@import "../style-assets/typography";
@import "../mixins/typegraphyMixin";
@import "../style-assets/colors";

//Radio input
// .radio-input {
// 	height: 20px;
// 	box-sizing: border-box;
// 	padding: 0;
// 	width: 20px;
// }

// .radio-input-container {
// 	display: flex;
// 	align-items: center;
// }

.label {
	margin-bottom: 0px !important;
}

.stripe-input-field-full {
	width     : 100%;
	color     : #96999C;
	// height: 45px;
	background: white;

	// border-bottom  : 1px solid #96999c;
	box-sizing     : border-box;
	padding-left   : 18px;
	color          : white !important;
	align-items    : center;
	justify-content: center;
}

.stripe-input-field-half {
	width     : 92%;
	color     : #96999C;
	// height: 45px;
	background: white;

	border-bottom  : 1px solid #96999c;
	box-sizing     : border-box;
	padding-top    : 12px;
	padding-left   : 8px;
	color          : white !important;
	align-items    : center;
	justify-content: center;
}

.stripe-input-field-full:focus {
	outline: none !important;
	color  : white !important;
}

.stripe-input-field-card {
	width        : 100%;
	height       : 40px;
	padding      : 10px 20px;
	border-radius: 12px;
	border       : none;
	resize       : none;
	outline      : none;
	box-shadow   : 0px 3px 5px #d3d3d3;
}

.stripe-input-field-card-half {
	width        : 250px;
	height       : 40px;
	padding      : 10px 20px;
	border-radius: 12px;
	border       : none;
	resize       : none;
	outline      : none;
	box-shadow   : 0px 3px 5px #d3d3d3;
}

// .input-underline {
// 	width        : 100%;
// 	padding      : 5px;
// 	border       : none;
// 	border-bottom: 1px solid #0054DC;
// }

// .input-underline-error {
// 	width        : 100%;
// 	padding      : 5px;
// 	border       : none;
// 	border-bottom: 1px solid #ff0022;
// }
// .search-box {
// 	width: 179px;
// 	height: 38px;
// 	border-radius: 78px;
// 	padding: 10px 15px;
// 	display: flex;
// 	justify-content: space-between;
// 	align-items: center;
// 	background-color: white;
// }

// .search-input {
// 	border: none;
// 	background-color: transparent;
// 	line-height: 0px;
// 	width: 90%;
// 	outline: none;
// 	@extend .description;

// 	&::placeholder {
// 		color: $black-400;
// 	}
// }

// .basic-input {
// 	border: none;
// 	// border-bottom   : 1px solid #CBE3FF;
// 	background-color: transparent;
// 	line-height: 0px;
// 	outline: none;

// 	// @extend .b4;

// 	&::placeholder {
// 		/* Chrome, Firefox, Opera, Safari 10.1+ */
// 		color: $black-300 !important; //Cloud Burst
// 		/* Firefox */
// 	}
// }

// input[type="number"]::-webkit-inner-spin-button {
// 	-webkit-appearance: none;
// }

// input[type="number"] {
// 	-moz-appearance: textfield;
// 	appearance: textfield;
// 	margin: 0;
// }

// .pricing-calculator-input {
// 	display: flex;
// 	justify-content: space-between;
// }

// @media only screen and (max-width: 768px) {
// 	.search-box {
// 		min-width: 100px;
// 		width: auto;
// 		max-width: 150px;
// 	}

// 	.pricing-calculator-input {
// 		display: flex;
// 		flex-direction: column;
// 	}
// }
.on-boarding-input {
	// margin-top: 24px;
	position: relative;
	display : block;


	.reveal-icon {
		position: absolute;
		margin  : auto;
		right   : 24px;
		top     : 13px;
		z-index : 1;
		width   : 12px;
		height  : 12px;
	}

	.error-input {
		border-bottom: 1px solid $danger;
	}
}

input[type="password"] {
	font     : small-caption !important;
	font-size: 20px !important;
	color    : $dark-100 !important;
}


.on-boarding-input>input {
	background      : #F6F5F8;
	width           : 100%;
	height          : 40px !important;
	outline         : none;
	// border       : 0.6px solid #C8CBCD;
	border-radius   : 24px;
	padding         : 19px 24px;
	border          : none;

}

.on-boarding-input>input:focus {
	outline      : none;
	border-bottom: none;
}

//--------------------------------------------------------------------------------------------------------------------------------------------

.primary-input {
	background      : #F6F5F8;
	width           : 100%;
	height          : 40px !important;
	outline         : none;
	// border       : 0.6px solid #C8CBCD;
	border-radius   : 24px;
	padding         : 19px 24px;
	border          : none;

}

.primary-input:focus-visible::placeholder {
	color: #96999C !important;
}

.primary-input-error {
	border       : 0.6px solid #FC4848 !important;
	border-radius: 24px;
}

// .primary-input:focus-visible {
// 	outline      : none;
// 	background   : #FFFFFF;
// 	border       : 0.6px solid #C8CBCD;
// 	border-radius: 4px;
// }
// .secondary-input {
// 	background      : #F6F5F8;
// 	width           : 100%;
// 	height          : 40px;
// 	outline         : none;
// 	// border       : 0.6px solid #C8CBCD;
// 	border-radius   : 24px;
// 	padding         : 24px;
// 	border          : none;

// }

// // .secondary-input:focus-visible::placeholder {
// // 	color: #96999C !important;
// // }

// // .secondary-input-error {
// // 	border       : 0.6px solid #FC4848 !important;
// // 	border-radius: 4px;
// // }