.profile-container {
    margin-left: 20px;
    align-items: flex-start;

    .profile-heading {
        margin: 44px 0px;
    }

    .profile-details {
        display       : flex;
        flex-direction: row;
        align-items   : flex-start;

        .profile-pic-detail {
            display       : flex;
            flex-direction: column;
            align-items   : flex-start;

            .body-3 {
                color: #2B3340
            }

            .profile-pic {
                margin-top : 20px;
                margin-left: 20px;
                align-items: center;

                img {
                    width : 128px;
                    height: 128px;
                }

                .description {
                    width     : 128px;
                    height    : 29px;
                    color     : #6E6B7B;
                    margin-top: 20px;
                }
            }
        }

        .profile-other-details {
            margin-left: 100px;
            flex-wrap  : wrap;

            .profile-edit-detail {
                align-items  : flex-start;
                width        : 521px;
                height       : 69px;
                margin-bottom: 30px;
                color        : #2B3340;

                .input {
                    width        : 521px;
                    height       : 40px;
                    margin       : 20px 30px 0px 0px;
                    background   : #FFFFFF;
                    border-radius: 3px;
                    color        : #2B3340;
                    border       : none;
                }

                .select {
                    margin-top      : 20px;
                    // width: 491px;
                    // padding: 10px 0px;
                    // margin: 20px 30px 0px 0px;
                    // background: #FFFFFF;
                    // border-radius: 3px;
                    // color: #2B3340;
                    // border-style: none;
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
    .profile-container {
        .profile-details {
            flex-direction: column;

            .profile-other-details {
                margin-top : 30px;
                margin-left: 0px;

                .profile-edit-detail {
                    width : 307px;
                    height: 65px;

                    .input {
                        width : 307px;
                        height: 40px;
                    }

                    .select {
                        outline: none;
                        width  : 277px;
                        height : 40px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .profile-container {
        height: auto;

        .profile-details {
            flex-direction: column;

            .profile-pic-detail {
                flex-direction: row;

                .profile-pic {
                    margin-top : 0px;
                    margin-left: 70px;
                    align-items: flex-end;
                }
            }

            .profile-other-details {
                margin-top : 30px;
                margin-left: 0px;

                .profile-edit-detail {
                    width : 327px;
                    height: 65px;

                    .input {
                        width : 327px;
                        height: 40px;
                    }

                    .select {
                        outline: none;
                        width  : 297px;
                        height : 40px;
                    }
                }
            }
        }
    }
}