.qualification-test-container {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    align-items    : flex-start;
    justify-content: flex-start;
    gap            : 32px;

    .qualification-test-questions-container {
        width         : 100%;
        max-width     : 668px;
        display       : flex;
        flex-direction: column;
        align-items   : flex-start;
        gap           : 24px;
    }
}