.welcome-video-container {
    width          : 100%;
    height         : 100%;
    display        : flex;
    flex-direction : column;
    align-items    : flex-start;
    justify-content: flex-start;
    padding        : 24px;
    background     : #FFFFFF;
    border-radius  : 24px;
    gap            : 24px;

    .video {
        width        : 100%;
        min-height   : 580px;
        height       : 580px;
        border-radius: 8px;

        video {
            border-radius: 8px;
        }
    }
}

@media only screen and (max-height: 900px) {
    .welcome-video-container {
        align-self: flex-start;
    }
}