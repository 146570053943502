.assessment-info-dialog-box-container {
    max-width     : 668px;
    display       : flex;
    flex-direction: column;
    align-items   : flex-start;
    background    : #FFFFFF;
    border-radius : 24px;

    .title {
        width          : 100%;
        display        : flex;
        justify-content: flex-start;
        padding        : 24px 24px 0px;

        .truncate-title {
            white-space  : nowrap;
            overflow     : hidden;
            text-overflow: ellipsis;
            max-width    : 450px;
            display      : block;
        }
    }

    .assessment-info-dialog-box-bottom-container {
        width          : 100%;
        display        : flex;
        flex-direction : row;
        justify-content: end;
        padding        : 18px 24px;
        border-top     : 1px solid #ECEDF1;
        gap            : 24px;
    }
}