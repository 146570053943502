@import "../style-assets/typography";

.dashboard-container {
	max-height   : 100vh;
	min-height   : 100vh;
	height       : 100vh;
	display      : flex;
	// padding   : 24px;


	.dashboard-page-view-75 {
		width: calc(100% - 75px - 24px) !important;
	}

	@media screen and (max-width: 767px) {
		.dashboard-page-view-75 {
			width: 100% !important;
		}
	}

	.dashboard-page-view-257 {
		width: calc(100% - 257px - 24px) !important;
	}

	@media screen and (max-width: 767px) {
		.dashboard-page-view-257 {
			width: 100% !important;
		}
	}


	.dashboard-page-view {
		width                    : 100%;
		// height             : 100%;
		// padding   : 45px 32px;
		// background      : #F9F9FC;	
		display                  : flex;
		position                 : relative;
		// overflow              : hidden;

		@media screen and (max-width: 1440px) {

			// font-size: 13px;
			/* Adjust the font size for screens less than or equal to 1440px */
		}

		.dashboard-page {
			// height    : 100%;
			width        : 100%;
			overflow-y   : auto;
			gap          : 24px;
			// padding   : 0px 24px;

			.page-header {
				display              : flex;
				flex-direction       : column;
				justify-content      : center;
				align-items          : flex-start;
				height               : auto;
				// min-height        : 100px;
				// max-height        : 100px;
				padding              : 20px 0px 32px;
				// border-bottom  : 1px solid #DEDAF2;

				.header-inner-container {
					justify-content: space-between;
					align-items    : center;
					padding        : 20px 0px 34px 0px;
				}
			}

			.page-content-assessment-creation-screen {
				width: calc(100vw - 75px - 446px);
			}

			.page-content {
				height                  : max-content;
				width                   : 100%;
				min-height              : calc(100% - 100px);
				// max-height        : calc(100vh - 100px);
				display                 : flex;
				flex-direction          : column;
				justify-content         : flex-start;
				// padding              : 2em;
				// overflow-y     : scroll;

				.truncate-title {
					word-break        : break-word;
					text-align        : left;
					width             : 100%;
					display           : -webkit-box;
					max-width         : 100%;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow          : hidden;
				}
			}

			.page-right-side-content {
				min-width         : 350px;
				width             : 350px;
				height            : max-content;
				display           : flex;
				flex-direction    : column;
				align-items       : flex-start;
				justify-content   : flex-start;
				padding           : 24px;
				gap               : 2em;
				border-radius     : 24px;
				background        : #FFFFFF;
				// overflow-x     : auto;
				position          : sticky;

				@media screen and (max-width: 1440px) {

					// font-size: 13px;
					/* Adjust the font size for screens less than or equal to 1440px */
				}

			}

			@media screen and (min-width: 1600px) {
				.page-right-side-content {
					width: 350px;
				}
			}

			@media screen and (min-width: 1200px) and (max-width: 1300px) {
				.page-right-side-content {
					width: 350px;
				}
			}

			.page-right-side-content::-webkit-scrollbar {
				display: none;
			}
		}
	}
}

.left-day-message-main-box {
	margin-top: 45px;
}

.left-day-message-box {
	max-width       : max-content;
	position        : absolute;
	top             : 64px;
	left            : 5%;
	right           : 5%;
	display         : flex;
	align-items     : center;
	text-align      : left;
	background-color: #FFFFFF;
	box-shadow      : 0px 4px 24px 0px #0000000F;
	padding         : 12px 24px;
	border-radius   : 24px;
	gap             : 12px;
	z-index         : 4;
}

@media only screen and (min-width: 768px) and (max-width: 1551px) {
	.left-day-message-main-box {
		margin-top: 60px;
	}

	.left-day-message-box {
		top: 84px;
	}
}

@media only screen and (min-width: 529px) and (max-width: 767px) {
	.left-day-message-main-box {
		margin-top: 80px;
	}

	.left-day-message-box {
		top  : 74px;
		left : 0%;
		right: 0%;
	}
}

@media only screen and (min-width: 405px) and (max-width: 528px) {
	.left-day-message-main-box {
		margin-top: 90px;
	}

	.left-day-message-box {
		top  : 74px;
		left : 0%;
		right: 0%;
	}
}

@media only screen and (min-width: 368px) and (max-width: 404px) {
	.left-day-message-main-box {
		margin-top: 114px;
	}

	.left-day-message-box {
		top  : 74px;
		left : 0%;
		right: 0%;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
	.dashboard-container {


		.dashboard-page-view {
			.dashboard-page {
				.page-content-assessment-creation-screen {
					width: calc(100vw - 75px - 446px);
				}
			}
		}
	}
}

@media screen and (min-width: 1600px) {
	.dashboard-container {


		.dashboard-page-view {
			.dashboard-page {
				.page-content-assessment-creation-screen {
					width: calc(100vw - 75px - 446px);
				}
			}
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
	.dashboard-container {


		.dashboard-page-view {

			// width: calc(100% - 80px);

			// padding: 24px;
			.dashboard-page {
				.page-content {
					.table-email-text {
						word-break        : break-word;
						text-align        : left;
						width             : 100%;
						display           : -webkit-box;
						max-width         : 100%;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						overflow          : hidden;
					}
				}
			}
		}

	}
}

@media screen and (max-width: 767px) {
	.dashboard-container {
		// min-height    : 100vh;
		// width         : 100vw;
		display       : flex;
		flex-direction: column;
		overflow      : hidden;

		.dashboard-page-view {
			overflow-y      : auto;
			// height       : calc(100vh - 108px);
			// margin-top: 24px;

			// width          : 100%;
			// min-height     : 100vh;
			// height         : 100%;
			// padding        : 0px;
			// display        : flex;
			// align-items    : center;
			// justify-content: center;
			.dashboard-page-mobile {
				// overflow-y: auto;
				// margin-top: 32px;

				.dashboard-page {
					// overflow-y: auto;
					// margin-top: 48px;

					.page-header {
						height    : 68px;
						min-height: 68px;
						max-height: 68px;
					}

					// .page-content {
					// 	padding: 24px;
					// }
				}
			}



			.dashboard-page::-webkit-scrollbar {
				display: none;
			}
		}
	}
}