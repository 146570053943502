.primary-textarea-container {
    width         : 100%;
    height        : auto;
    min-height    : 120px;
    display       : flex;
    flex-direction: column;
    align-items   : center;

    .primary-textarea {
        width        : 100%;
        height       : auto;
        outline      : none;
        background   : #F6F5F8;
        border-radius: 24px;
        padding      : 19px 24px;
        border       : none;

    }
}