.assessment-result-card-main-container {
    width         : 100%;
    height        : fit-content;
    display       : flex;
    flex-direction: column;
    padding       : 24px;
    border-radius : 24px;
    border        : 1px solid #ECEDF1;
    gap           : 24px;
    background    : #FFFFFF;
}

.assessment-result-card-container {
    width            : 100%;
    height           : fit-content;
    display          : flex;
    flex-direction   : column;
    padding          : 16px 18px;
    border-radius    : 24px;
    // gap           : 24px;
    background       : #F6F5F8;

    .question-ans-text-heading {
        @extend .body-2;
        color         : #808191 !important;
        // font-weight: 500 !important;
        text-align    : left;
    }

    .question-text {
        @extend .body-2;
        color           : #121216 !important;
        text-align      : left;
        // font-weight  : 500 !important;
        overflow-wrap   : anywhere
    }

    .answer-text {
        @extend .body-2;
        color     : #121216 !important;
        text-align: left;
    }

    .assessment-result-card-header-not-cmlt {
        width          : 100%;
        height         : auto;
        display        : flex;
        align-items    : center;
        justify-content: space-between;
        border-radius  : 24px;
        gap            : 24px;
        background     : #F6F5F8;
    }

    .assessment-result-card-header-expired {
        width          : 100%;
        height         : auto;
        display        : flex;
        align-items    : center;
        justify-content: space-between;
        border-radius  : 24px;
        gap            : 24px;
        background     : #F6F5F8;
    }

    .assessment-result-card-header {
        width                : 100%;
        height               : auto;
        // background     : rgba(111, 45, 189, 0.1);
        // border-bottom  : 1px solid #DEDAF2;
        border-radius        : 8px 8px 8px 8px;
        display              : flex;
        // align-items       : center;
        justify-content      : space-between;
        padding              : 0.75em 1em;
        gap                  : 16px;
    }

    .assessment-result-card-header-expanded {
        border-bottom-left-radius : 0px !important;
        border-bottom-right-radius: 0px !important;
    }

    .assessment-result-view {
        width     : 100%;
        height    : 0px;
        transition: height 1s;
        overflow  : hidden;
    }

    .assessment-result-view-onExpand {
        width                           : 100%;
        min-height                      : 34px;
        height                          : min-content;
        // padding                      : 16px;
        // border                       : 1px solid #DEDAF2;
        // border-bottom-left-radius    : 8px;
        // border-bottom-right-radius   : 8px;
        // word-break                : break-all;

        .general_test_result-card {
            height       : 66px;
            width        : 100%;
            border       : 1px solid #DEDAF2;
            border-radius: 4px;
            display      : flex;

            .left-head {
                width  : calc(100% - 177px);
                display: flex;

                .title {
                    @extend .headline-small;
                }

                .subtitle {
                    @extend .body-2;
                }
            }

            .right-header {
                width: 177px;

                .title {
                    @extend .headline-small;
                }

                .subtitle {
                    @extend .body-2
                }
            }
        }

        .test_result_card {
            width             : 100%;
            height            : 44px;
            display           : flex;
            justify-content   : space-between;
            align-items       : center;
            // border         : 1px solid #DEDAF2;
            padding           : 12px 18px;
            border-radius     : 24px;
            background        : #FFF;

            .result_card-left-side {
                height     : 100%;
                width      : calc(100% - 9em);
                padding    : 0.5em 0.75em;
                display    : flex;
                align-items: center;

                .coding-title-truncate {
                    white-space  : nowrap;
                    overflow     : hidden;
                    text-overflow: ellipsis;
                    max-width    : 100%;
                    display      : block;
                }
            }

            .result_card-right-side {
                width                : max-content;
                height               : 100%;
                // background     : #DEDAF2;
                // border-radius  : 0px 4px 4px 0px;
                // justify-content   : space-around;
                display              : flex;
                flex-direction       : row;
                align-items          : center;
                justify-content      : flex-end;
                gap                  : 12px;
            }
        }

        .skill_test_result_card {
            height         : 76px;
            width          : 100%;
            display        : flex;
            justify-content: space-between;
            align-items    : center;
            border         : 1px solid #DEDAF2;
            border-radius  : 4px;

            .result_card-left-side {
                height     : 100%;
                width      : calc(100% - 9em);
                padding    : 0.5em 0.75em;
                display    : flex;
                align-items: center;
            }

            .result_card-right-side {
                width          : 9em;
                height         : 100%;
                background     : #DEDAF2;
                border-radius  : 0px 4px 4px 0px;
                justify-content: space-around;
                display        : flex;
                flex-direction : column;
                align-items    : center;
                justify-content: center;
            }
        }

        .value_test_result_card {
            height         : 56px;
            width          : 100%;
            display        : flex;
            justify-content: space-between;
            align-items    : center;
            border         : 1px solid #DEDAF2;
            border-radius  : 4px;

            .result_card-left-side {
                height     : 100%;
                width      : calc(100% - 9em);
                padding    : 0.5em 0.75em;
                display    : flex;
                align-items: center;
            }

            .result_card-right-side {
                width          : 9em;
                height         : 100%;
                background     : #DEDAF2;
                border-radius  : 0px 4px 4px 0px;
                justify-content: space-around;
                display        : flex;
                flex-direction : column;
                align-items    : center;
                justify-content: center;
            }
        }

        .file-upload-result {
            width           : 100%;
            height          : fit-content;
            padding         : 12px 18px 12px 18px;
            border-radius   : 24px;
            display         : flex;
            flex-direction  : column;
            align-items     : flex-start;
            background-color: white;

            .ql-container {
                @extend .body-1;

                .ql-editor {
                    padding: 0px;
                }
            }

            .file-upload-result-doc {
                width          : 100%;
                height         : auto;
                display        : flex;
                flex-direction : row;
                justify-content: space-between;
                align-items    : center;
                padding        : 16px;
                gap            : 8px;
                background     : #FFF;
                border         : 1px solid #121216;
                border-radius  : 24px;

                .truncated-text {
                    white-space  : nowrap;
                    overflow     : hidden;
                    text-overflow: ellipsis;
                    max-width    : 100%;
                    display      : block;
                }

                .truncated-text:hover {
                    white-space: normal;
                    overflow   : visible;
                }

                .truncated-text::after {
                    content       : "";
                    display       : inline-block;
                    width         : 0;
                    height        : 1em;
                    vertical-align: bottom;
                }
            }
        }

        .video-response-result {
            width        : 100%;
            height       : 250px;
            border-radius: 12px;
            background   : #121216;
            object-fit   : contain;
        }

        .loom-video-recorder {
            width : 389px;
            height: 206px;

            iframe {
                border-radius: 12px;
                width        : 100%;
                height       : 100%;
            }
        }

        .personality-result {
            width         : 100%;
            padding       : 8px 12px;
            height        : fit-content;
            background    : #FAF9FB;
            border-radius : 4px;
            display       : flex;
            flex-direction: column;
            align-items   : flex-start;
        }
    }

}

.assessment-result-container {
    height          : 100%;
    width           : 100%;
    // padding-right: 10px;
}

.no-result-section {
    position: absolute;
    z-index : 0;
}

.overlap-div {
    position: absolute;
    z-index : 1;
}

.no-result-text {
    // position  : absolute;
    width        : 100%;
    height       : auto;
    display      : flex;
    text-align   : center;
    padding      : 12px 24px;
    border-radius: 24px;
    gap          : 10px;
    background   : #F6F5F8;
    // transform : translateY(-50%);
}

.arrow {
    transform : rotate(0deg);
    transition: transform 0.15s;
}

.roate-arrow {
    transform    : rotate(180deg);
    // margin-top: -36px;
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
    .assessment-result-card-main-container {
        padding: 16px;
    }

    .assessment-result-card-container {
        width: 100%;

        .assessment-result-view-onExpand {
            .coding_test_result_card {
                width: 100%;

                .result_card-left-side {
                    .coding-title-truncate {
                        max-width: 200px;
                    }
                }
            }

            .file-upload-result {
                .file-upload-result-doc {
                    width: 100% !important;
                }
            }

            .video-response-result {
                width: 100%;
            }

            .loom-video-recorder {
                width: 100%;
            }
        }
    }

    .no-result-section {
        position: absolute;
        z-index : 0;
    }
}

@media screen and (max-width: 767px) {
    .assessment-result-card-main-container {
        padding: 16px;
    }

    .assessment-result-card-container {
        .assessment-result-view-onExpand {

            .skill_test_result_card {
                .result_card-right-side {
                    width: 100px;
                }

                .result_card-left-side {
                    width: calc(100% - 100px);
                }
            }

            .value_test_result_card {
                .result_card-right-side {
                    width: 100px;
                }

                .result_card-left-side {
                    width: calc(100% - 100px);
                }
            }

            .coding_test_result_card {
                .result_card-right-side {
                    width: 170px;
                }

                .result_card-left-side {
                    width: calc(100% - 170px);

                    .coding-title-truncate {
                        max-width: 80px;
                    }
                }
            }

            .file-upload-result {
                .file-upload-result-doc {
                    width: 100%;
                }
            }

            .video-response-result {
                width: 100%;
            }

            .loom-video-recorder {
                width: 100%;
            }
        }
    }

    .no-result-section {
        position: absolute;
        z-index : 0;
        width   : 240px !important;
        height  : 208px !important;

    }
}