@import "../mixins/typegraphyMixin";

// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');




@font-face {
    font-family: "urbanist";
    src        : local("Urbanist"),
        url("../../font/Urbanist/Urbanist-VariableFont_wght.ttf") format("truetype");
}

.headline-1 {
    @include typography('Urbanist', 400, 36px, 43.2px, 0.005px, #000000);
}

.headline-2 {
    @include typography('Urbanist', 400, 32px, 38.4px, 0.005px, #000000);
}

.headline-3 {
    @include typography('Urbanist', 400, 28px, 33.6px, 0.005px, #000000);
}

.headline-4 {
    @include typography('Urbanist', 400, 24px, 28.8px, 0.005px, #000000);
}

.headline-5 {
    @include typography('Urbanist', 400, 22px, 26.4px, 0.005px, #000000);
}

.subtitle-1 {
    @include typography('Urbanist', 400, 20px, 24px, 0.005px, #000000);
}

.subtitle-2 {
    @include typography('Urbanist', 400, 18px, 21.6px, 0.005px, #000000);
}

.body-1 {
    @include typography('Urbanist', 400, 16px, 19.2px, 0.005px, #000000);
}

.body-2 {
    @include typography('Urbanist', 400, 14px, 16.8px, 0.005px, #000000);
}

.body-3 {
    @include typography('Urbanist', 400, 12px, 14.4px, 0.005px, #000000);
}

.caption {
    @include typography('Urbanist', 400, 11px, 13.2px, 0.005px, #000000);
}

.button-small-text {
    @include typography('Urbanist', 400, 16px, 19.2px, 0.005px, #000000);
}

.extrabold {
    font-weight: 800;
}

.bold {
    font-weight: 700;
}

.semibold {
    font-weight: 600;
}

.medium {
    font-weight: 500;
}

.light {
    font-weight: 300;
}

// .................................old typography............................................//
@font-face {
    font-family: "poppins";
    src        : local("poppins"),
        url("../../font/Poppins/Poppins-Regular.ttf") format("truetype");
}



@font-face {
    font-family: "Open Sans";
    src        : local("OpenSans"),
        url("../../font/Open_Sans/OpenSans-VariableFont_wdth\,wght.ttf") format("truetype");
}

.poppin-40-600 {
    @include typography(poppins, 600, 2.5rem, 66px, -0.46px, null);
}

.poppin-32-500 {
    @include typography(poppins, 500, 2rem, 48px, -0.46px, null);
}

.poppin-24-500 {
    @include typography(poppins, 500, 1.5rem, 36px, -0.46px, null);
}

.poppin-16-600 {
    @include typography(poppins, 600, 1rem, 24px, -0.46px, null);
}

.poppin-16-500 {
    @include typography(poppins, 500, 1rem, 24px, -0.40px, null);
}

.poppin-12-400 {
    @include typography(poppins, 400, 0.75rem, 18px, -0.36px, null);
}

.poppin-14-400 {
    @include typography(poppins, 500, 0.875rem, 18px, -0.36px, null);
}

.poppin-14-700 {
    @include typography(poppins, 700, 0.875rem, 20px, -0.38px, null);
}

// Open Sans
.hero-large {
    @include typography('Open Sans', 600, 2.5rem, 48px, 0.005px, #10182B);
}

.hero-medium {
    @include typography('Open Sans', 600, 2rem, 38px, 0.005px, #10182B);
}

.hero-small {
    @include typography('Open Sans', 600, 1.75rem, 33px, 0.005px, #10182B);
}

.headline-large {
    @include typography('Open Sans', 600, 1.75rem, 33.6px, 0.005px, #10182B);
}

.headline-medium {
    @include typography('Open Sans', 600, 1.5rem, 28.8px, 0.005px, #10182B);
}

.headline-small {
    @include typography('Open Sans', 500, 1.25rem, 24px, 0.005px, #10182B);
}

// Roboto
// .body-1 {
//     @include typography('Roboto', 400, 1.25rem, 34px, 0.005px, #10182B);
// }

// .body-2 {
//     @include typography('Roboto', 400, 1.125rem, 30px, 0.005px, #10182B);
// }

// .body-3 {
//     @include typography('Roboto', 400, 1rem, 26px, 0.005px, #10182B);
// }

// .body-4 {
//     @include typography('Roboto', 400, 0.875rem, 24px, 0.005px, #10182B);
// }

.body-5 {
    @include typography('Roboto', 400, 0.75rem, 20px, 0.005px, #10182B);
}

.description {
    @include typography('Roboto', 500, 0.75rem, 14.4px, 0.005px, #10182B);
}

.label {
    @include typography('Roboto', 600, 0.75rem, 12px, 0.005px, #10182B);
}

// .button-large-text {
//     @include typography('Roboto', 500, 1.125rem, 16px, 0.005px, #10182B);
// }

// .button-medium-text {
//     @include typography('Roboto', 500, 0.875rem, 14px, 0.005px, #10182B);
// }

// .button-small-text {
//     @include typography('Roboto', 500, 0.75rem, 12px, 0.005px, #10182B);
// }


.underline {
    text-decoration: underline;
}

// @media only screen and (min-width: 768px) and (max-width: 1279px) {
//     .headline-large {
//         @include typography('Open Sans', 600, 24px, 28px, 0.005px, #10182B);
//     }

//     .headline-medium {
//         @include typography('Open Sans', 600, 20px, 24px, 0.005px, #10182B);
//     }

//     .headline-small {
//         @include typography('Open Sans', 600, 18px, 20px, 0.005px, #10182B);
//     }
// }

// @media only screen and (max-width: 767px) {
//     .headline-large {
//         @include typography('Open Sans', 600, 24px, 28px, 0.005px, #10182B);
//     }

//     .headline-medium {
//         @include typography('Open Sans', 600, 20px, 24px, 0.005px, #10182B);
//     }

//     .headline-small {
//         @include typography('Open Sans', 600, 18px, 24px, 0.005px, #10182B);
//     }

//     // .body-1 {
//     //     @include typography('Roboto', 400, 16px, 26px, 0.005px, #10182B);
//     // }

//     // .body-2 {
//     //     @include typography('Roboto', 400, 14px, 24px, 0.005px, #10182B);
//     // }

//     // .body-3 {
//     //     @include typography('Roboto', 400, 12px, 24px, 0.005px, #10182B);
//     // }

//     // .body-4 {
//     //     @include typography('Roboto', 400, 12px, 20px, 0.005px, #10182B);
//     // }

//     .medium,
//     .headline-medium {
//         text-align: left;
//     }
// }

// @media (max-width: 379px) {
//     .body-2 {
//         @include typography('Roboto', 400, 12px, 20px, 0.005px, #10182B);
//     }
// }