.case-study-collapsed-intro-text {
	width      : 100%;
	overflow   : hidden;
	font-size  : 18px;
	line-height: 21.94px;
	height     : 30px;
	text-align : left;
	color      : $dark-100
}

.case-study-expanded-intro-text {
	width     : 100%;
	overflow  : hidden;
	font-size : 18px;
	text-align: left;
}

.case-study-collapsed-intro-text-view-more {
	text-align: left;
	cursor    : pointer;
	width     : 105px;
}

.case-study-question-container {
	width         : 100%;
	display       : flex;
	flex-direction: column;
	align-items   : flex-start;
	border        : 1px solid #ECEDF1;
	padding       : 24px;
	border-radius : 24px;
	gap           : 24px;
}

.quill {
	display       : flex;
	flex-direction: column;
	width         : 100%;
	margin-top    : 2px;
}

.text-response-ql-editor {
	.ql-container {
		// min-height: 10rem;
		// max-height: 30rem;
		// overflow-y: auto;
		height: auto;
	}

	.ql-editor {
		padding: 0px 0px;

		img {
			width: 500px;
		}

		video {
			width: 500px !important;
		}
	}
}

.ql-editor-test-instruction {
	.ql-editor {
		img {
			width: 500px;
		}

		video {
			width: 500px !important;
		}
	}
}

.case-study-cs-quill-container {
	width         : 100%;
	display       : flex;
	flex-direction: column;
	background    : #F6F5F8;
	padding       : 24px;
	border-radius : 24px;
	gap           : 12px;

	.ql-container {
		width         : 100%;
		height        : auto;
		min-height    : 140px;
		display       : flex;
		flex-direction: column;
		border        : none !important;

		.ql-editor::before {
			padding-left: 8px;
		}

		.ql-editor {
			height       : 100%;
			border-radius: 24px;
			padding      : 8px 24px;
			gap          : 8px;
		}
	}

	.ql-toolbar {
		box-shadow   : 0px 4px 3px rgba(224, 224, 224, 0.25) !important;
		border       : none !important;
		min-height   : 40px;
		display      : flex;
		align-items  : flex-start;
		background   : #FFF !important;
		border-radius: 24px;
		padding      : 8px 24px;

		.ql-formats {

			button,
			span {
				color: #718096 !important;


				.ql-stroke {
					stroke: #718096 !important;
				}

				.ql-fill {
					fill: #718096 !important;
				}

				&:hover {
					color: #06c !important;

					.ql-stroke {
						stroke: #06c !important;
					}

					.ql-fill {
						fill: #06c !important;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 1339px) {}

@media only screen and (max-width: 767px) {}