.radio-with-input-container {
    width         : 100%;
    min-height    : 40px;
    max-height    : 40px;
    position      : relative;
    display       : flex;
    flex-direction: row;
    align-items   : center;
    background    : #FFFFFF;
    padding       : 12px 24px;
    border-radius : 24px;
    gap           : 12px;

    .ql-editor {
        padding: 0px;
    }

    .form-check-input {
        border: 1px solid #808191;
        cursor: pointer;
    }

    .radio-label-input {
        padding-left    : 50px;
        border          : none;
        background-color: transparent;
        // height      : 38px;
    }

    .read-only-div {
        p {
            margin: 0;
            cursor: pointer;
        }

        // height : fit-content;
    }

    img {
        width: 100%;
    }
}

.custom-radio input[type="radio"] {
    display: none;
}

.custom-radio {
    display    : inline-flex;
    align-items: center;
    cursor     : pointer;
    font-size  : 18px;
    user-select: none;
}

.custom-radio .radio-mark {
    display         : inline-block;
    width           : 16px;
    min-width       : 16px;
    height          : 16px;
    background-color: #fff;
    border          : 1px solid #121216;
    border-radius   : 50%;
    position        : relative;
    margin-right    : 12px;
}

.custom-radio .radio-mark::after {
    content         : "";
    display         : block;
    width           : 10px;
    min-width       : 10px;
    height          : 10px;
    background-color: #121216;
    border-radius   : 50%;
    position        : absolute;
    top             : 50%;
    left            : 50%;
    transform       : translate(-50%, -50%);
    opacity         : 0;
    transition      : opacity 0.2s ease;
}

/* Show the inner circle when the radio button is checked */
.custom-radio input[type="radio"]:checked+.radio-mark::after {
    opacity: 1;
}

.readonly-radio-with-input-container {
    max-height      : min-content;
    align-items     : flex-start;
    background-color: $dark-20 !important;
}

.radio-with-custom-component {
    display    : flex;
    align-items: center;

    .form-check-input {
        width     : 100%;
        background: #F4F5F7;
        width     : 16px;
        height    : 16px;
    }
}

input#radioNoLabel1:checked {
    background-color: $dark-100;
    border-color    : $dark-100;
}

.error-radio input#radioNoLabel1:checked {
    background-color: #FC4848;
    border-color    : #FC4848;
}