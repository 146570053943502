@import "./../style-assets/colors";
@import "./../style-assets/typography";
@import "./../mixins/typegraphyMixin";



.primary-button {
	background   : $dark-100;
	border       : 1px solid $dark-100;
	border-radius: 50px;

	span {
		color         : white;
		// font-weight: 600 !important;
	}

	svg {
		color: white;

		path {
			stroke: white;
		}
	}


}



.primary-button-active,
.primary-button:hover {
	// background: rgba(255, 255, 255, 0.6);
	// border: 2px solid #392D69;
	cursor: pointer;

	span {
		// font-size: 16px;
		transform : scale(1.1);
		transition: 0.3s;
	}

	svg {
		color: #ffffff;

		path {
			stroke: #ffffff;
		}

		g {
			rect {
				fill: #ffffff;
			}
		}
	}
}

.primary-button,
.primary-button-disabled {
	.button-processing-indicator-wrapper {
		height    : 14px;
		width     : 14px;
		display   : flex;
		align-self: center;

		.processing-indicator {
			border-top  : 2px solid white;
			border-left : 2px solid white;
			border-right: 2px solid white;
		}
	}
}

.primary-button-disabled {
	background   : $dark-100;
	border       : 1px solid $dark-100;
	border-radius: 50px;
	cursor       : not-allowed;
	opacity      : 0.4;

	span {
		color         : white;
		// font-weight: 600 !important;
	}
}

.primary-light-button {
	background: $primary-text;
	border    : 2px solid $primary-text;

	span {
		color      : white;
		font-weight: 600 !important;
	}

	svg {
		color: white;

		path {
			stroke: white;
		}
	}
}

.primary-light-button-active,
.primary-light-button:hover {
	background: white !important;
	border    : 2px solid $primary-text;
	cursor    : pointer;

	span {
		color: $primary-text;
	}

	svg {
		color: $primary-text;

		path {
			stroke: $primary-text;
		}
	}
}

.primary-light-button,
.primary-light-button-disabled {
	.button-processing-indicator-wrapper {
		height    : 14px;
		width     : 14px;
		display   : flex;
		align-self: center;

		.processing-indicator {
			border-top  : 2px solid white;
			border-left : 2px solid white;
			border-right: 2px solid white;
		}
	}
}

.primary-light-button-disabled {
	background: #DEDAF2;
	cursor    : not-allowed;
	border    : none;

	span {
		color      : white;
		font-weight: 600 !important;
	}
}

.secondary-btn {
	background   : white;
	border       : 1px solid $dark-100;
	border-radius: 24px;

	span {
		color         : $dark-100;
		// font-weight: 600 !important;
	}

	.button-processing-indicator-wrapper {
		height    : 16px;
		width     : 16px;
		display   : flex;
		align-self: center;
	}
}

.secondary-btn-v2-active,
.secondary-btn-v2:hover,
.secondary-btn:hover {
	// background   : #DEDAF2;
	border       : 1px solid $dark-100;
	border-radius: 24px;
	cursor       : pointer;

	span {
		color     : $dark-100;
		transform : scale(1.1);
		transition: 0.3s;
	}
}

.secondary-btn-disabled {
	background   : #F4F6F6;
	border       : 1px solid #C8CBCD;
	border-radius: 24px;
	cursor       : not-allowed;

	.button-processing-indicator-wrapper {
		height    : 16px;
		width     : 16px;
		display   : flex;
		align-self: center;
	}

	span {
		color      : #C8CBCD;
		font-weight: 600 !important;
	}
}

.secondary-btn-v2 {
	background   : white;
	border       : 1px solid $dark-100;
	border-radius: 50px;

	span {
		color         : $dark-100;
		// font-weight: 600 !important;
	}

	svg {
		g rect {
			fill: $dark-100;
		}
	}

	.button-processing-indicator-wrapper {
		height    : 16px;
		width     : 16px;
		display   : flex;
		align-self: center;
	}
}

.btn-lg {
	// border-radius: 3px;
	padding: 14px 32px;
}

.btn-md {
	// border-radius: 3px;
	padding: 0 24px;
	height : 44px;

	svg {
		height: 20px;
		width : 20px;
	}
}

.btn-sm {
	// border-radius: 3px;
	padding: 0 24px;
	height : 32px;

	svg {
		height: 16px;
		width : 16px;
	}
}

.in-section-btn {
	width          : 100%;
	display        : flex;
	flex-direction : row;
	align-items    : center;
	justify-content: flex-start;
	padding        : 12px 18px 12px 18px;
	height         : 40px;
	background     : #ffffff;
	border         : none;
	border-radius  : 24px;

	span {
		font-size        : 16px;
		// font-weight   : 400;
		// font-family: Roboto;
		// color      : #56585A !important;
		text-align       : center;
	}

	svg {
		height: 18px;
		width : 18px;
		stroke: $dark-50
	}

	&:disabled {
		opacity: 0.5;
	}
}

.in-section-btn-V2 {
	width          : 100%;
	display        : flex;
	flex-direction : row;
	align-items    : center;
	justify-content: space-between;
	padding        : 12px 18px 12px 18px;
	height         : 40px;
	background     : #ffffff;
	border         : none;
	border-radius  : 24px;

	span {
		font-size        : 16px;
		// font-weight   : 400;
		// font-family: Roboto;
		// color      : #56585A !important;
		text-align       : center;
	}

	svg {
		height: 18px;
		width : 18px;
		stroke: $dark-50
	}

	&:disabled {
		opacity: 0.5;
	}
}

.in-section-btn-V2:hover {
	background      : #000000 !important;
	// border    : 1px solid #121216;
	// box-shadow   : 3px 4px 4px rgba(6, 5, 7, 0.13);

	span {
		color         : #ffffff !important;
		// font-weight: 500 !important;
	}

	svg {
		color : #ffffff !important;
		stroke: #ffffff !important;
	}
}

.in-section-btn:hover {
	background      : #000000 !important;
	// border    : 1px solid #121216;
	// box-shadow   : 3px 4px 4px rgba(6, 5, 7, 0.13);

	span {
		color         : #ffffff !important;
		// font-weight: 500 !important;
	}

	svg {
		color : #ffffff !important;
		stroke: #ffffff !important;
	}
}

.in-section-btn-danger {
	// background: rgba(255, 225, 225, 0.5) !important;
	border: 1px solid #FC4848 !important;

	span {
		color: #808191 !important;
	}

	svg {
		color : #808191 !important;
		stroke: #808191 !important;
	}

}

// .in-section-btn-danger:hover {
// 	span {
// 		color: #808191 !important;
// 	}

// 	svg {
// 		color : #808191 !important;
// 		stroke: #808191 !important;
// 	}

// }

.in-section-btn-success {
	// background: rgba(224, 255, 246, 0.5) !important;
	border: 0.6px solid #00D69A !important;

	span {
		color: #808191 !important;
	}

	svg {
		color : #808191 !important;
		stroke: #808191 !important;
	}

}

// .in-section-btn-success:hover {
// 	// background: rgba(224, 255, 246, 0.5) !important;
// 	border: 0.6px solid #00D69A !important;

// 	span {
// 		color: #808191 !important;
// 	}

// 	// svg {
// 	// 	color : #808191 !important;
// 	// 	stroke: #808191 !important;
// 	// }

// }


//....................................................................................................................
.signup-container {
	width           : 197px;
	height          : 40px;
	background-color: transparent;
}

.signup-button {
	width                    : 122px;
	border-radius            : 6px;
	background-color         : $tertiary-brand;
	height                   : 40px;
	cursor                   : pointer;
	border                   : none;
	text-align               : center;
	display                  : inline-block;
	border-top-left-radius   : 0px;
	border-bottom-left-radius: 0px;
}

.btn-container {
	display      : flex;
	border       : 1px solid #00b4d8;
	width        : 319px;
	height       : 40px;
	border-radius: 8px;
}

.button-small {
	width           : fit-content;
	padding         : 5px 10px;
	background-color: $tertiary-brand;
	cursor          : pointer;
	border-radius   : 50px;
}

.button-medium {
	width           : fit-content;
	padding         : 9.5px 23.5px;
	background-color: $tertiary-brand;
	cursor          : pointer;
	border-radius   : 50px;
}

.button-large {
	width           : fit-content;
	padding         : 13px 32.5px;
	background-color: $tertiary-brand;
	cursor          : pointer;
	border-radius   : 50px;
}

.white-button-sm {
	width        : fit-content;
	padding      : 5px 30px;
	border       : 1px solid $tertiary-brand;
	border-radius: 50px;
	cursor       : pointer;
}

.white-button-md {
	width        : fit-content;
	padding      : 9.5px 30px;
	border       : 1px solid $tertiary-brand;
	border-radius: 50px;
	cursor       : pointer;
}

.white-button-lg {
	width        : fit-content;
	padding      : 13px 32px;
	border       : 1px solid $tertiary-brand;
	border-radius: 50px;
	cursor       : pointer;
}

.dashboard-button {
	width        : fit-content;
	padding      : 2.5px 13.5px;
	border-radius: 50px;
	cursor       : pointer;
}

.profile_btn_dimenstions {
	height: 50px;
	width : 196px;
}

.profile_btn {
	@extend .profile_btn_dimenstions;
	border-radius  : 10px;
	background     : white;
	padding        : 5px 10px;
	cursor         : pointer;
	position       : relative;
	display        : flex;
	justify-content: center;
	align-items    : center;
}

.profile_button_options {
	height                    : 38px;
	width                     : 196px;
	padding                   : 10px 10px;
	border-bottom-left-radius : 10px;
	border-bottom-right-radius: 10px;
	background                : white;
	z-index                   : 10;
	border-top                : 1px solid #eaedee;
	cursor                    : pointer;
	position                  : absolute;
	top                       : 60;
}

.profile-btn-switcher {
	width           : 196px;
	height          : auto;
	min-height      : 100px;
	max-height      : 220px;
	border-radius   : 12px;
	background-color: white;
	padding         : 15px 20px;
	justify-content : flex-end;
	align-items     : flex-start;
	display         : flex;
	flex-direction  : column;
	z-index         : 1000;
}

.danger-btn {
	padding      : 10px 24px;
	width        : max-content;
	height       : 40px;
	background   : #FC4848;
	border-radius: 4px;
	outline      : none;
	border       : none;

	span {
		color: white !important;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
	.in-section-btn {
		padding: 10px 0px 10px 12px;

		span {
			font-size  : 12px;
			font-weight: 400;
		}
	}
}

@media only screen and (max-width: 768px) {


	.btn-md {
		justify-content: center;

		.button-processing-indicator-wrapper {
			height: 12px;
			width : 12px;

		}

		svg {
			height: 20px;
			width : 20px;
		}
	}

	.profile_btn_dimenstions {
		height: 30px;
		width : 149px;
	}

	.profile_btn {
		width  : 145px;
		height : 35px;
		padding: 6px;
	}

	.profile_button_options {
		width          : 140px;
		height         : 30px;
		top            : 48px;
		display        : flex;
		justify-content: center;
		align-items    : center;
		// cursor: pointer;
	}


}

@media only screen and (max-width: 992px) {
	// .signup-container{
	//     width: 170px;
	//     height: 28px;
	// }
	// .signup-button{
	//     width:84px;
	//     height: 28px;
	// }
	// .btn-container{
	//     width: 254px;
	//     height: 28px;
	// }
}

.rectangle-button {
	width           : 100%;
	height          : 34px;
	background-color: $dark-100;
	border          : none;
	border-radius   : 24px;

	.title {
		color: $white;
	}

	.button-processing-indicator-wrapper {
		height    : 16px;
		width     : 16px;
		display   : flex;
		align-self: center;

	}

}


.rectangle-button:disabled {
	opacity: 0.7;
}

.rectangle-button:active {
	transform: scale(99.5%);
}