.df-center {
	display        : flex;
	justify-content: center;
	align-items    : center;
}

.pointer {
	cursor: pointer;
}

.primary-bg {
	background-color: $primary-brand;
}

.primary-text {
	color: $primary-text;
}

.primary-dark-text {
	color: $primary-brand;
}

.danger-text {
	color: $danger;
}

a {
	text-decoration: none;
	cursor         : pointer;
}

.black-400 {
	color: $black-400;
}

.black-500 {
	color: $black-500;
}

.black-600 {
	color: $black-600;
}

.black-700 {
	color: $black-700
}

.black-800 {
	color: $black-800
}

.black-900 {
	color: $black-900
}

.dashboard-db {
	background-color: #F5F5F5;
}

.avatar-group {
	display       : flex;
	flex-direction: row-reverse;
	max-width     : fit-content;
	align-items   : center;

	.custom-avatar-badge {
		position   : relative;
		margin-left: -6px;
		font-size  : 11px;
		border     : 2px solid white;
	}

}

.custom-avatar-badge {
	border-radius   : 50%;
	display         : flex;
	justify-content : center;
	align-items     : center;
	color           : white;
	background-color: #121216 !important;
	font-size       : 1.25rem;
	text-transform  : capitalize;
	flex-shrink     : 0;
	padding         : 3px;




	.custom-avatar-img {
		width        : 100%;
		height       : 100%;
		border-radius: 50%;
		object-fit   : cover;
	}
}

.processing-indicator-wrapper {
	// style={{ position: 'relative', top: '0', bottom: '0', left: '0', right: '0', height: '25px', width: '25px', margin: 'auto' }}
	position: relative;
	top     : 0;
	bottom  : 0;
	left    : 0;
	margin  : auto;

	height: 30px;
	width : 30px;

	&.medium-indicator {
		height: 25px;
		width : 25px;
	}

	&.small-indicator {
		height: 18px;
		width : 18px;
	}

}

// .icon-wrapper {

// }