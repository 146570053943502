.assessment-criteria-modal {
    width: 100%;
	height: 700px;
    gap: 32px;
    .assessment-criteria-modal-header {
        width: 100%;
        gap: 4px;
        .headline-medium {
            color: #5E5873;
        }
        .body-3 {
            color: #6E6B7B;
        }
		.assessment-criteria-heading {
			width: 336px;
			height: 34px;
			margin-top: 24px;
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			gap: 24px;
			border-bottom: 2px solid #EBECF0;
			.assessment-criteria-heading-type {
				height: 34px;
				display: flex;
				align-items: center;
				cursor: pointer;
				padding: 7px 0px;
				border-bottom: 2px solid #EBECF0;
			}
		}
    }
	.assessment-detail-container {
		width: 100%;
		overflow-y: scroll;
    	height  : calc(100vh - 285px);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
		.test-container {
			width: 99%;
			height: auto;
			display: flex;
			flex-direction: column;
			padding: 12px 16px;
			gap: 12px;
			background: #FFFFFF;
			border: 1px solid #DEDAF2;
			border-radius: 2px;
			.test {
				width: 100%;
				height: 32px;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				border-bottom: 2px solid #DEDAF2;
				gap: 8px;
			}
			.coding-test-card {
				width: 262px;
				height: auto;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				align-items: flex-start;
				padding: 8px 12px;
				gap: 12px;
				background: #FAF9FB;
				border-radius: 4px;
			}
			.skill-test-card {
				width: auto;
				height: 41px;
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				padding: 12px;
				gap: 12px;
				background: #FAF9FB;
				border-radius: 4px;
			}
			.value-test-card {
				width: auto;
				height: auto;
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				padding: 12px;
				gap: 12px;
				background: #FAF9FB;
				border-radius: 4px;
			}
			.text-response-card{
				width: 100%;
				height: auto;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				padding: 12px;
				gap: 8px;
				background: #FAF9FB;
				border-radius: 4px;
			}
			.case-study-card {
				width: 100%;
				height: auto;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				padding: 12px;
				gap: 24px;
				background: #FAF9FB;
				border-radius: 4px;
			}
			.qq-card{
				width: 100%;
				height: auto;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				padding: 12px;
				gap: 8px;
				background: #FAF9FB;
				border-radius: 4px;
			}
		}
	}
}