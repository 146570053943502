.side-menu-avatar-toggle {
	width        : 270px;
	height       : calc(164px - 56px);
	right        : -260px;
	bottom       : 30px;
	align-items  : flex-start;
	position     : absolute;
	background   : #FFFFFF;
	box-shadow   : 0px 2px 4px rgba(0, 0, 0, 0.08);
	border-radius: 16px;

	.side-menu-avatar-card-logo {
		height     : min-content !important;
		padding    : 16px 20px;
		align-items: center;
		gap        : 5px;
		display    : flex;
		cursor     : pointer;

		.avatar {
			width        : 33.61px;
			height       : 32px;
			border-radius: 6px;
			margin-right : 21px;
		}

		.side-menu-avatar-card-logo-text {
			align-items: flex-start;

			.body-3 {
				color: #56585A;
			}

			.body-4 {
				color: #96999C;
			}
		}
	}

	.side-menu-avatar-toggle-profile {
		width       : 270px;
		height      : 56px;
		align-items : center;
		padding     : 16px 20px;
		box-sizing  : border-box;
		border-width: 1px 0px;
		border-style: solid;
		border-color: #E1E1E1;
		cursor      : pointer;

		.side-menu-avatar-toggle-profile-icon {
			width : 24px;
			height: 24px;
		}

		span {
			margin-left : 20px;
			margin-right: 90px;
			color       : #333333;
		}
	}

	.side-menu-avatar-toggle-logout {

		border-color : #E1E1E1;
		width        : 270px;
		height       : 56px;
		align-items  : center;
		padding      : 10px 20px;
		border-radius: 0px 0px 16px 16px;
		cursor       : pointer;

		.side-menu-avatar-toggle-logout-icon {
			width : 24px;
			height: 24px;
		}

		span {
			margin-left: 20px;
			color      : #333333;
		}
	}

	// .side-menu-avatar-toggle-logout:hover {
	// 	background-color: #FFBDAD;
	// }

	// .side-menu-avatar-toggle-profile:hover {
	// 	background-color: #DEDAF2;
	// }

	// #DEDAF2
}

@media screen and (max-width: 767px) {
	.side-menu-avatar-toggle {
		right : 45px;
		bottom: 80px;
	}
}