@import '../../../style-assets/typography';

.section-container {
	width                   : 100%;
	display                 : flex;
	flex-direction          : column;
	align-items             : flex-start;
	justify-content         : flex-start;
	padding                 : 24px;
	// max-width            : 996px;
	width                   : 100%;
	height                  : min-content;
	background              : #F6F5F8;
	// border         : 1px solid #dedaf2;
	// box-shadow        : 4px 4px 40px rgba(173, 161, 186, 0.15);
	border-radius           : 24px;

	.svg {
		fill  : #56585a;
		stroke: #56585a;

		path {
			fill: none;
		}
	}

	.section-data-confermation {
		width          : 100%;
		display        : flex;
		justify-content: flex-end;
		align-items    : center;
		margin-top     : 32px;
	}

	&.error {
		border-color: $danger !important;
	}
}

.default-section-div {

	position: relative;

	.default-section-options {
		display       : inline-flex;
		flex-direction: column;
		align-items   : flex-start;
		border-radius : 24px;
		background    : var(--Dark-10, #FFF);
		position      : absolute;
		z-index       : 1;
		/* Drop Shadow */
		box-shadow    : 0px 8px 24px 0px rgba(149, 157, 165, 0.20);
		top           : 50px;

		.default-section-option {
			display    : flex;
			padding    : 12px 24px;
			align-items: center;
			gap        : 4px;
			align-self : stretch;
			border     : inherit;

			&:hover {
				background-color: #121216 !important;
				color           : white !important;

				span {
					color: white !important;
				}
			}
		}
	}
}

.minize-view-of-section-container {
	width          : 100%;
	height         : auto;
	display        : flex;
	flex-direction : column;
	align-items    : flex-start;
	justify-content: space-between;
	background     : #F6F5F8;
	padding        : 16px 24px;
	border-radius  : 12px;
	gap            : 8px;


	svg {
		width : 16px;
		height: 16px;
		fill  : #56585a;
		stroke: #56585a;

		path {
			fill: none;
		}
	}

	.section-data-confermation {
		display   : flex;
		align-self: center;
		margin-top: 26px;
	}
}

.minize-view-of-section-container-error {
	border: 0.6px solid #fc4848 !important;
}

.section-file-upload-container {
	width: 100%;
}


.skill-based-test-container {
	width     : 100%;
	display   : flex;
	flex-wrap : wrap;
	margin-top: 16px;
	gap       : 18px;
	position  : relative;

	.skill-based-test-box-container {
		// width                : calc(24.5% - 12px);
		width                : 23.70%;
		// width          : 305px;
		min-width            : 223px;
		// max-width         : 335px;
		height               : 171px;
		padding              : 18px;
		display              : flex;
		flex-direction       : column;
		justify-content      : space-between;

		background: #ffffff;

		/* Primary/Light */
		// border       : 0.6px solid #dedaf2;
		border-radius: 24px;

		.skill-based-test-box-container-text-overflow {
			text-align        : left;
			width             : 100%;
			display           : -webkit-box;
			max-width         : 100%;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow          : hidden;
		}


		.skill-based-test-box-btn {
			width          : 100%;
			padding        : 6px;
			align-items    : center;
			justify-content: center;
			display        : flex;
			background     : #000000;
			margin-top     : 12px;
			/* Primary/Dark */
			border         : 1px solid #392d69;
			border-radius  : 50px !important;
			color          : white !important;

			span {
				color: white !important;
			}

			svg {
				fill         : #ffffff;
				color        : #ffffff;
				// margin-top: -6px !important;

				path {
					fill  : #ffffff;
					stroke: #ffffff;
				}
			}
		}

		.disabled-skill-based-test-box-btn {
			background     : #EAEDEE;
			border         : none;
			border-radius  : 50px !important;
			align-items    : center;
			justify-content: center;

			span {
				color      : #96999C;
				font-weight: 500 !important;
			}

			svg {
				path {
					stroke: #96999C !important
				}
			}
		}

		.selected-skill-based-test-box-btn {
			background     : rgba(252, 72, 72, 0.73);
			border         : 1px solid rgba(252, 72, 72, 0.73);
			border-radius  : 50px !important;
			width          : 100%;
			padding        : 0;
			align-items    : center;
			justify-content: center;
			display        : flex;

			svg {
				fill : white;
				color: white;

				path {
					fill  : white;
					stroke: white;
				}
			}

			span {
				color: white;
			}
		}
	}
}

.error-box {
	display       : flex;
	flex-direction: row;
	box-sizing    : border-box;
	text-align    : start;
	align-items   : center;
	width         : 100%;
	padding       : 8px 18px 12px 18px;
	gap           : 8px;
	background    : rgb(255, 255, 255);
	width         : auto;
	height        : auto;

	// border       : 0.6px solid #fc4848;
	border-radius: 24px;

	flex     : none;
	flex-grow: 0;
}

@media only screen and (max-width: 1440px) {
	.skill-based-test-container {


		.skill-based-test-box-container {
			// width                   : 31%;
			width                   : calc(33.33% - 13px) !important;
			// min-width            : 220px;
			// max-width         : 335px;
			height                  : 181px;
			padding                 : 18px;
			display                 : flex;
			flex-direction          : column;
			justify-content         : space-between;
			background              : #ffffff;
			border-radius           : 24px;
		}
	}

}

@media only screen and (max-width: 1199px) {
	.skill-based-test-container {


		.skill-based-test-box-container {
			width                : 31.7%;
			min-width            : 223px;
			// max-width         : 335px;
			height               : 181px;
			padding              : 18px;
			display              : flex;
			flex-direction       : column;
			justify-content      : space-between;
			background           : #ffffff;
			border-radius        : 24px;
		}
	}

}

@media only screen and (max-width: 900px) {
	.skill-based-test-container {


		.skill-based-test-box-container {
			width                : 48.3%;
			min-width            : 223px;
			// max-width         : 335px;
			height               : 181px;
			padding              : 18px;
			display              : flex;
			flex-direction       : column;
			justify-content      : space-between;
			background           : #ffffff;
			border-radius        : 24px;
		}
	}

}


//--------------------------------------------------------------------------Value Fitness
.value-fitness-test-container {
	display        : flex;
	justify-content: space-between;
	columns        : 22px;
	flex-wrap      : wrap;
	margin-top     : 16px;
	gap            : 22px;
	row-gap        : 24px;
	width          : 100%;

	.value-button-container {
		background      : #ffffff;
		border          : 0.6px solid #96999c;
		border-radius   : 4px;
		// min-width    : 300px;
		width           : calc(33.33% - 15px);
		max-width       : 300px;
		height          : 40px;

		display        : flex;
		align-items    : center;
		justify-content: flex-start;
		padding        : 0px 18px;

		span {
			@extend .body-3;
			font-weight: 300 !important;
			color      : #96999c !important;
		}
	}

	.selected-value-button-container {
		background: rgba(222, 218, 242, 0.3);

		/* Primary/Light */
		border            : 1px solid #dedaf2;
		border-radius     : 4px;
		width             : calc(33.33% - 15px);
		// min-width      : 300px;
		max-width         : 300px;
		height            : 40px;
		display           : flex;
		align-items       : center;
		justify-content   : flex-start;
		padding           : 0px 18px;

		span {
			@extend .body-3;
			font-weight: 500 !important;
			color      : #2b3340 !important;
		}
	}
}


//--------------------------------------------------------------------------Coding Test
.search-and-filter-test-container {
	width          : 100%;
	display        : flex;
	flex-direction : row;
	align-items    : center;
	justify-content: space-between;

	.search-and-filter-test-inner-container {
		width         : calc(100% - 175px);
		display       : flex;
		flex-direction: row;
		align-items   : center;
		gap           : 6px;
		position      : relative;
	}

	.pagination-test-container {
		display       : flex;
		flex-direction: row;
		align-items   : center;
		gap           : 4px;
	}
}

.coding-test-filter-container {
	width     : 272px;
	height    : min-content;
	position  : absolute;
	padding   : 24px;
	right     : 0;
	top       : 54px;
	background: #FFFFFF;
	gap       : 24px;

	z-index         : 2;
	// border       : 1px solid #DEDAF2;
	box-shadow      : 0px 4px 24px rgba(0, 0, 0, 0.06);
	border-radius   : 24px;

	.filter-header {
		width             : 100%;
		// padding        : 16px 12px;
		display           : flex;
		flex-direction    : row;
		justify-content   : space-between;
	}

	.primary-checkbox-title {
		@extend .body-2;
		// font-weight: 400 !important;
		color: #121216 !important;
	}

	.radio-section {
		width          : 100%;
		display        : flex;
		flex-direction : column;
		align-items    : flex-start;
		justify-content: center;

		.select-radio {
			width             : 100%;
			padding           : 6px 8px;
			display           : flex;
			justify-content   : flex-start;
			align-items       : center;
			// border-bottom  : 0.6px solid #C8CBCD;
			margin-bottom     : 6px;

			input[type='radio'] {
				width       : 15px;
				height      : 15px;
				accent-color: $dark-100;
			}
		}
	}

	.checkbox-section {
		width             : 100%;
		// padding        : 24px 20px;
		display           : flex;
		flex-direction    : column;
		align-items       : flex-start;
		justify-content   : center;
		gap               : 8px;

		.select-checkbox {
			padding: 0px 12px;
		}
	}
}

.coding-filter-label {
	display              : flex;
	flex-direction       : row;
	align-items          : center;
	justify-content      : space-between;
	padding              : 12px 16px 12px 16px;
	gap                  : 24px;
	width                : auto;
	height               : auto;
	background           : #121216;
	border-radius        : 24px;
	height               : 40px;
	// width             : 147px;
}

.selected-test-overflow-arrow-left {
	display           : flex;
	width             : 56px;
	height            : 171px;
	padding           : 35px 4px;
	flex-direction    : column;
	justify-content   : center;
	align-items       : center;
	border-radius     : 8px 0px 0px 8px;
	background        : rgba(222, 218, 242, 0.80);
	bottom            : 0px;
	// opacity        : 0;

	// &:hover {
	// 	opacity: 1;
	// }
}

.searched-filtered-ct-container {
	display         : flex;
	width           : 100%;
	gap             : 16px;
	padding         : 6px 0px;
	// border-bottom: 1px solid #DEDAF2;
	overflow        : auto;


	.searched-filtered-ct {
		// width        : 28.5%;
		min-width       : 223px;
		width           : 259px;
		height          : 171px;
		background      : #ffffff;
		padding         : 18px;
		/* Accent/Light Purple */
		// border       : 1px solid #6F2DBD;
		border-radius   : 24px;
		margin-top      : 16px;

		.selected-coding-test-box-container {
			margin-top: 0px !important;
		}

		.text-overflow {
			text-align        : left;
			width             : 100%;
			display           : -webkit-box;
			max-width         : 100%;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow          : hidden;
		}

		.selected-skill-based-test-box-btn,
		.selected-coding-test-box-btn {
			background     : rgba(252, 72, 72, 0.73);
			border-radius  : 50px !important;
			align-items    : center;
			justify-content: center;
			margin-top     : 12px;

			span {
				color      : white;
				font-weight: 500 !important;
			}

			svg {
				path {
					stroke: white !important
				}
			}
		}
	}
}

.coding-test-container {
	width     : 100%;
	display   : flex;
	flex-wrap : wrap;
	margin-top: 16px;
	gap       : 16px;
	row-gap   : 24px;
	position  : relative;

	.coding-test-box-container {
		width          : 23.90%;
		min-width      : 223px;
		height         : 171px;
		padding        : 18px;
		display        : flex;
		flex-direction : column;
		justify-content: space-between;

		background: #ffffff;

		/* Primary/Light */
		// border       : 0.6px solid #dedaf2;
		border-radius: 24px;

		.coding-test-box-container-text-overflow {
			text-align        : left;
			width             : 100%;
			display           : -webkit-box;
			max-width         : 100%;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow          : hidden;
		}

		.coding-test-box-btn {
			width          : 100%;
			padding        : 6px;
			align-items    : center;
			justify-content: center;
			display        : flex;
			background     : #000000;
			margin-top     : 12px;
			/* Primary/Dark */
			border         : 1px solid #392d69;
			border-radius  : 50px !important;
			color          : white !important;

			span {
				color: white !important;
			}

			svg {
				fill         : #ffffff;
				color        : #ffffff;
				// margin-top: -6px !important;

				path {
					fill  : #ffffff;
					stroke: #ffffff;
				}
			}
		}

		.disabled-coding-test-box-btn {
			background     : #EAEDEE;
			border         : none;
			border-radius  : 50px !important;
			align-items    : center;
			justify-content: center;

			span {
				color      : #96999C;
				font-weight: 500 !important;
			}

			svg {
				path {
					stroke: #96999C !important
				}
			}
		}

		.selected-coding-test-box-btn {
			background     : rgba(252, 72, 72, 0.73);
			border         : 1px solid rgba(252, 72, 72, 0.73);
			border-radius  : 50px !important;
			width          : 100%;
			padding        : 0;
			align-items    : center;
			justify-content: center;
			display        : flex;

			svg {
				fill : white;
				color: white;

				path {
					fill  : white;
					stroke: white;
				}
			}

			span {
				color: white;
			}
		}
	}
}

@media only screen and (max-width: 1440px) {
	.coding-test-container {


		.coding-test-box-container {
			width    : calc(33.33% - 11px) !important;
			min-width: 223px;
			height   : 181px;
		}
	}

}

@media only screen and (max-width: 1199px) {
	.coding-test-container {


		.coding-test-box-container {
			width    : 31.7%;
			min-width: 223px;
			height   : 181px;
		}
	}

}

@media only screen and (max-width: 900px) {
	.coding-test-container {


		.coding-test-box-container {
			width    : 48.3%;
			min-width: 223px;
			height   : 181px;
		}
	}

}



//---------------------------------------------------------------------------------------Text Test

.text-response-question-container {
	width           : 100%;
	padding         : 24px;
	background      : #ffffff;
	// border       : 0.6px solid #c8cbcd;
	border-radius   : 24px;
	gap             : 16px;
	display         : flex;
	flex-direction  : column;

	.text-response-question-upper-container {
		width         : 100%;
		display       : flex;
		flex-direction: row;
		flex-wrap     : wrap;
		align-items   : flex-start;
		gap           : 16px;
	}

	.text-response-question-container-menu {
		display        : flex;
		align-items    : center;
		justify-content: flex-end;
		position       : relative;

		.container-menu {
			min-width         : 147px;
			// height         : 88px;
			background        : #ffffff;
			border            : 0.6px solid #c8cbcd;
			box-shadow        : 0px 8px 24px 0px #959DA533;
			;
			border-radius  : 4px;
			display        : flex;
			flex-direction : column;
			align-items    : center;
			justify-content: center;
			position       : absolute;
			right          : 24px;
			top            : 16px;
			z-index        : 1;

			div {
				padding        : 8px 24px 8px 24px;
				display        : flex;
				justify-content: flex-start;
				width          : 100%;
				align-items    : center;
				cursor         : pointer;
			}

			div:hover {
				background-color: #392d69;
				width           : 100%;

				span {
					color: white;
				}
			}
		}
	}
}

.add-question {
	width         : max-content;
	height        : auto;
	display       : flex;
	flex-direction: row;
	align-items   : center;
	cursor        : pointer;
	padding       : 8px 24px;
	background    : #ffffff;
	border        : 1px solid #121216;
	border-radius : 24px;
	gap           : 8px;
}

//---------------------------------------------------------------------------------------Testpack Detail

.view-testpack-details-container {
	width                  : 100% !important;
	// max-width           : 1200px;
	height                 : 90vh;
	background             : #FFFFFF;
	// border           : 0.6px solid #DEDAF2;
	border-radius          : 24px;
	display                : flex;
	flex-direction         : column;
	align-items            : flex-start;
	// gap           : 32px;
	position               : relative;
	overflow-y             : auto;

	.cross-icon {
		position: absolute;
		right   : 24px;
		top     : 24px;
		cursor  : pointer;
	}

	.VTD-container-header {
		display             : flex;
		flex-direction      : column;
		align-items         : flex-start;
		gap                 : 12px;
		padding             : 24px;
		// border-bottom : 1px solid #DEDAF2;
		width               : 100%;
		// margin-top       : 64px;

		// span {
		// 	max-width: 854px;
		// }



		button {
			// margin-top     : 4px;
			height            : 44px !important;
			// width          : 240px !important;
			background        : #121216;
			border-radius     : 50px !important;
			display           : flex;
			align-items       : center;
			justify-content   : center;

			svg {
				path {
					stroke: #ffffff;
				}
			}
		}

		.coding-test-box-btn {
			background        : #121216;
			// border         : 1px solid #392D69;
			border-radius     : 50px !important;
			display           : flex;
			align-items       : center;
			justify-content   : center;

			span {
				color         : white;
				// font-weight: 500 !important;
			}

			svg {
				path {
					stroke: white !important
				}
			}
		}

		.disabled-coding-test-box-btn {
			background     : rgba(57, 45, 105, 0.30);
			border         : none;
			border-radius  : 50px !important;
			align-items    : center;
			justify-content: center;

			span {
				color      : white;
				font-weight: 500 !important;
			}

			svg {
				path {
					stroke: white !important
				}
			}
		}

		.selected-coding-test-box-btn {
			background     : rgba(252, 72, 72, 0.73);
			border         : 1px solid rgba(252, 72, 72, 0.73);
			border-radius  : 50px !important;
			align-items    : center;
			justify-content: center;

			span {
				color      : white;
				font-weight: 500 !important;
			}

			svg {
				path {
					stroke: white !important
				}
			}
		}
	}

	.VTD-container-details {
		padding          : 24px;
		padding-top      : 0px;
		width            : 100%;
		display          : flex;
		flex-direction   : column;
		// gap           : 32px;
		position         : relative;


		span {
			text-align: left;
		}

		.details-and-coverd-skills-sections {
			display          : flex;
			flex-direction   : column;
			// flex-wrap     : wrap;
			// row-gap       : 40px;
			width            : 100%;



			.details-section {
				width         : 100%;
				display       : flex;
				flex-direction: row;
				flex-wrap     : wrap;
				gap           : 16px;
				min-width     : 540px;

				.test-detail-box {
					width                   : 30%;
					height                  : 100px;
					background              : #FFFFFF;
					// border            : 0.6px solid #6F2DBD;
					// box-shadow           : 0px 0px 20px rgba(0, 0, 0, 0.1);
					border-radius           : 24px;
					display                 : flex;
					// flex-direction : column;
					align-items             : center;
					justify-content         : start;
					padding                 : 24px;
					background-color        : #F6F5F8;
					justify-content         : space-between;
					align-items             : center;

					span {
						text-align: left;
					}
				}
			}

			.coverd-skills-section {
				width         : 100%;
				display       : flex;
				flex-direction: column;
				gap           : 16px;
				border        : 1px solid #ECEDF1;
				padding       : 24px;
				border-radius : 24px;
				margin-top    : 24px;

				span {
					text-align: left;
				}
			}
		}

		.details-and-coverd-skills-sub-section {
			border       : 1px solid #ECEDF1;
			padding      : 24px;
			border-radius: 24px;
			margin-top   : 24px;
		}
	}
}

// @media only screen and (min-width: 768px) and (max-width: 1440px) {
// 	.section-container {
// 		padding: 24px 16px;

// 		svg {
// 			width : 16px;
// 			height: 16px;
// 		}
// 	}

// 	.search-and-filter-test-container {
// 		flex-direction : column;
// 		align-items    : flex-end;
// 		justify-content: flex-end;
// 		gap            : 16px;

// 		.search-and-filter-test-inner-container {
// 			width: 100%;
// 		}

// 		.pagination-test-container {
// 			flex-direction: row;
// 			align-items   : flex-end;
// 			gap           : 4px;
// 		}
// 	}

// 	.skill-based-test-container {
// 		width: 100%;
// 		gap  : 8px;

// 		.skill-based-test-box-container {
// 			width: calc(50% - 6px) !important;
// 		}
// 	}

// 	.coding-test-container {
// 		width: 100%;
// 		gap  : 8px;

// 		.coding-test-box-container {
// 			width: calc(50% - 6px);
// 		}
// 	}

// 	.value-fitness-test-container {
// 		width  : 100%;
// 		gap    : 8px;
// 		row-gap: 12px;

// 		.value-button-container {
// 			width: calc(50% - 6px);
// 		}

// 		.selected-value-button-container {
// 			width: calc(50% - 6px);
// 		}
// 	}

// 	.text-response-question-container {
// 		.text-response-question-upper-container {
// 			flex-direction: column;
// 			gap           : 12px;
// 		}

// 		.text-response-question-container-menu {
// 			width        : 32px;
// 			padding-right: 0px;
// 		}
// 	}
// }

@media only screen and (max-width: 767px) {
	.section-container {
		padding: 24px 16px;

		svg {
			width : 16px;
			height: 16px;
		}
	}

	.search-and-filter-test-container {
		flex-direction : column;
		align-items    : flex-end;
		justify-content: flex-end;
		gap            : 16px;

		.search-and-filter-test-inner-container {
			width: 100%;
		}

		.pagination-test-container {
			flex-direction: row;
			align-items   : flex-end;
			gap           : 4px;
		}
	}

	.skill-based-test-container {
		width: 100%;
		gap  : 8px;

		.skill-based-test-box-container {
			width    : 48.3% !important;
			max-width: 100%;
		}
	}

	.coding-test-container {
		width: 100%;
		gap  : 8px;

		.coding-test-box-container {
			width    : 48.3% !important;
			max-width: 100%;
		}
	}

	.value-fitness-test-container {
		width  : 100%;
		gap    : 8px;
		row-gap: 12px;

		.value-button-container {
			width    : 48.3% !important;
			max-width: 100%;
		}

		.selected-value-button-container {
			width    : 100%;
			max-width: 100%;
		}
	}

	.text-response-question-container {
		.text-response-question-upper-container {
			flex-direction: column;
			gap           : 12px;
		}

		.text-response-question-container-menu {
			width        : 32px;
			padding-right: 0px;
		}
	}

	.coding-test-filter-container {
		width: 220px;
	}

	.view-testpack-details-container {
		width     : 100vw;
		height    : 100%;
		height    : auto;
		overflow-y: hidden;

		.VTD-container-details {
			padding: 0px 24px 32px 24px;

			.details-and-coverd-skills-sections {
				flex-direction: column;

				.details-section {
					width         : 100%;
					flex-direction: column;
					min-width     : 100%;
				}

				.coverd-skills-section {
					width: 100%;
				}
			}
		}
	}
}

@media only screen and (max-width: 550px) {


	.skill-based-test-container {
		width: 100%;
		gap  : 8px;

		.skill-based-test-box-container {
			width    : 100% !important;
			max-width: 100%;
		}
	}

	.coding-test-container {
		width: 100%;
		gap  : 8px;

		.coding-test-box-container {
			width    : 100% !important;
			max-width: 100%;
		}
	}

	.value-fitness-test-container {
		width  : 100%;
		gap    : 8px;
		row-gap: 12px;

		.value-button-container {
			width    : 100%;
			max-width: 100%;
		}

		.selected-value-button-container {
			width    : 100%;
			max-width: 100%;
		}
	}



}



@media only screen and (max-width: 767px) {
	.view-testpack-details-container {
		.VTD-container-details {
			.details-and-coverd-skills-sections {
				.details-section {
					.test-detail-box {
						width                   : 100% !important;
						height                  : 100px;
						background              : #FFFFFF;
						// border            : 0.6px solid #6F2DBD;
						// box-shadow           : 0px 0px 20px rgba(0, 0, 0, 0.1);
						border-radius           : 24px;
						display                 : flex;
						// flex-direction : column;
						align-items             : center;
						justify-content         : start;
						padding                 : 24px;
						background-color        : #F6F5F8;
						justify-content         : space-between;
						align-items             : center;

						span {
							text-align: left;
						}
					}
				}

				.coverd-skills-section {
					width         : 100%;
					display       : flex;
					flex-direction: column;
					gap           : 16px;
					border        : 1px solid #ECEDF1;
					padding       : 24px;
					border-radius : 24px;
					margin-top    : 24px;

					span {
						text-align: left;
					}
				}
			}

			.details-and-coverd-skills-sub-section {
				border       : 1px solid #ECEDF1;
				padding      : 24px;
				border-radius: 24px;
				margin-top   : 24px;
			}
		}
	}
}