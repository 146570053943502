.support-toggle{
	width: 270px;
	height: 201px;
	margin-left: 220px;
	margin-bottom: 130px;
	align-items: flex-start;
	position: absolute;
	background: #FFFFFF;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
	border-radius: 16px;
	.support-toggle-text {
		width: 270px;
		height: 56px;
		padding: 20px 20px;
		align-items: flex-start;
		box-sizing: border-box;
		border-width: 1px 0px;
		border-bottom-style: solid;
		border-color: #E1E1E1;
		margin-bottom: 10px;
		.body-2 {
			margin-left: -150px;
			color: #333333;
		}
	}
	.support-toggle-user {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 10px 24px;
		width: 274px;
		height: 40px;
		.support-toggle-user-icon {
			width: 24px;
			height: 24px;
		}
		.body-3 {
			color: #333333;
			margin-left: 20px;
		}
		.support-toggle-arrow-icon {
			width: 10px;
			height: 10px;
			margin-left: auto;
			margin-right: 10px;
		}
	}
}