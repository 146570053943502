.assessment-cards {
    width         : 100%;
    height        : calc(100vh - 195px);
    overflow      : auto;
    display       : flex;
    flex-direction: column;
    background    : #FFFFFF;
    padding       : 24px;
    border-radius : 24px;
    overflow-x    : hidden;
    gap           : 16px;

    .assessment-card-container {
        width          : 100%;
        height         : auto;
        display        : flex;
        flex-direction : row;
        align-items    : center;
        justify-content: space-between;
        background     : #F6F5F8;
        padding        : 18px 24px;
        border-radius  : 24px;
        gap            : 10px;

        .detail-container {
            width         : 100%;
            height        : auto;
            display       : flex;
            flex-direction: column;
            align-items   : flex-start;
            gap           : 6px;

            .assessment-icon {
                width : 40px;
                height: 40px;
            }

            .details {
                width          : 100%;
                display        : flex;
                flex-direction : row;
                align-items    : center;
                justify-content: space-between;
                gap            : 12px;

                .details-progress {
                    width          : 110px;
                    display        : flex;
                    align-items    : flex-end;
                    justify-content: flex-end;
                }
            }

            .company-detail {
                display       : flex;
                flex-direction: row;
                flex-wrap     : wrap;
                align-items   : center;
                gap           : 12px;

                .time {
                    display       : flex;
                    flex-direction: row;
                    align-items   : center;
                    gap           : 4px;
                }
            }
        }

        .detail-container-right {
            display        : flex;
            align-items    : flex-end;
            justify-content: end;
        }
    }

    .selected-assessment,
    .assessment-card-container:hover {
        background: #ECEDF1;
    }

    .selected-assessment {
        cursor: default !important;
    }

    .assessment-card-container:hover {
        cursor: pointer;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1339px) {
    .assessment-cards {
        .assessment-card-container {
            width: 100%;
            gap  : 0px;
        }
    }
}

@media screen and (max-width: 767px) {
    .assessment-cards {
        height: calc(100vh - 265px);

        .assessment-card-container {
            width         : 100%;
            flex-direction: column;
            gap           : 0px;

            .detail-container {
                .details {
                    gap: 6px;
                }

                @media screen and (max-width: 480px) {
                    .details {
                        position: relative;

                        .details-progress {
                            position: absolute;
                            right   : 0px;
                            top     : 40px;
                        }
                    }
                }

                .company-detail {
                    flex-direction: column;
                    align-items   : flex-start;
                }
            }

            .detail-container-right {
                width     : 100%;
                margin-top: 12px;
            }
        }
    }
}