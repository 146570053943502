.assessment-criteria-heading {
	width            : 100%;
	height           : 44px;
	display          : flex;
	flex-direction   : row;
	justify-content  : space-between;
	align-items      : center;
	// margin-top    : 24px;
	padding          : 0px 24px;
	border-radius    : 24px;
	background       : #FFFFFF;
	gap              : 24px;

	.assessment-criteria-heading-type {
		height     : 43px;
		display    : flex;
		align-items: center;
		cursor     : pointer;
		padding    : 7px 0px;
	}

	.assessment-criteria-heading-type:hover,
	.assessment-criteria-heading-type-selected {
		border-bottom: 4px solid #121216;

		span {
			color: #121216;
		}
	}
}

.assessment-criteria {
	width        : 100%;
	height       : auto;
	gap          : 24px;
	overflow-wrap: anywhere;

	.assessment-criteria-header {
		width           : 100%;
		display         : flex;
		flex-direction  : column;
		background-color: white;
		padding         : 24px;
		border-radius   : 24px;
		gap             : 18px;
	}

	.assessment-detail-container {
		width         : 100%;
		height        : auto;
		display       : flex;
		flex-direction: column;
		align-items   : flex-start;
		padding       : 24px;
		border-radius : 24px;
		background    : #FFFFFF;
		gap           : 16px;

		.test-container {
			width         : 100%;
			height        : auto;
			display       : flex;
			flex-direction: column;
			padding       : 16px 18px;
			border-radius : 24px;
			background    : #F6F5F8;
			gap           : 24px;

			.questions-container {
				width         : 100%;
				display       : flex;
				flex-direction: row;
				flex-wrap     : wrap;
				align-items   : flex-start;
				gap           : 24px;
			}

			.test {
				width          : 100%;
				height         : 24px;
				display        : flex;
				flex-direction : row;
				justify-content: space-between;
				align-items    : center;
				gap            : 8px;
			}

			.coding-test-card {
				width         : calc(50% - 24px);
				height        : auto;
				min-width     : calc(50% - 24px);
				display       : flex;
				flex-direction: row;
				align-items   : center;
				padding       : 12px 18px;
				border-radius : 24px;
				background    : #FFFFFF;
				gap           : 12px;

				@media (max-width: 768px) {
					text-align: left;
					min-width : 0px;
					width     : 100%;
				}
			}

			.skill-test-card {
				width         : calc(50% - 24px);
				height        : auto;
				min-width     : calc(50% - 24px);
				display       : flex;
				flex-direction: row;
				align-items   : center;
				padding       : 12px 18px;
				border-radius : 24px;
				background    : #FFFFFF;
				gap           : 12px;

				@media (max-width: 768px) {
					text-align: left;
					min-width : 0px;
					width     : 100%;
				}
			}

			.value-test-card {
				width         : auto;
				height        : auto;
				min-width     : 250px;
				display       : flex;
				flex-direction: row;
				align-items   : center;
				padding       : 12px 18px;
				border-radius : 24px;
				background    : #FFFFFF;
				gap           : 12px;

				@media (max-width: 768px) {
					min-width: 0px;
					width    : 100%;
				}
			}

			.personality-test-group {
				display        : flex;
				flex-direction : column;
				justify-content: start;
				gap            : 4px;
			}

			.text-response-card {
				width         : 100%;
				height        : auto;
				display       : flex;
				flex-direction: column;
				align-items   : flex-start;
				padding       : 12px 18px;
				border-radius : 24px;
				background    : #FFFFFF;
				gap           : 12px;
			}

			.case-study-card {
				width         : 100%;
				height        : auto;
				display       : flex;
				flex-direction: column;
				align-items   : flex-start;
				padding       : 24px;
				border-radius : 24px;
				background    : #FFFFFF;
				gap           : 16px;
			}

			.qq-card {
				width         : 100%;
				height        : auto;
				display       : flex;
				flex-direction: column;
				align-items   : flex-start;
				padding       : 16px 18px;
				border-radius : 24px;
				background    : #F6F5F8;
				gap           : 24px;
			}

			.welcome-video-criteria-section {
				height: 210px;
				width : 390px;

				@media (max-width: 768px) {
					height: 100%;
					width : 100%;
				}

				video {
					border-radius: 12px;
				}
			}
		}
	}
}