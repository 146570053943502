@import "./main";

.App {
    text-align        : center;
    width             : 100vw;
    height            : 100vh;
    // scroll-behavior: smooth;
    overflow-x        : hidden;

}

.disable-text-selection {
    -webkit-user-select: none;
    -moz-user-select   : none;
    -ms-user-select    : none;
    user-select        : none;
}



.option-read-only {
    .ql-editor {
        h1 {
            cursor: pointer;
        }

        h2 {
            cursor: pointer;
        }

        b {
            cursor: pointer;
        }

        p {
            cursor: pointer;
        }

        img {
            cursor: pointer;
        }

        ol li {
            cursor: pointer;
        }

        ul li {
            cursor: pointer;
        }
    }
}

.text-read-only {
    .ql-editor {
        h1 {
            cursor: default;
        }

        h2 {
            cursor: default;
        }

        b {
            cursor: default;
        }

        p {
            cursor: default;
        }

        img {
            cursor: default;
        }

        ol li {
            cursor: default;
        }

        ul li {
            cursor: default;
        }
    }
}

.intercom-lightweight-app-launcher {
    bottom: 24px !important;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow   : inset 0 0 5px rgb(207, 207, 207);
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background   : rgb(179, 179, 179);
    border-radius: 5px;
}

.App-logo {
    height        : 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height      : 100vh;
    display         : flex;
    flex-direction  : column;
    align-items     : center;
    justify-content : center;
    font-size       : calc(10px + 2vmin);
    color           : white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.loading-skeleton {
    display           : block;
    width             : 80px;
    min-height        : 5px;
    background-color  : #acacac;
    border-radius     : 7px;
    animation         : skeleton_animation infinite linear;
    animation-duration: 1s;
}

@keyframes skeleton_animation {
    0% {
        background-color: #acacac;
    }

    50% {
        background-color: #d4d4d4;
    }

    100% {
        background-color: #acacac;
    }
}

.processing-indicator {
    display            : block;
    height             : 100%;
    width              : 100%;
    // background-color: red;
    border-top         : 2px solid rgb(255, 255, 255);
    border-left        : 2px solid rgb(255, 255, 255);
    border-right       : 2px solid rgb(255, 255, 255);
    border-bottom      : 2px solid transparent;
    border-radius      : 100%;
    animation          : processing_animation infinite linear;
    animation-duration : 0.8s;

    &.primary {
        border-top  : 2px solid $primary-brand;
        border-left : 2px solid $primary-brand;
        border-right: 2px solid $primary-brand;
    }

    &.grey {
        border-top  : 2px solid $black-400;
        border-left : 2px solid $black-400;
        border-right: 2px solid $black-400;
    }

}

// .processing-indicator.primary {
// }

.processing-stripe {

    background     : repeating-linear-gradient(-55deg, $primary-brand, $primary-brand 10px, #6F2DBD 10px, #6F2DBD 20px);
    animation      : barberpole 20s linear infinite reverse;
    background-size: 200% 200%;

}

// .processing-button::before {
// 	content         : '';
// 	display         : block;
// 	position        : absolute;
// 	height          : 100%;
// 	width           : 100%;
// 	background-color: rgba(255, 255, 255, 0.25);
// 	margin          : auto;
// 	top             : 0;
// 	left            : 0;
// 	bottom          : 0;
// 	right           : 0;
// }

@keyframes processing_animation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes barberpole {
    100% {
        background-position: 100% 100%;
    }
}

.ql-container {
    font-family: 'Urbanist' !important;
}

@media screen and (max-width: 767px) {
    .App::-webkit-scrollbar {
        display: none;
    }
}

// video::-webkit-media-controls-play-button {
//     display: none;
// }

// video::-webkit-media-controls-mute-button {
// 	display: none;
// }
// video::-webkit-media-controls-fullscreen-button {
// 	display: none;
// }
// video::-webkit-media-controls-volume-slider-mute-button {
// 	display: none;
// }
// video::-webkit-media-text-track-container {
// 	display: none;
// }

// video::-webkit-media-controls-download-button {
//     display:none;
// }
// video::-webkit-media-controls-download-button {
//     display:none;
// }