@import "../../../mixins/typegraphyMixin";

.qualifying-questions-outer-container {
    background: #FFFFFF;

    /* Primary/Light */
    border       : 1px solid #DEDAF2;
    border-radius: 6px;
    padding      : 24px 32px;
    margin-bottom: 32px;
}

.question-box {
    width           : 100%;
    background      : #FFFFFF;
    margin-bottom   : 16px;
    // margin-top   : 24PX;
    display         : flex;
    border-radius   : 24px;
    padding         : 24px;

    .question-box-question-section {
        width         : 100%;
        display       : flex;
        flex-direction: column;
        align-items   : flex-start;


        .question-answer-selection-container {
            display        : flex;
            flex-direction : row;
            justify-content: space-between;
            width          : 100%;
            gap            : 24px;

            .select-container {
                max-width: none !important;
            }

            // .question-input-responsive,
            // .question-select-responsive {
            //     span {
            //         font-weight: 600;
            //     }
            // }
        }
    }

    .question-box-action-section {
        width          : 8.5%;
        display        : flex;
        flex-direction : column;
        align-items    : center;
        justify-content: center;
        padding-top    : 8px;
        padding-left   : 8px;

        svg {
            cursor: pointer;
        }
    }

    &.error {
        border-color: $danger;
    }

}

.qualifying-questions-container {
    width         : 100%;
    display       : flex;
    flex-direction: column;
    align-items   : flex-start;
    margin-top    : 24px;

    .add-qualifying-questions-container {
        align-self: center;
        width     : 100%;

        .section-container {
            max-width : none !important;
            background: #FFFFFF;

            /* Primary/Light */
            border       : 1px solid #DEDAF2;
            box-shadow   : 4px 4px 40px rgba(173, 161, 186, 0.15);
            border-radius: 4px;
        }

        .add-qualifying-questions {

            width         : 100%;
            padding       : 24px 0px 27px 0px;
            border        : 1.8px dashed #96999C;
            border-radius : 12px;
            background    : #FFFFFF;
            display       : flex;
            flex-direction: column;
            align-items   : center;
            margin-bottom : 16px;

            .body-3 {
                color      : #96999C;
                font-weight: 600;
            }

            .add-buttons-container {
                margin-top: 16px;
                display   : flex;


                button {
                    // border: 1px solid #6F2DBD;
                    max-width   : 150px;
                    margin-right: 8px !important;

                    span {}

                    svg {
                        width : 18px;
                        stroke: #56585A;
                    }
                }



                button:hover {
                    border: 1px solid #6F2DBD;

                    span {
                        color      : #6F2DBD !important;
                        font-weight: 600 !important;
                    }

                    svg {
                        stroke: #6F2DBD;

                        path {
                            stroke: #6F2DBD;
                        }
                    }
                }

            }
        }


        .question-box-mobile-res {
            background     : #FFFFFF;
            /* Black/400 */
            border         : 0.6px solid #96999C;
            border-radius  : 4px;
            padding        : 16px;
            display        : flex;
            width          : 100%;
            justify-content: space-between;

            span {
                color: #3E454D;
            }

            &.error {
                border-color: $danger;
            }
        }

        .next-to-assessment-btn-container {
            display        : flex;
            align-items    : center;
            justify-content: center;
            margin-top     : 106.67px;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
    .question-box {
        .question-box-question-section {
            .question-answer-selection-container {
                // flex-direction: column;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .question-box {
        .question-box-question-section {
            .question-answer-selection-container {
                flex-direction: column;
            }
        }
    }

    .qualifying-questions-container {
        padding: 16px !important;

        .add-qualifying-questions-container {
            padding-bottom: 16px;

            .add-buttons-container {
                display       : flex;
                flex-direction: column;
                align-items   : center;

                .in-section-btn {
                    margin-right: 0px !important;
                    margin-top  : 8px !important;
                }
            }


            .next-to-assessment-btn-container {
                display        : flex;
                justify-content: space-between;

                button {
                    width : 145px !important;
                    margin: 0px !important;
                }
            }
        }


    }
}