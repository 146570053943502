// @import url(http://fonts.googleapis.com/css?family=Roboto+Slab|Open+Sans:300italic,700italic,400,300);

@mixin typography($fontFamily, $fontWeight, $fontSize, $lineHight, $letterSpacing, $color) {
    font-family   : $fontFamily;
    font-style    : normal;
    font-weight   : $fontWeight;
    font-size     : $fontSize;
    line-height   : $lineHight;
    letter-spacing: $letterSpacing;

    @if $color==null {
        color: $black-700;
    }

    @else {
        color: $color;
    }
}