@import "../generalClasses";



.registration-background-container {

    background-color   : $dark-100;
    width              : 100vw;
    height             : 100vh;
    display            : flex;
    align-items        : center;
    justify-content    : space-evenly;
    // overflow        : hidden;
    align-items        : center;
    padding            : 24px;

    .onBoarding-container-box {
        // min-height         : calc(800px - 128px) !important;
        // background-color: red !important;
        display          : flex;
        flex-direction   : column;
        align-items      : center;
        // padding       : 24px;
        min-height       : calc(100vh - 48px);
        max-height       : max-content;
    }

    .top-content-container {



        .content-container {
            // height     : calc(100vh - 120px);
            // overflow-y : auto;
            display    : flex;
            align-items: center;
            width      : 100%;

            .content-box {
                width: 100%;

                .logo {
                    padding-top: 0px;
                }
            }


            .top-filled-box {
                background-color   : $secondary-brand;
                mix-blend-mode     : normal;
                opacity            : 0.04;
                border-radius      : 20px;
                position           : absolute;
                left               : 0;
                top                : 30px;
                // z-index         : -1;
                width              : 252px;
                height             : 252px;
            }

            .top-empty-box {
                mix-blend-mode   : normal;
                opacity          : 0.6;
                border           : 1px solid #DADAFF;
                border-radius    : 20px;
                position         : absolute;
                left             : 121px;
                top              : 0;
                // z-index       : -2;
                width            : 163px;
                height           : 163px;
            }

            .bottom-filled-box {
                background-color   : $secondary-brand;
                mix-blend-mode     : normal;
                opacity            : 0.04;
                border-radius      : 20px;
                position           : absolute;
                right              : 30px;
                bottom             : 30px;
                // z-index         : -1;
                width              : 252px;
                height             : 252px;
            }

            .bottom-dotted-box {
                mix-blend-mode   : normal;
                opacity          : 0.13;
                border           : 2px dashed #392D69;
                border-radius    : 20px;
                width            : 315px;
                height           : 315px;
                position         : absolute;
                // z-index       : -2;
                bottom           : 0;
                right            : 0;
            }

            .content-box {
                // width                    : 462px;
                // min-width          : min(500px, 100vw);
                height                   : fit-content;
                min-height               : 50%;
                border-radius            : 24px;
                padding                  : 30px 50px 0px 50px;
                // max-width       : 600px;
                // box-shadow            : 0 0 8px 8px rgba(204, 204, 204, 0.2);
                z-index                  : 2;

                img {
                    height          : 32px;
                    margin-bottom   : 24px;
                    // margin-top   : 24px;
                }
            }

            .content-full-box {
                width        : fit-content;
                min-width    : min(500px, 100vw);
                height       : fit-content;
                // margin-top: -80px;
                z-index      : 2;
            }
        }
    }

    .footer {
        width: 100vw;
        @extend .df-center;
        margin-top: 24px;

        .footer-content {
            width          : 500px;
            display        : flex;
            justify-content: space-between;
        }
    }
}

@media only screen and (max-width: 1440px) {
    .registration-background-container {
        .top-content-container {
            .content-container {
                .content-box {
                    // width                    : 462px;
                    // min-width          : min(500px, 100vw);
                    height                         : fit-content;
                    min-height                     : 50%;
                    border-radius                  : 24px;
                    padding                        : 0px;
                    // max-width       : 600px;
                    // box-shadow            : 0 0 8px 8px rgba(204, 204, 204, 0.2);
                    z-index                        : 2;
                    // background                  : transparent;
                }


            }
        }


    }
}

@media only screen and (min-width: 768px) {
    .registration-background-container {
        height       : 100% !important;
        // max-height: max-content !important;
        overflow-y   : auto;

        .onBoarding-container-box {
            // height             : calc(100vh - 48px) !important;
            // min-height         : calc(1000px - 128px) !important;
            // min-height         : 850px;
            // background-color: red !important;
            display       : flex;
            flex-direction: column;
            align-items   : center;
            padding       : 24px;
        }



    }
}



// @media only screen and (max-width: 1200px) {
//     .registration-background-container {
//         height    : 100vh !important;
//         max-height: max-content !important;



//     }
// }


@media only screen and (max-width: 767px) {
    .registration-background-container {
        align-items: center !important;
        padding    : 40px 0 24px 0;
        height     : 100vh !important;
        max-height : max-content !important;

        .top-content-container {
            align-items: flex-start !important;
            width      : 100%;

            .content-container {
                align-items: flex-start !important;
                min-width  : 100%;
                padding    : 0 12px;

                .content-box {
                    width    : fit-content;
                    min-width: 100%;
                    padding  : 25px 15px;
                }

                .content-full-box {
                    margin-top: 0px;
                }
            }
        }

        .footer {
            padding: 0 12px;

            .footer-content {}
        }
    }
}

@media only screen and (max-width: 1199px) {
    .onBoarding-container {
        padding         : 24px;
        background-color: #121216 !important;
        height          : 100% !important;



        .onBoarding-container-text {
            display: none !important;
        }

        .onBoarding-container-box {
            width       : 100% !important;
            // height   : calc(100vh - 48px) !important;
            // height: 780px !important;

            .top-content-container {
                .content-container {
                    .content-box {
                        padding: 0px !important;
                    }
                }
            }
        }


    }
}

@media only screen and (max-width: 767px) {
    .onBoarding-container {
        padding         : 24px;
        background-color: #121216 !important;
        height          : 100% !important;

        .onBoarding-container-text {
            display: none !important;
        }

        .onBoarding-container-box {
            width  : 100% !important;
            height : max-content !important;
            padding: 24px !important;

            .top-content-container {
                .content-container {
                    .content-box {
                        padding: 0px !important;
                    }
                }
            }
        }


    }
}

// @media only screen and (min-width: 768px) and (max-width: 1551px) {
//     .onBoarding-container {
//         // padding            : 24px;
//         // background-color: #121216 !important;
//         height: 100vh !important;

//         .top-content-container {
//             align-items: center !important;

//             .content-container {
//                 align-items: flex-start !important;
//                 min-width  : 100%;
//                 padding    : 0 12px;

//                 .content-full-box {
//                     margin-top: 0px;
//                 }
//             }
//         }
//     }
// }

@media only screen and (max-width: 767px) {
    .registration-background-container {

        .top-content-container {
            align-items: center !important;

            .content-container {
                align-items: flex-start !important;
                min-width  : 100%;
                padding    : 0 12px;

                .content-full-box {
                    margin-top: 0px;
                }
            }
        }
    }
}

@media only screen and (max-width: 330px) {
    .onBoarding-container {
        padding         : 12px;
        background-color: #121216 !important;
        height          : 100vh !important;

        .onBoarding-container-text {
            display: none !important;
        }

        .onBoarding-container-box {
            width     : 100% !important;
            // height : 780px !important;
            padding   : 4px !important;

            .top-content-container {
                .content-container {
                    .content-box {
                        padding: 0px !important;
                    }
                }
            }

            .body-2 {
                font-size: 12px !important;
            }
        }


    }
}