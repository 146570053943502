.testpack-preview-questions-container {
	display        : flex;
	flex-direction : column;
	align-items    : center;
	width          : 100%;
	min-height     : 100%;
	min-height     : 100vh;
	height         : min-content;
	justify-content: space-between;
	position       : relative;
	background     : #FAF9FB;
	padding        : 24px;

	.testpack-preview-questions-disclaimer {
		max-width    : 100%;
		display      : flex;
		align-items  : flex-start;
		padding      : 12px 20px;
		gap          : 10px;
		border-radius: 24px;
		background   : $dark-20
	}

	.test-progress-info-header {
		width             : 100%;
		padding           : 24px;
		background        : #FFFFFF;
		border-radius     : 24px;
		display           : flex;
		flex-direction    : row;
		align-items       : center;
		justify-content   : space-between;
		height            : 92px;
		// padding        : 14px 24px;
	}

	.test-content-wrapper {
		width          : 100%;
		height         : min-content;
		display        : flex;
		flex-direction : column;
		align-items    : center;
		justify-content: flex-start;
		padding        : 24px;
		background     : #FFFFFF;
		border-radius  : 24px;
		margin-top     : 24px;

		.test-content-wrapper-content {
			height         : min-content;
			min-height     : 442px;
			width          : 100%;
			display        : flex;
			flex-direction : column;
			justify-content: space-between;

			.test-content-wrapper-content-container {
				height         : min-content;
				min-height     : 442px;
				display        : flex;
				justify-content: center;
				align-items    : flex-start;
			}
		}
	}

	.testpack-test-container {
		width         : 100%;
		display       : flex;
		flex-direction: row;
		height        : 100%;
		gap           : 32px;

		.testpack-test-questions-container {
			width          : 50%;
			height         : 100%;
			display        : flex;
			justify-content: flex-start;
			align-items    : flex-start;
			padding        : 0px 12px 0px 0px;

			span {
				width : 100%;
				cursor: default;
			}

			p {
				width : 100%;
				cursor: default;
			}

			img {
				width : 100%;
				cursor: default;
			}

			.ql-container {
				display       : flex;
				flex-direction: column;
				font-size     : 18px !important;
				color         : #808191;

				.ql-editor {
					padding: 0px !important;

					img {
						max-width : 100%;
						margin-top: 12px;
					}
				}
			}
		}

		.testpack-test-answers-container {
			width          : 50%;
			height         : 100%;
			display        : flex;
			flex-direction : column;
			justify-content: flex-start;
			align-items    : flex-start;

			.ql-container {
				display       : flex;
				flex-direction: column;
				font-size     : 16px !important;
				color         : #2B3340;
			}
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
	.testpack-preview-questions-container {
		.testpack-preview-questions-disclaimer {
			max-width: 550px;
		}

		.test-progress-info-header {
			max-width: 650px;
		}

		.test-content-wrapper {
			max-width: 720px;
		}

		.testpack-test-container {
			flex-direction: column;

			.testpack-test-questions-container {
				width: 100%;
			}

			.testpack-test-answers-container {
				width: 100%;
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.testpack-preview-questions-container {
		.testpack-preview-questions-disclaimer {
			// max-width: 300px;
		}

		.test-progress-info-header {
			// max-width     : 327px;
			flex-direction: column;
		}

		.test-content-wrapper {
			// max-width: 327px;
		}

		.testpack-test-container {
			flex-direction: column;
			gap           : 24px;

			.testpack-test-questions-container {
				width: 100%;

				.ql-container {
					font-size: 16px !important;
				}
			}

			.testpack-test-answers-container {
				width: 100%;
			}
		}
	}
}

.ͼ1x .cm-gutters {
	background-color: #121216 !important;
}

.ͼ1 .cm-content {
	background-color: #121216 !important;

}