.test-content-wrapper {
    width          : 100%;
    height         : min-content;
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: flex-start;
    padding        : 24px;
    background     : #FFFFFF;
    border-radius  : 24px;
    margin-top     : 24px;

    .test-content-wrapper-header {
        display       : flex;
        flex-direction: column;
        align-items   : center;
        width         : 100%;
    }

    .test-content-wrapper-content {
        height               : min-content;
        min-height           : 442px;
        width                : 100%;
        // padding-top       : 24px;
        display              : flex;
        flex-direction       : column;
        justify-content      : space-between;
        // overflow       : scroll;

        .test-content-wrapper-content-container {
            // height: calc(100% - 64px);
            height         : min-content;
            min-height     : 442px;
            display        : flex;
            justify-content: center;
            align-items    : flex-start;
        }
    }
}

// @media only screen and (max-height: 800px) {
//     .test-content-wrapper {
//         align-self: flex-start;
//         min-height: calc(100vh - 218px);
//     }
// }