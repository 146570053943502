.timeout-message {
	width        : 100%;
	display      : flex;
	align-items  : center;
	margin-top   : 24px;
	padding      : 24px;
	border-radius: 24px;
	background   : #FFF;
	gap          : 12px;
}

.assessment-test-container {
	width          : 100%;
	height         : 100%;
	display        : flex;
	flex-direction : row;
	align-items    : flex-start;
	justify-content: space-between;
	gap            : 40px;

	.assessment-test-questions-container {
		width          : 50%;
		height         : 100%;
		display        : flex;
		justify-content: flex-start;
		align-items    : flex-start;

		span {
			width : 100%;
			cursor: default;
		}

		p {
			width : 100%;
			cursor: default;
		}

		img {
			width : 100%;
			cursor: default;
		}
	}

	.assessment-test-answers-container {
		width          : 50%;
		height         : 100%;
		display        : flex;
		flex-direction : column;
		justify-content: flex-start;
		align-items    : flex-start;
	}

	.video-limit {
		width        : 100%;
		display      : flex;
		align-items  : center;
		padding      : 12px 18px;
		border-radius: 24px;
		background   : #F6F5F8;
		gap          : 12px;
	}
}

.assessment-test-coding-container {
	width          : 100%;
	height         : 100%;
	display        : flex;
	flex-direction : row;
	align-items    : flex-start;
	justify-content: space-between;
	gap            : 40px;

	.assessment-test-coding-questions-container {
		width          : calc(38.15% - 12px);
		height         : 100%;
		display        : flex;
		justify-content: flex-start;
		align-items    : flex-start;
		margin-top     : 52px;

		span {
			width : 100%;
			cursor: default;
		}

		p {
			width : 100%;
			cursor: default;
		}

		img {
			width : 100%;
			cursor: default;
		}
	}

	.assessment-test-coding-answers-container {
		width          : calc(61.84% - 12px);
		height         : 100%;
		display        : flex;
		flex-direction : column;
		justify-content: flex-start;
		align-items    : flex-start;
	}
}

.run-code-button {
	width          : 100%;
	height         : auto;
	display        : flex;
	justify-content: flex-start;
	align-items    : center;
	padding        : 11px 24px;
	background     : #ECEDF1;
}

.CT-result-screen {
	height         : 200px;
	overflow-y     : auto;
	width          : 100%;
	background     : #F6F5F8;
	border-radius  : 0px 0px 8px 8px;
	display        : flex;
	justify-content: flex-start;
	padding        : 6px 16px;

	span {
		color: #121216;
	}

	label {
		margin-top   : 0;
		margin-bottom: 1rem;
		font-size    : .875em;
	}
}

.text-upload-doc-container {
	width          : 100%;
	height         : 250px;
	display        : flex;
	flex-direction : column;
	align-items    : center;
	justify-content: center;
	border         : 2px dashed #ECEDF1;
	border-radius  : 24px;
}

.uploaded-doc-container {
	width         : 100%;
	height        : auto;
	display       : flex;
	flex-direction: row;
	align-items   : flex-start;
	background    : #F6F5F8;
	padding       : 12px 24px;
	border-radius : 24px;
	gap           : 8px;
}

.video-testing-container {
	width         : 100% !important;
	display       : flex;
	flex-direction: column;
	align-items   : flex-start;
	padding       : 24px;
	background    : #FFF;
	border-radius : 24px;
	gap           : 24px;

	.CS-VR-vide-text-info-container {
		width          : 100%;
		display        : flex;
		flex-direction : row;
		align-items    : flex-start;
		justify-content: space-between;

		.CS-VR-left-container {
			width         : calc(50% - 12px);
			display       : flex;
			flex-direction: column;
			gap           : 24px;
		}
	}

	.error-message {
		width        : 100%;
		display      : flex;
		align-items  : center;
		padding      : 12px 18px;
		border-radius: 24px;
		background   : #F6F5F8;
		gap          : 12px;
	}
}

.video-recorder-container {
	width          : 100%;
	height         : 100%;
	display        : flex;
	flex-direction : column;
	justify-content: flex-start;

	.video-recorder {
		width           : 100%;
		height          : calc(100% - 52px);
		// border-radius: 24px 24px 0px 0px !important;
		object-fit      : cover;
	}

	video {
		// width        : 100%;
		// height       : calc(100% - 52px);
		// border-radius: 24px 24px 0px 0px !important;
		// object-fit   : cover;
	}

	.camera-container-footer-testing {
		width          : 100%;
		min-height     : 52px;
		height         : 52px;
		border-radius  : 0px 0px 8px 8px;
		display        : flex;
		justify-content: center;
		align-items    : center;
	}

	.camera-container-footer {
		width          : 100%;
		height         : 52px;
		border-radius  : 0px 0px 24px 24px;
		display        : flex;
		justify-content: space-between;
		align-items    : center;
		background     : #F6F5F8;
		padding        : 0px 24px;
	}
}

.loom-recorder-container {
	height          : 403.5px;
	width           : 100%;
	background-color: #2B3340;
	display         : flex;
	align-items     : center;
	justify-content : center;

	button {
		border-radius   : 4px;
		height          : 52px;
		padding         : 14px 32px;
		background-color: #FC4848;

		outline: none;

		span {
			color      : white !important;
			font-size  : 20px !important;
			font-style : normal !important;
			font-weight: 500 !important;
			line-height: 34px !important;
		}
	}

	.loom-iframe-container {
		width : 100%;
		height: 100%;

		iframe {
			width : 100%;
			height: 100%;
		}
	}
}

.webcam-instructions {
	width          : 100%;
	height         : auto;
	display        : flex;
	flex-direction : column;
	align-items    : flex-start;
	justify-content: flex-start;
	padding        : 24px;
	background     : #F6F5F8;
	border-radius  : 24px;
	gap            : 12px;
}

.assessment-test-questions-container {
	// display: none !important;

	.ql-container {
		display       : flex;
		flex-direction: column;
		font-size     : 16px !important;

		.ql-editor {
			padding: 0px !important;

			img {
				max-width: 100%;
			}
		}
	}
}

.grape-placeholder::placeholder {
	color      : #6f2dbd;
	font-weight: 500;
	font-size  : 14px;
	line-height: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 1339px) {
	.assessment-test-container {
		gap: 24px;
	}

	.assessment-test-coding-container {
		flex-direction: column;
		gap           : 24px;

		.assessment-test-coding-questions-container {
			width     : 100%;
			margin-top: 0px;
		}

		.assessment-test-coding-answers-container {
			width: 100%;
		}
	}

	.video-testing-container {
		.CS-VR-vide-text-info-container {
			flex-direction: column;
			gap           : 24px;

			.CS-VR-left-container {
				width: 100%;
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.assessment-test-container {
		flex-direction: column;
		gap           : 24px;

		.assessment-test-questions-container {
			width: 100%;
		}

		.assessment-test-answers-container {
			width: 100%;
		}
	}

	.assessment-test-coding-container {
		flex-direction: column;
		gap           : 24px;

		.assessment-test-coding-questions-container {
			width     : 100%;
			margin-top: 0px;
		}

		.assessment-test-coding-answers-container {
			width: 100%;
		}
	}

	.video-testing-container {
		.CS-VR-vide-text-info-container {
			flex-direction: column;
			gap           : 24px;

			.CS-VR-left-container {
				width: 100%;
			}
		}
	}
}