.create-assessment-view-container {
    display       : flex;
    flex-direction: column;
    width         : 100%;
    align-items   : flex-start;

    .add-sections-container {
        display: flex;
        width  : 100%;

    }
}

.section-type-container {
    display       : flex;
    flex-direction: column;
    align-items   : flex-start;
    width         : 278px;

}

.add-section-container {
    width            : calc(100% - 3em);
    display          : flex;
    flex-direction   : column;
    align-items      : center;
    margin           : 1.5em 1.5em 2.5em;
    gap              : 1em;
    // max-width     : 62.25em;

    .default-section-div {
        width          : 100%;
        height         : 4em;
        background     : #FFFFFF;
        border         : 0.6px dashed #96999C;
        border-radius  : 24px;
        display        : flex;
        align-items    : center;
        justify-content: center;

        &.primary {
            border: 1.6px dashed $dark-50;
        }

    }
}

@media screen and (max-width: 767px) {
    .section-type-container {
        width: 208px;
    }

    .add-section-container {
        width : calc(100% - 32px);
        margin: 24px 16px 40px;
    }
}