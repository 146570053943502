.add-card {
    gap: 8px;
}
.view-card-modal {
    width: 100%;
    gap: 20px;
    .modal-header {
        width: 100%;
        align-items: center;
        gap: 4px;
        .headline-medium {
            color: #5E5873;
        }
        .body-3 {
            color: #6E6B7B;
        }
    }
}

@media screen and (max-width: 767px) {
    .view-card-modal {
        width: 100%;
        .modal-header{
            width: 100%;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 10px;
        }
    }
}