.company-profile-container {
    width         : 100%;
    display       : flex;
    flex-direction: column;
    gap           : 24px;

    .company-profile-details {
        width              : 100%;
        display            : flex;
        flex-direction     : column;
        align-items        : flex-start;
        background-color   : white;
        border-radius      : 24px;
        padding            : 24px;
        gap                : 24px;
        // height          : calc(100vh - 375px) !important;

        .company-profile-other-details {
            width         : 100%;
            display       : flex;
            flex-direction: row;
            flex-wrap     : wrap;
            gap           : 16px;

            .company-profile-edit-detail {
                width      : calc(50% - 8px);
                height     : auto;
                align-items: flex-start;
                color      : #121216;
            }
        }
    }

    .company-profile-pic-detail {
        width         : 100%;
        height        : auto;
        display       : flex;
        flex-direction: column;
        align-items   : flex-start;
        background    : white;
        padding       : 24px;
        border-radius : 24px;
        gap           : 24px;

        .body-3 {
            color: #121216
        }

        .company-profile-pic {
            width         : 100%;
            height        : auto;
            display       : flex;
            flex-direction: row;
            align-items   : center;
            gap           : 24px;

            .profile-img {
                display        : flex;
                justify-content: center;
                align-items    : center;
                background     : white;
                border         : 1px solid $dark-50;
                border-radius  : 50%;
                height         : 120px;
                width          : 120px;
            }

            .profile-img-upload-icon {
                display: none;

            }

            .profile-img-upload-icon2 {
                display: block;
            }

            .profile-img :hover~.profile-img-upload-icon {
                display: block;

            }

            .profile-img:hover {
                width           : 100%;
                height          : 100%;
                background-color: $dark-20;
                opacity         : 0.5;
                border-radius   : 50%;
            }

            .profile-img :hover~.profile-img-upload-icon2 {
                display: none;
            }
        }
    }
}

// @media only screen and (max-width: 1441px) {
//     .company-profile-container {
//         .company-profile-details {
//             height: calc(100vh - 340px) !important;


//         }
//     }
// }


@media only screen and (max-width: 767px) {
    .company-profile-container {
        .company-profile-details {
            .company-profile-other-details {
                .company-profile-edit-detail {
                    width: 100%;
                }
            }
        }

        .company-profile-pic-detail {
            .company-profile-pic {
                width          : 100%;
                flex-direction : column;
                justify-content: center;
            }
        }
    }
}