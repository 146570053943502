.candidate-assessment-listing-container{
	width: auto;
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 8px 0px;
	margin-top: 48px;
	gap: 20px;
	.candidate-assessment-type {
		height: 32px;
		display: flex;
		cursor: pointer;
		border-bottom: 4px solid #fff;
	}
}