.welcome-video-section-container {
    padding      : 24px 32px 24px 32px;
    background   : #FFFFFF;
    /* Primary/Light */
    border       : 1px solid #DEDAF2;
    border-radius: 6px;

    display       : flex;
    flex-direction: column;
    align-items   : flex-start;


}

.WV-reupload-video {
    height     : 16px;
    width      : 17px;
    position   : relative;
    display    : flex;
    align-items: flex-end;

    input {
        opacity : 0;
        height  : 100%;
        width   : 100%;
        position: absolute;
        z-index : 1;
        left    : 0;
        top     : 0;
        cursor  : pointer;
    }
}

.WV-upload-video {
    width             : 100%;
    display           : flex;
    align-items       : center;
    justify-content   : center;
    background        : #FFFFFF;
    border            : 1px dashed #808191;
    border-radius     : 24px;
    padding           : 12px 18px 12px 18px;
    // margin-top     : 12px;
    position          : relative;
    overflow          : hidden;
    height            : 40px;
    color             : gold !important;

    input {
        position: absolute;
        width   : 100%;
        height  : 100%;
        opacity : 0;
        cursor  : pointer;
    }
}

.video-preview-outer-container {
    display        : flex;
    width          : 100%;
    flex-direction : row;
    justify-content: space-between;
    align-items    : flex-start;
    padding        : 16px;
    gap            : 16px;
    background     : #FAF9FB;
    border-radius  : 4px;
    margin-top     : 12px;

    .video-preview-container {
        width           : 389px;
        height          : 206px;
        border-radius   : 12px;
        background-color: white;
    }
}