.complete-card-container {
    @media screen and (max-width: 1440px) {
        // font-size: 13px;
    }
}

.on-test-complete-card-container {
    max-width      : 648px;
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: flex-start;
    padding        : 24px;
    background     : #FFFFFF;
    border-radius  : 24px;
    gap            : 24px;

    .on-test-complete-card-stats-container {
        width         : 100%;
        display       : flex;
        flex-direction: column;
        align-items   : center;
        padding       : 24px;
        background    : #FFFFFF;
        border        : 1px solid #ECEDF1;
        border-radius : 24px;
        gap           : 12px;
    }
}