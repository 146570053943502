.icon-wrapper {
	display      : block;
	border-radius: 5px;
	width        : auto;

	* {
		margin: 5px;
	}

	&:hover {
		box-shadow      : 0 0 3px 1px #e8e8e8;
		background-color: #fff;
	}

	&.danger-wrapper {
		&:hover {
			box-shadow      : 0 0 3px 1px #e8e8e8;
			background-color: $danger;

			* {
				background-color: #fff;
			}
		}
	}

	&.view-wrapper {
		&:hover {
			box-shadow      : 0 0 3px 1px #e8e8e8;
			background-color: $primary-brand;

			* {
				background-color: #fff;
			}
		}
	}

	&.active-bg-primary {
		&:active {
			background-color: $primary-brand;

			* {
				background-color: #fff !important;
			}
		}
	}

	&.active-bg-success {
		&:active {
			background-color: $success;

			* {
				background-color: #fff !important;
			}
		}
	}
}

.circle-check-icon {
	width            : 22px;
	height           : 22px;
	display          : inline-block;
	-webkit-mask     : url("./../icons/basic/check-circle.svg") no-repeat 50% 50%;
	mask             : url("./../icons/basic/check-circle.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;

	&.icon-success {
		background-color: $success;
	}

}

.circle-check-icon.circle-check-icon-white {
	background-color: $grey;
}

.circle-check-icon.circle-check-icon-grey {
	background-color: $grey;
}

.user-icon-1 {
	width            : 24px;
	height           : 24px;
	display          : inline-block;
	-webkit-mask     : url("./../icons/basic/users.svg") no-repeat 50% 50%;
	mask             : url("./../icons/basic/users.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;
}

.user-icon-1.user-icon-1-white {
	background-color: $white;
}

.user-icon-1.user-icon-1-grey {
	background-color: $grey;
}

.settings-icon-1 {
	width            : 24px;
	height           : 24px;
	display          : inline-block;
	-webkit-mask     : url("./../icons/basic/settings.svg") no-repeat 50% 50%;
	mask             : url("./../icons/basic/settings.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;
}

.api-access-token-icon {
	width            : 24px;
	height           : 24px;
	display          : inline-block;
	-webkit-mask     : url("./../icons/basic/api-access-token.svg") no-repeat 50% 50%;
	mask             : url("./../icons/basic/api-access-token.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;
}

.notification-icon {
	width            : 24px;
	height           : 24px;
	display          : inline-block;
	-webkit-mask     : url("./../icons/basic/notifi-bell.svg") no-repeat 50% 50%;
	mask             : url("./../icons/basic/notifi-bell.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;
}

.settings-icon-1.settings-icon-1-white {
	background-color: $white;
}

.settings-icon-1.settings-icon-1-grey {
	background-color: $grey;
}

.integrations-icon-1 {
	width            : 24px;
	height           : 24px;
	display          : inline-block;
	-webkit-mask     : url("./../icons/basic/cpu.svg") no-repeat 50% 50%;
	mask             : url("./../icons/basic/cpu.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;
}

.integrations-icon-1.integrations-icon-1-white {
	background-color: $white;
}

.integrations-icon-1.integrations-icon-1-grey {
	background-color: $grey;
}

.plus-icon {
	width            : 16px;
	height           : 16px;
	background-color : $grey;
	display          : inline-block;
	-webkit-mask     : url("./../icons/basic/plus-icon.svg") no-repeat 50% 50%;
	mask             : url("./../icons/basic/plus-icon.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;
}

.minus-icon {
	width            : 16px;
	height           : 16px;
	background-color : $grey;
	display          : inline-block;
	-webkit-mask     : url("./../icons/basic/minus-icon.svg") no-repeat 50% 50%;
	mask             : url("./../icons/basic/minus-icon.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;
}

.active-rectangle-icon {
	width            : 20px;
	height           : 20px;
	background-color : white;
	margin-left      : 5px;
	margin-right     : 5px;
	display          : inline-block;
	-webkit-mask     : url("./../icons/components/sidebar/inbox.svg") no-repeat 50% 50%;
	mask             : url("./../icons/components/sidebar/inbox.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;
}

.draft-rectangle-icon {
	width            : 20px;
	height           : 20px;
	background-color : white;
	margin-left      : 5px;
	margin-right     : 5px;
	display          : inline-block;
	-webkit-mask     : url("./../icons/components/sidebar/file.svg") no-repeat 50% 50%;
	mask             : url("./../icons/components/sidebar/file.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;
}

.archived-rectangle-icon {
	width            : 20px;
	height           : 20px;
	background-color : white;
	margin-left      : 5px;
	margin-right     : 5px;
	display          : inline-block;
	-webkit-mask     : url("./../icons/components/sidebar/archive.svg") no-repeat 50% 50%;
	mask             : url("./../icons/components/sidebar/archive.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;
}

.settings-rectangle-icon {
	width            : 20px;
	height           : 20px;
	background-color : white;
	margin-left      : 5px;
	margin-right     : 5px;
	display          : inline-block;
	-webkit-mask     : url("./../icons/components/sidebar/user.svg") no-repeat 50% 50%;
	mask             : url("./../icons/components/sidebar/user.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;
}

.company-rectangle-icon {
	width            : 20px;
	height           : 20px;
	background-color : white;
	margin-left      : 5px;
	margin-right     : 5px;
	display          : inline-block;
	-webkit-mask     : url("./../icons/components/sidebar/briefcase.svg") no-repeat 50% 50%;
	mask             : url("./../icons/components/sidebar/briefcase.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;
}

.team-rectangle-icon {
	width            : 20px;
	height           : 20px;
	background-color : white;
	margin-left      : 5px;
	margin-right     : 5px;
	display          : inline-block;
	-webkit-mask     : url("./../icons/components/sidebar/users.svg") no-repeat 50% 50%;
	mask             : url("./../icons/components/sidebar/users.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;
}

.billing-rectangle-icon {
	width            : 20px;
	height           : 20px;
	background-color : white;
	margin-left      : 5px;
	margin-right     : 5px;
	display          : inline-block;
	-webkit-mask     : url("./../icons/components/sidebar/credit-card.svg") no-repeat 50% 50%;
	mask             : url("./../icons/components/sidebar/credit-card.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;
}

.upload-cloud-icon {
	width            : 24px;
	height           : 24px;
	display          : inline-block;
	-webkit-mask     : url("./../icons/basic/upload-cloud.svg") no-repeat 50% 50%;
	mask             : url("./../icons/basic/upload-cloud.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;

	&.icon-white {
		background-color: #fff;
	}

	&.icon-primary {
		background-color: $primary-brand;
	}

	&.icon-grey {
		background-color: $grey;
	}
}

.archive-icon {
	width            : 24px;
	height           : 24px;
	display          : inline-block;
	-webkit-mask     : url("./../icons/components/common/table/archive.svg") no-repeat 50% 50%;
	mask             : url("./../icons/components/common/table/archive.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;

	&.icon-white {
		background-color: #fff;
	}

	&.icon-primary {
		background-color: $primary-brand;
	}

	&.icon-grey {
		background-color: $grey;
	}

	&.icon-black-500 {
		background-color: $black-500;
	}
}

.link-icon {
	width            : 24px;
	height           : 24px;
	display          : inline-block;
	-webkit-mask     : url("./../icons/components/common/table/link.svg") no-repeat 50% 50%;
	mask             : url("./../icons/components/common/table/link.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;

	&.icon-white {
		background-color: #fff;
	}

	&.icon-primary {
		background-color: $primary-brand;
	}

	&.icon-grey {
		background-color: $grey;
	}

	&.icon-black-500 {
		background-color: $black-500;
	}
}

.link-icon-2 {
	width            : 24px;
	height           : 24px;
	display          : inline-block;
	-webkit-mask     : url("./../icons/basic/link-2.svg") no-repeat 50% 50%;
	mask             : url("./../icons/basic/link-2.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;

	&.icon-white {
		background-color: #fff;
	}

	&.icon-primary {
		background-color: $primary-brand;
	}

	&.icon-grey {
		background-color: $grey;
	}

	&.icon-black-500 {
		background-color: $black-500;
	}
}

.user-plus-icon {
	width            : 24px;
	height           : 24px;
	display          : inline-block;
	-webkit-mask     : url("./../icons/components/common/table/user-plus.svg") no-repeat 50% 50%;
	mask             : url("./../icons/components/common/table/user-plus.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;

	&.icon-white {
		background-color: #fff;
	}

	&.icon-primary {
		background-color: $primary-brand;
	}

	&.icon-grey {
		background-color: $grey;
	}

	&.icon-black-500 {
		background-color: $black-500;
	}
}

.x-cross-icon {
	width              : 24px;
	height             : 24px;
	display            : inline-block;
	-webkit-mask       : url("./../icons/basic/x.svg") no-repeat 50% 50%;
	mask               : url("./../icons/basic/x.svg") no-repeat 50% 50%;
	-webkit-mask-size  : cover;
	mask-size          : cover;
	// background-color: $danger;

	&.icon-white {
		background-color: #fff;
	}

	&.icon-primary {
		background-color: $primary-brand;
	}

	&.icon-grey {
		background-color: $grey;
	}

	&.icon-black-500 {
		background-color: $black-500;
	}

	&.icon-danger {
		background-color: $danger;
	}
}

.circle-cross-icon {
	width              : 24px;
	height             : 24px;
	display            : inline-block;
	-webkit-mask       : url("./../icons/candidate-assessment/x-circle.svg") no-repeat 50% 50%;
	mask               : url("./../icons/candidate-assessment/x-circle.svg") no-repeat 50% 50%;
	-webkit-mask-size  : cover;
	mask-size          : cover;
	// background-color: $danger;

	&.icon-white {
		background-color: #fff;
	}

	&.icon-primary {
		background-color: $primary-brand;
	}

	&.icon-grey {
		background-color: $grey;
	}

	&.icon-black-500 {
		background-color: $black-500;
	}

	&.icon-danger {
		background-color: $danger;
	}
}

.back-arrow-icon {
	width            : 24px;
	height           : 24px;
	display          : inline-block;
	-webkit-mask     : url("./../icons/basic/chevron-left.svg") no-repeat 50% 50%;
	mask             : url("./../icons/basic/chevron-left.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;

	&.icon-white {
		background-color: #fff;
	}

	&.icon-primary {
		background-color: $primary-brand;
	}

	&.icon-grey {
		background-color: $grey;
	}

	&.icon-black-500 {
		background-color: $black-500;
	}
}

.hourglass-icon {
	width            : 24px;
	height           : 24px;
	display          : inline-block;
	-webkit-mask     : url("./../icons/basic/hourglass.svg") no-repeat 50% 50%;
	mask             : url("./../icons/basic/hourglass.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;

	&.icon-white {
		background-color: #fff;
	}

	&.icon-primary {
		background-color: $primary-brand;
	}

	&.icon-grey {
		background-color: $grey;
	}

	&.icon-black-500 {
		background-color: $black-500;
	}
}

.trash-icon {
	width            : 24px;
	height           : 24px;
	display          : inline-block;
	-webkit-mask     : url("./../icons/basic/trash.svg") no-repeat 50% 50%;
	mask             : url("./../icons/basic/trash.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;

	&.icon-white {
		background-color: #fff;
	}

	&.icon-primary {
		background-color: $primary-brand;
	}

	&.icon-grey {
		background-color: $grey;
	}

	&.icon-black-500 {
		background-color: $black-500;
	}
}

.view-icon {
	width            : 24px;
	height           : 24px;
	display          : inline-block;
	-webkit-mask     : url("./../icons/components/common/table/eye.svg") no-repeat 50% 50%;
	mask             : url("./../icons/components/common/table/eye.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;

	&.icon-white {
		background-color: #fff;
	}

	&.icon-primary {
		background-color: $primary-brand;
	}

	&.icon-grey {
		background-color: $grey;
	}

	&.icon-black-500 {
		background-color: $black-500;
	}
}

.edit-icon {
	width            : 24px;
	height           : 24px;
	display          : inline-block;
	-webkit-mask     : url("./../icons/basic/edit-3.svg") no-repeat 50% 50%;
	mask             : url("./../icons/basic/edit-3.svg") no-repeat 50% 50%;
	-webkit-mask-size: cover;
	mask-size        : cover;

	&.icon-white {
		background-color: #fff;
	}

	&.icon-primary {
		background-color: $primary-brand;
	}

	&.icon-grey {
		background-color: $grey;
	}

	&.icon-black-500 {
		background-color: $black-500;
	}
}

// .icon-red:hover {
// 	background-color: blue;
// }

// .icon-orange {
// 	background-color: orange;
// }

// .icon-orange:hover {
// 	background-color: purple;
// }

// .icon-yellow {
// 	background-color: yellow;
// }

// .icon-green {
// 	background-color: green;
// }

// .icon-blue {
// 	background-color: blue;
// }

// .icon-indigo {
// 	background-color: indigo;
// }

// .icon-violet {
// 	background-color: violet;
// }