.pointer {
	cursor: pointer;
}

.custom-switch {
	padding-left: 0px !important;
}

.custom-control {
	padding-left: 0px !important;
}

.test-btn-display {
	display: flex;
}

.test-btn-padding {
	padding-bottom: 0px;
}

.select-drop-down {
	border          : none;
	border-bottom   : 1px solid $black-400;
	padding         : 8px;
	font-size       : 14px;
	background-color: transparent;
	color           : $black-600;
	outline         : none;

}


.select-drop-down:focus {
	border-bottom: 1.5px solid #555;
}

@media only screen and (max-width: 700px) {
	.test-btn-display {
		display       : flex;
		flex-direction: column;
	}

	.test-btn-padding {
		padding-bottom: 10px;
	}
}

@media only screen and (min-width: 1921px) {
	// .maxScreenSize {
	// //   width: 1920px;
	// }
}

.react-multi-email {
	background      : transparent;
	border          : 0.6px solid #C8CBCD !important;
	border-radius   : 24px !important;
	box-sizing      : border-box;
	display         : flex;
	align-items     : center;
	// border-radius: 5px;
	padding         : 8px;

	input {
		margin-left: 16px;
	}

	[data-placeholder] {
		display    : none;
		margin-left: 12px;
	}

	[data-tag] {
		font-weight     : normal !important;
		background-color: rgba(222, 218, 242, 0.3) !important;
	}
}

.email-list .react-multi-email>input {

	width: 100% !important;
}

.ͼ1 .cm-content {
	text-align: start !important;
}

.css-1ytufz-MuiAvatarGroup-root .MuiAvatar-root {
	// padding-top: 2px !important;
}

.hide-scrollbar::-webkit-scrollbar {
	display: none;
}

.hide-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width   : none;
	scroll-behavior   : smooth;
}


// @media only screen and (max-width: 445px) {

// #loom-sdk-record-overlay-shadow-root-id {
// 	.loom-sdk-record-overlay-shadow-inner-container {
// 		background-color: red !important;
// 	}

// 	.em-lns-ikmc81 {
// 		width: calc(100vw - 40px) !important;
// 	}
// }



// }

// recorder-overlay-id