.rich-text-error {
	.ql-container {
		.ql-editor {
			border: 0.6px solid #FC4848 !important;
		}
	}
}

.case-study-quill-container {
	.ql-container {
		min-height    : 50px;
		max-height    : 125px;
		display       : flex;
		flex-direction: column;
		border        : none !important;

		.ql-editor::before {
			padding-left: 8px;
		}

		.ql-editor {
			background   : #F6F5F8 !important;
			border-radius: 24px;
			padding      : 8px 24px;
			gap          : 8px;
			margin-top   : 12px;
		}
	}

	.ql-toolbar {
		box-shadow   : 0px 4px 3px rgba(224, 224, 224, 0.25) !important;
		border       : none !important;
		min-height   : 40px;
		background   : #F6F5F8 !important;
		border-radius: 24px;
		padding      : 8px 24px;

		.ql-formats {

			button,
			span {
				color: #718096 !important;


				.ql-stroke {
					stroke: #718096 !important;
				}

				.ql-fill {
					fill: #718096 !important;
				}

				&:hover {
					color: #06c !important;

					.ql-stroke {
						stroke: #06c !important;
					}

					.ql-fill {
						fill: #06c !important;
					}
				}
			}
		}
	}
}

.jd-quill-container {
	margin-top      : 16px;
	// min-height   : 5rem !important;
	// max-height   : 50rem !important;
	border          : 0.6px solid #C8CBCD;
	border-radius   : 4px;

	.ql-container {
		height        : 300px;
		display       : flex;
		flex-direction: column;
		border        : none !important;

		.ql-editor {
			background: white !important;
		}
	}

	.ql-toolbar {
		box-shadow            : 0px 4px 3px rgba(224, 224, 224, 0.25) !important;
		border                : none !important;
		min-height            : 40px;
		// max-height         : 40px;
		// display   : flex;
		background            : #f9fbfd !important;
		// border-bottom   : 1px solid #C8CBCD !important;
		// border-radius: 4px 4px 0px 0px;


		.ql-formats {

			button,
			span {
				color: #718096 !important;


				.ql-stroke {
					stroke: #718096 !important;
				}

				.ql-fill {
					fill: #718096 !important;
				}

				&:hover {
					color: #06c !important;

					.ql-stroke {
						stroke: #06c !important;
					}

					.ql-fill {
						fill: #06c !important;
					}
				}
			}
		}
	}
}

.ql-editor-test-instruction {
	.ql-container {
		display       : flex;
		flex-direction: column;
	}

	.ql-editor {
		padding: 0px !important;

		img {
			max-width: 100%;
		}
	}
}