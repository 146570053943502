@import "../../../style-assets/typography";

.tag1 {
    align-items     : center;
    padding         : 3px 16px;
    width           : 58px;
    height          : 20px;
    background-color: #FFBDAD;
    border-radius   : 3px;
}

.icon-cover {
    width          : 44px;
    height         : 44px;
    display        : flex;
    align-items    : center;
    justify-content: center;
    cursor         : pointer;
    background     : #F6F5F8;
    border-radius  : 50px;

}

.hiring-stage {
    width          : 100%;
    display        : flex;
    justify-content: space-between;
    align-items    : center;

    .hiring-stage-selection {
        width: 152px;

        .select-container {
            background-color: #F4F5F7;
            border-color    : #F4F5F7;

            input {
                background-color: #F4F5F7;
            }
        }
    }


    // input {
    //     padding  : 8px !important;
    //     font-size: 14px !important;
    // }
}

.view-with-sidebar {
    width             : 100%;
    height            : 100%;
    display           : flex;
    justify-content   : space-between;
    // padding        : 2.5em 2em;

    @media screen and (max-width: 1551px) {
        gap: 10px;
    }

    .name-text {
        word-break        : break-word;
        text-align        : left;
        width             : 100%;
        display           : -webkit-box;
        max-width         : 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow          : hidden;
    }

    .left-sidebar-container {
        width     : 100%;
        transition: width 0.2s;
    }

    .left-sidebar-open {
        width: calc(50vw - 36px - 32px - 24px) !important;
    }

    .right-sidebar-container {
        height    : calc(100vh - 96px);
        width     : 0px;
        overflow  : hidden;
        transition: width 0.2s;

    }

    .test-details-right-sidebar-open {
        width        : 45.5vw;
        overflow     : auto;
        padding      : 24px;
        border-radius: 24px;
        background   : #FFFFFF;
        margin-left  : 24px;
        height       : 100%;
    }

    .test-details-right-sidebar-open-minimized {
        width      : calc(50% - 56px) !important;
        padding    : 24px 24px !important;
        margin-left: 0px;
    }

}

.collapsed-left-sidebar-container {
    width         : 88px !important;
    background    : #ffffff;
    border-radius : 24px;
    transition    : width 0.2s;
    display       : flex;
    flex-direction: column;
    align-items   : center;
    padding       : 35px 0px;
    gap           : 35px;

    .sidebar-container-headline {
        text-orientation: mixed;
        writing-mode    : vertical-lr;
        transform       : rotateZ(180deg);
    }
}

.test-detail-assessments-stats-container {
    width: 100%;

    .AssessmentsStatsIcon-container {
        width          : 48px;
        height         : 48px;
        border         : 1px solid $dark-100;
        border-radius  : 50px;
        display        : flex;
        justify-content: center;
        align-items    : center;

        svg {
            path {
                stroke: #121216 !important;
            }
        }
    }

    .AssessmentsStatsIcon {
        height: 24px !important;
        width : 24px !important;

        svg {
            stroke: #121216 !important;
        }
    }

    .assessments-stats-50-50 {
        width          : 100%;
        display        : flex;
        justify-content: space-between;
        align-items    : center;
        flex-wrap      : nowrap;

        .assessments-stats-card {
            padding-left        : 24px;
            padding-right       : 24px;
            padding-top         : 14px;
            padding-bottom      : 14px;
            // border           : 1px solid $primary-text;
            border-radius       : 24px;
            background-color    : #ffffff;
            // max-width     : 381.75px;
            width               : 23%;
            display             : flex;
            gap                 : 1.5em;
            height              : 100px;
            align-items         : center;
            justify-content     : space-between;

            .stats-card-text {
                display        : flex;
                flex-direction : column;
                align-items    : flex-start;
                justify-content: flex-start;
                border         : 1px solid;

                :first-child {
                    font-size: 1rem;
                }
            }
        }
    }

    .assessments-stats {

        width          : 100%;
        display        : flex;
        align-items    : center;
        flex-wrap      : nowrap;
        justify-content: space-between;

        .assessments-stats-card {
            padding-left        : 24px;
            padding-right       : 24px;
            padding-top         : 14px;
            padding-bottom      : 14px;
            // border           : 1px solid $primary-text;
            border-radius       : 24px;
            background-color    : #ffffff;
            justify-content     : space-between;
            // max-width     : 381.75px;
            width               : 23%;
            display             : flex;
            height              : 100px;
            align-items         : center;

            .stats-card-text {
                display           : flex;
                flex-direction    : column;
                gap               : 8px;
                text-align        : left;
                justify-content   : center;
                // border         : 1px solid;
                height            : 51px;

                .headline-large {
                    font-size  : 18px !important;
                    line-height: 30px;
                }
            }

            .assessment-card-text {
                font-size  : 18px !important;
                font-weight: 400;
                line-height: 30px;
            }
        }
    }
}

.test-detail-container {
    // padding   : 32px 32px 48px;
    // background: #FFFFFF;
    // border    : 1px solid #E9ECEF;
    width   : 100%;
    height  : calc(100vh - 60px);
    overflow: scroll;

    .test-detail-header {
        // margin-bottom      : 32px;
        background-color   : white;
        padding            : 1.5em;
        // border          : 1px solid #DEDAF2;
        border-radius      : 24px;
        height             : 144px;

        .test-detail-header-left {
            gap: 16px;

            .icon-wrapper {
                height         : 40px;
                width          : 44px;
                box-shadow     : 3px 4px 4px 0px #9362CE21;
                border-radius  : 50%;
                border         : 1px solid #DEDAF2;
                display        : flex;
                justify-content: center;
                align-items    : center;
            }
        }


        .test-detail-header-right {
            width: max-content;
            gap  : 10px;

            .btn-md {
                width  : 227;
                padding: 0.25em 0.75em;
                gap    : 0.25em;
            }

            .result {
                width           : 100%;
                padding         : 8px;
                // background   : rgba(233, 236, 239, 0.3);
                border          : 0.2px solid #E9ECEF;
                border-radius   : 4px;
                gap             : 15px;
                height          : 58px;
                display         : flex;
                align-items     : center;
            }
        }
    }

    .candidate-Criteria-selection-bar {
        width           : 100%;
        background-color: white;
        height          : 44px;
        border-radius   : 24px;
    }

    .candidate-details-container {
        width         : 100%;
        display       : flex;
        flex-direction: column;
        align-items   : flex-start;
    }

    .assessments-detail-search-bar {
        display        : flex;
        flex-direction : column !important;
        display        : flex;
        justify-content: space-between;
        border-radius  : 6px;

        .search {
            width           : 100%;
            height          : 44px;
            display         : flex;
            align-items     : center;
            background-color: #F6F5F8;
            padding         : 12px 24px 12px 24px;
            border-radius   : 50px;
            gap             : 8px;

            .on-boarding-input {
                width: 100%;
            }
        }
    }
}

.truncate-assessment-title {
    white-space  : nowrap;
    overflow     : hidden;
    text-overflow: ellipsis;
    max-width    : 260px;
    display      : block;
}

.action-btn-card {
    width         : 200px;
    height        : auto;
    display       : flex;
    z-index       : 1;
    flex-direction: column;
    align-items   : flex-start;
    position      : absolute;
    left          : 0px;
    top           : 60px;
    background    : #FFFFFF;
    border        : 1px solid #DEDAF2;
    box-shadow    : 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
    border-radius : 8px;

    .action-btn-item {
        width        : 100%;
        height       : 52px;
        padding      : 16px 12px;
        background   : #FFFFFF;
        border-bottom: 1px solid rgba(111, 45, 189, 0.3);
    }

    .action-btn-item:first-child {
        border-top-left-radius : 8px;
        border-top-right-radius: 8px;
    }

    .action-btn-item:last-child {
        border-bottom-left-radius : 8px;
        border-bottom-right-radius: 8px;
        border-bottom             : none;
    }

    .action-btn-item:hover {
        background   : #DEDAF2;
        border-bottom: none;
    }
}

.candidate-status-container {
    display           : flex;
    flex-direction    : row;
    justify-content   : center;
    align-items       : center;
    padding           : 6px 24px;
    width             : 100%;
    max-width         : max-content;
    border-radius     : 24px;
    // height         : 30px;
    // color          : #42526E;
    // margin-left    : 16px;
    @extend .body-2;
}

.test-detail-right-view-container {

    width : 100%;
    height: 100%;

    .assessment-reasult-card-container {
        width             : 100%;
        // background     : #FFFFFF;
        // border         : 0.6px solid #C8CBCD;
        // border-radius  : 4px;
        // padding        : 16px;
        // height         : 52px;
        // display        : flex;
        // align-items    : center;
        // justify-content: space-between;
        gap               : 16px;

        .hr {
            width           : 100%;
            margin          : 10px 0px 0px 0px;
            height          : 1px;
            color           : #C8CBCD;
            background-color: #C8CBCD;
        }

        .test {
            width        : 100%;
            background   : #FFFFFF;
            border       : 0.6px solid #C8CBCD;
            border-radius: 4px;
            padding      : 16px;

            .arrow {
                width : 20px;
                height: 20px;
                cursor: pointer;
            }

            .document {
                width        : 298px;
                padding      : 10px;
                border       : 1px solid #C8CBCD;
                border-radius: 4px;
            }
        }

        .test3 {
            width        : 100%;
            background   : #FFFFFF;
            border       : 0.6px solid #C8CBCD;
            border-radius: 4px;
            padding      : 16px;
        }
    }
}

.anti-cheat-card {
    width          : 100%;
    height         : auto;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: space-between;
    gap            : 6px;
    padding        : 12px 24px;
    border-radius  : 24px;
    border         : 1px solid #ECEDF1;
    background     : #FFF;
}

.graph-card-main-container {
    width          : 100%;
    height         : auto;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: space-between;
    gap            : 6px;
    padding        : 24px;
    border-radius  : 24px;
    border         : 1px solid #ECEDF1;
    background     : #FFF;
    position       : relative;

    .graph-card-left-container {
        // width         : calc(100% - 160px);
        width         : 50%;
        height        : 100%;
        display       : flex;
        flex-direction: column;
        align-items   : flex-start;
        gap           : 16px;

        .graph-card-left-inner-container {
            width          : 100%;
            display        : flex;
            flex-direction : row;
            align-items    : flex-start;
            justify-content: space-between;
        }
    }

    .graph-card-right-container {
        width   : 160px;
        height  : 175px;
        position: sticky !important;
    }
}

.test-pass-tag {
    background-color: #E0FFF6;

    span {
        color: #00D69A !important;
    }
}

.test-detailed-container {
    transition: width 0.15s;
    overflow-y: scroll;
}

.coding-test {
    .custom-avatar-badge {
        background: transparent;
    }
}

.minimized-test-detailed-view {
    width   : 0px !important;
    overflow: hidden !important;
}

.test-detailed-view-expanded {
    width        : calc(50% - 56px) !important;
    /* Primary/White */
    padding      : 24px 24px;
    background   : #FFFFFF;
    border-radius: 24px;

    /* Primary/Light */
    // border: 1px solid #DEDAF2;
}

.public-test-detailed-view-expanded {
    width        : calc(50% - 12px) !important;
    background   : #FFFFFF;
    padding      : 24px;
    border-radius: 24px;
}

.anti-cheat-genral-info-box {
    width             : 100%;
    border-radius     : 24px;
    // border         : 1px solid var(--accent-light-purple, );
    background        : #F6F5F8;
    padding           : 12px 18px 12px 18px;
    display           : flex;
    justify-content   : space-between;
    align-items       : center;
    height            : 44px;

    span {
        font-weight: 500;
    }

    .anti-cheat-genral-info-box-right-div {
        width          : 78px;
        height         : 100%;
        border-radius  : 0px 4px 4px 0px;
        display        : flex;
        align-items    : center;
        justify-content: center;

    }

    .anti-cheat-genral-info-box-normal {
        background: #FFF8E0;
        color     : #F4C20D;
    }

    .anti-cheat-genral-info-box-safe {
        background: #E0FFF6;
        color     : #00D69A;
    }

    .anti-cheat-genral-info-box-danger {
        background: #FFE1E1;
        color     : #FC4848;
    }
}

.anti-cheat-genral-info-collapsing-card {

    border-radius   : 8px;
    // border       : 1px solid var(--primary-light, #DEDAF2);
    height          : 85px;
    overflow        : hidden;
    transition      : height 0.25s;
    border-radius   : 24px;


    .anti-cheat-genral-info-collapsing-card-header {
        width          : 100%;
        height         : 85px;
        background     : #F6F5F8;
        padding        : 6px 0px;
        display        : flex;
        flex-direction : column;
        align-items    : flex-start;
        justify-content: space-evenly;
        padding        : 6px 16px;
        cursor         : pointer;
        position       : relative;

        svg {
            position  : absolute;
            right     : 16px;
            transform : rotate(180deg);
            transition: transform 0.25s;
        }

        .anti-cheat-genral-info-collapsing-card-header-text {
            display       : flex;
            flex-direction: column;
            align-items   : flex-start;
            gap           : 4px;
        }
    }

    .anti-cheat-genral-info-collapsing-card-body {
        // padding       : 32px 32px 32px 32px;
        padding       : 12px 18px 12px 18px;
        display       : flex;
        background    : #F6F5F8;
        flex-direction: column;
        gap           : 24px;
        display       : flex;
        text-align    : left;

        span {
            color: #6F2DBD;
        }
    }
}

.anti-cheat-genral-info-collapsing-card-open-with-events,
.anti-cheat-genral-info-collapsing-card-open {
    height: auto;

    .anti-cheat-genral-info-collapsing-card-header {
        background: #F6F5F8;
        padding   : 12px 18px 12px 18px;

        svg {
            transform: rotate(0deg);
        }

    }
}

// .anti-cheat-genral-info-collapsing-card-open-with-events {
//     height: 724px;
// }

// .anti-cheat-genral-info-collapsing-card-open {
//     height: 288px;
// }
@media screen and (min-width: 1200px) and (max-width: 1551px) {
    .view-with-sidebar {
        width             : 100%;
        height            : 100%;
        display           : flex;
        justify-content   : space-between;
        // padding        : 2.5em 2em;
        gap               : 10px;

        .test-details-right-sidebar-open {
            width           : 45.5vw;
            overflow        : auto;
            padding         : 24px;
            border-radius   : 24px;
            background      : #FFFFFF;
            margin-left     : 17px;
            height          : 100%;
            position        : sticky;
            // left         : 12px;
        }

        // .test-detailed-view-expanded {
        //     width        : calc(50% - 56px) !important;
        //     /* Primary/White */
        //     padding      : 24px 24px;
        //     background   : #FFFFFF;
        //     border-radius: 24px;
        //     margin-left  : 0px;

        //     /* Primary/Light */
        //     // border: 1px solid #DEDAF2;
        // }

        .test-details-right-sidebar-open-minimized {
            width      : calc(49% - 56px) !important;
            padding    : 24px 24px !important;
            margin-left: 0px !important;
            position   : relative;
            left       : 0px;
        }



    }

}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .view-with-sidebar {
        height            : 100%;
        width             : calc(100vw - 40px - 32px);
        display           : flex;
        justify-content   : space-between;
        // padding        : 48px 32px;
        gap               : 24px;

        .test-details-right-sidebar-open {
            margin-left: 0px;
        }

        .test-details-right-sidebar-open-minimized {
            width  : calc(100% - 56px) !important;
            padding: 0px 0px !important;
        }

        .left-sidebar-container {
            width     : 98%;
            transition: width 0.2s;
        }

        .test-detail-container {
            width      : 100%;
            // height  : calc(100vh - 90px);
            overflow   : scroll;

            .test-detail-header {
                .test-detail-header-left {
                    gap: 16px;
                }

                .test-detail-header-right {
                    width: max-content;
                    gap  : 10px;

                    .btn-md {
                        width  : 227;
                        padding: 4px 12px;
                        gap    : 4px;
                    }
                }
            }

            .candidate-details-container {
                width            : 100%;
                display          : flex;
                flex-direction   : column;
                align-items      : flex-start;
                // padding       : 32px 32px 48px;
                // background    : #FFFFFF;
                // border        : 1px solid #DEDAF2;
            }
        }

        .right-sidebar-container {
            height    : 100%;
            width     : 100%;
            overflow  : scroll;
            transition: width 0.2s;
        }
    }

    .test-detailed-view-expanded {
        width : 100% !important;
        border: none;
    }

    .public-test-detailed-view-expanded {
        width  : 100% !important;
        padding: 24px;
    }

    .test-detail-right-view-container {
        width : 100%;
        height: 100%;
    }

    .graph-card-main-container {
        .graph-card-left-container {
            width: calc(100% - 220px);
            gap  : 16px;

            .graph-card-left-inner-container {
                flex-direction : column;
                justify-content: flex-start;
            }
        }
    }

    .action-btn-card {
        top: 48px;
    }
}

@media only screen and (min-width: 1199px) and (max-width: 1285px) {
    .assesment-text-truncate {
        white-space  : nowrap;
        overflow     : hidden;
        text-overflow: ellipsis;
        max-width    : 130px;
        display      : block;
    }

}

@media screen and (max-width: 767px) {
    .icon-cover {
        width : 34px;
        height: 34px;
    }

    .test-detail-header-left {
        gap: 8px;
    }

    .action-btn-card {
        top: 48px;
    }

    .public-test-detailed-view-expanded {
        width  : 100% !important;
        padding: 24px;
    }

    .graph-card-main-container {
        flex-direction : column;
        justify-content: flex-start;
        gap            : 32px;

        .graph-card-left-container {
            width : 100%;
            height: 100%;
            gap   : 16px;
        }

        .graph-card-right-container {
            width : 100%;
            height: 260px;
        }
    }

    .collapsed-left-sidebar-container {
        width          : 100% !important;
        height         : 58px !important;
        flex-direction : row;
        justify-content: space-between;
        padding        : 0px 24px;

        .sidebar-container-headline {
            writing-mode: horizontal-tb;
            transform   : rotateZ(0deg);
        }
    }

    .test-detail-page-container {
        height    : calc(100vh - 16px);
        width     : 100%;
        align-self: flex-start;
        margin-top: 16px;

        .test-detail-page-header-container {
            display        : flex;
            flex-direction : column;
            justify-content: center;
            align-items    : flex-start;
            height         : auto;
            margin         : 6px;

            .test-detail-page-header {
                width         : 100%;
                display       : flex;
                flex-direction: row;
                align-items   : center;
                padding-bottom: 24px;
                gap           : 12px;
            }

            .page-heading {
                padding-top: 24px;
            }
        }

        .test-detail-page-content {
            height         : calc(100% - 99px);
            width          : 100%;
            display        : flex;
            flex-direction : column;
            justify-content: flex-start;

            .view-with-sidebar {
                height           : auto;
                // padding       : 24px;
                flex-direction   : column;

                .candidate-details-container {
                    width         : 100%;
                    display       : flex;
                    flex-direction: column;
                    align-items   : flex-start;
                }
            }

            .test-detail-container {
                width : 100%;
                height: 100%;

                .test-detail-header {
                    .test-detail-header-left {
                        gap: 16px;
                    }

                    .test-detail-header-right {
                        width: max-content;
                        gap  : 10px;

                        .btn-md {
                            width  : 227;
                            padding: 4px 12px;
                            gap    : 4px;
                        }
                    }
                }
            }

            .right-sidebar-container {
                height       : 100%;
                width        : 100%;
                padding      : 0px;
                overflow     : scroll;
                transition   : width 0.2s;
                border-radius: 24px;
                background   : #FFFFFF;
            }

            .test-detailed-view-expanded {
                height       : 100% !important;
                width        : 100% !important;
                padding      : 12px;
                border-radius: 24px;
                margin-top   : 16px;
                background   : #FFFFFF;
                border       : none;
            }
        }
    }

    .anti-cheat-genral-info-collapsing-card {
        .anti-cheat-genral-info-collapsing-card-header {
            .anti-cheat-genral-info-collapsing-card-header-text {
                flex-direction: column;
                gap           : 0px;
            }
        }
    }
}

@media screen and (max-width:767px) {
    .test-detail-right-view-container {
        width           : 100%;
        height          : 100%;
        background-color: #FFF;
        padding         : 12px;
        border-radius   : 24px;
        margin-top      : 16px;

    }


}


@media screen and (max-width: 1780px) {

    .test-detail-assessments-stats-container {
        width: 100%;

        .assessments-stats {

            width          : 100%;
            display        : flex;
            gap            : 10 !important;
            align-items    : center;
            flex-wrap      : nowrap;
            justify-content: space-between;

            .assessments-stats-card {
                border-radius    : 24px;
                background-color : #ffffff;
                // max-width     : 381.75px;
                width            : 23%;
            }

            .candidate-assess-stats-card {
                padding: 16px !important;
                height : 62px !important;
            }
        }
    }
}

// @media screen and (max-width: 1550px) {
//     .test-detail-assessments-stats-container {


//         .assessments-stats {


//             .assessments-stats-card {

//                 .assessment-card-text {
//                     font-size  : 16px !important;
//                     font-weight: 400;
//                 }
//             }
//         }
//     }
// }

@media screen and (max-width: 1510px) {
    .test-detail-assessments-stats-container {


        .assessments-stats {

            width          : 100%;
            display        : flex;
            gap            : 1.5em;
            align-items    : center;
            flex-wrap      : wrap;
            justify-content: space-between;

            .assessments-stats-card {
                width: 23%;

                .assessment-card-text {
                    font-size  : 16px !important;
                    font-weight: 400;
                }
            }
        }
    }
}

@media screen and (max-width: 1260px) {
    .test-detail-assessments-stats-container {


        .assessments-stats {

            width      : 100%;
            display    : flex;
            gap        : 1.5em;
            align-items: center;
            flex-wrap  : wrap;

            .assessments-stats-card {
                // border          : 1px solid $primary-text;
                // border-radius   : 6px;
                // background-color: #DEDAF2;
                // display         : flex;
                // gap             : 1.5em;

                .stats-card-text {
                    display       : flex;
                    flex-direction: column;
                    gap           : 6px;
                    text-align    : left;
                }



            }
        }
    }
}

// tab view
@media screen and (max-width: 1200px) {
    .test-detail-assessments-stats-container {
        .assessments-stats {

            width         : 100%;
            display       : flex;
            // gap        : 1.5em;
            align-items   : center;
            flex-wrap     : nowrap;

            .assessments-stats-card {
                padding: 12px !important;
            }
        }
    }
}

// tab veiew
// @media screen and (max-width: 1200px) {
//     .test-detail-assessments-stats-container {
//         .assessments-stats {

//             width      : 100%;
//             display    : flex;
//             gap        : 1.5em;
//             align-items: center;
//             flex-wrap  : wrap;

//             .assessments-stats-card {
//                 // max-width     : 381.75px;
//                 width               : 48.5%;
//                 display             : flex;
//                 gap                 : 1.5em;
//                 // height           : 60px;

//                 .stats-card-text {
//                     display        : flex;
//                     flex-direction : column;
//                     gap            : 0px;
//                     text-align     : left;
//                     justify-content: center;

//                     .headline-large {
//                         font-size  : 16px !important;
//                         line-height: 25px;
//                     }
//                 }

//                 .assessment-card-text {
//                     font-size  : 16px !important;
//                     font-weight: 400;
//                     line-height: 25px;
//                 }
//             }
//         }
//     }

//     .assessments-search-bar {
//         display       : flex;
//         flex-direction: column !important;
//         // align-items: center;
//     }

// }


@media screen and (max-width: 767px) {
    .test-detail-assessments-stats-container {
        .assessments-stats {

            width          : 100%;
            display        : flex;
            // gap        : 1.5em;
            justify-content: space-between;
            align-items    : center;
            flex-wrap      : wrap;

            .assessments-stats-card {
                // max-width     : 381.75px;
                width     : 47%;
                display   : flex;
                // gap    : 1.5em;
            }

            .assessment-card-text {
                font-size  : 12px !important;
                font-weight: 400;
                line-height: 15px !important;
            }
        }
    }

}

@media screen and (max-width: 660px) {
    .test-detail-assessments-stats-container {
        .assessments-stats {

            width      : 100%;
            display    : flex;
            gap        : 1.5em;
            align-items: center;
            flex-wrap  : wrap;

            .assessments-stats-card {

                // max-width     : 381.75px;
                width  : 48.2%;
                display: flex;
                gap    : 1.5em;
            }
        }
    }

    .assessments-search-bar {
        display       : flex;
        flex-direction: column !important;
        // align-items: center;
    }

}

@media screen and (max-width: 590px) {
    .test-detail-assessments-stats-container {
        .assessments-stats {

            width      : 100%;
            display    : flex;
            gap        : 1.5em;
            align-items: center;
            flex-wrap  : wrap;

            .assessments-stats-card {

                // max-width     : 381.75px;
                width  : 48%;
                display: flex;
                gap    : 1.5em;


            }
        }
    }

}

@media screen and (max-width: 560px) {
    .test-detail-assessments-stats-container {
        .assessments-stats {

            width      : 100%;
            display    : flex;
            gap        : 1.5em;
            align-items: center;
            flex-wrap  : wrap;

            .assessments-stats-card {

                // max-width     : 381.75px;
                width  : 46%;
                display: flex;
                gap    : 1.5em;
            }
        }
    }

}

@media screen and (max-width: 535px) {
    .test-detail-assessments-stats-container {
        .assessments-stats {

            width      : 100%;
            display    : flex;
            gap        : 1.5em;
            align-items: center;
            flex-wrap  : wrap;

            .assessments-stats-card {

                // max-width     : 381.75px;
                width  : 46%;
                display: flex;
                gap    : 1.5em;

            }

            .assesment-text-truncate {
                white-space  : nowrap;
                overflow     : hidden;
                text-overflow: ellipsis;
                max-width    : 100px;
                display      : block;
            }
        }
    }


}


@media screen and (max-width: 515px) {
    .test-detail-assessments-stats-container {
        .assessments-stats {

            width         : 100%;
            display       : flex;
            // gap        : 1.5em;
            align-items   : center;
            flex-wrap     : wrap;

            .assessments-stats-card {

                // max-width     : 381.75px;
                width     : 47%;
                display   : flex;
                // gap    : 1.5em;

                // .assesment-info-icon {
                //     width: 105px !important;
                // }
            }

            .assesment-text-truncate {
                white-space  : nowrap;
                overflow     : hidden;
                text-overflow: ellipsis;
                max-width    : 100px;
                display      : block;
            }

            .AssessmentsInfoIcon {
                margin-left: 0px !important;
            }

        }
    }

    .assesment-creation-button {
        width           : 100%;
        border          : 1px solid;
        height          : 72px;
        background-color: #DEDAF2;
        display         : flex;
        justify-content : space-evenly;
        align-items     : center;
        border-radius   : 4px;
        border          : 1px solid #DEDAF2;

        // float: right;
        .add-btn {
            width          : 100%;
            display        : flex;
            justify-content: space-evenly;
            align-items    : center;


        }

    }

}



@media screen and (max-width: 375px) {
    .test-detail-assessments-stats-container {
        .assessments-stats {


            .assessments-stats-card {


                .assesment-info-icon {
                    width: 80px !important;
                }

                .AssessmentsInfoIcon {
                    width      : 11px !important;
                    margin-left: 4px !important;
                }

            }


        }
    }

}

@media screen and (max-width: 320px) {
    .test-detail-assessments-stats-container {
        .assessments-stats {

            width      : 100%;
            display    : flex;
            gap        : 1.5em;
            align-items: center;
            flex-wrap  : wrap;

            .assessments-stats-card {
                // padding-left     : 1em;
                // max-width     : 381.75px;
                width  : 46%;
                display: flex;
                gap    : 0.5em;
                height : 60px;

                .stats-card-text {
                    display        : flex;
                    flex-direction : column;
                    gap            : 0px;
                    text-align     : left;
                    justify-content: center;

                    .headline-large {
                        font-size  : 11px !important;
                        line-height: 18px;
                    }
                }

                .assessment-card-text {
                    font-size  : 11px !important;
                    font-weight: 400;
                    line-height: 15px !important;
                }

                .assesment-info-icon {
                    width: 50px !important;
                }

                .AssessmentsInfoIcon {
                    width: 11px !important;
                }
            }


        }
    }

}