.table-show-rows-select {
    // width                : 176px;
    display              : flex;
    justify-content      : space-between;
    align-items          : center;
    // margin-bottom  : 48px;
    // border            : 1px solid;
    padding              : 12px 24px 12px 24px;
}

.table-pagination-container {
    display    : flex;
    align-items: center;
    padding    : 24px;
    gap        : 4px;
}

.empty-table-text {
    width           : calc(100% - 48px);
    display         : flex;
    flex-direction  : column;
    background-color: #F6F5F8;
    border-radius   : 24px;
    padding         : 18px 24px 18px 24px;
    margin          : 24px;
}

.table-container {
    width              : 100%;
    height             : 100%;
    background-color   : white;
    // border          : 1px solid #DEDAF2;

    .bulk-update-header {
        // height            : 54px;
        padding           : 12px;
        width             : calc(100% - 48px);
        // background     : #6F2DBD4D;
        border-radius     : 24px;
        margin            : auto;
        gap               : 64px;
        display           : flex;
        justify-content   : space-between;
        align-items       : center;
        background-color  : $dark-20;

    }

    .select-row-checkbox {
        position   : relative;
        margin-left: 23px;

        .primary-checkbox {
            position       : absolute;
            display        : flex;
            align-items    : center;
            justify-content: flex-start;
            height         : 58px;
            width          : 0px;
        }
    }

    .indeterminate-checkbox {
        position        : absolute;
        width           : 11px;
        height          : 11px;
        top             : 2px;
        left            : 2px;
        background-color: $primary-brand;
        display         : flex;
        justify-content : center;
        align-items     : center;

        .indeterminate-icon {
            height          : 2px;
            width           : 80%;
            color           : white;
            background-color: white;
        }
    }

    .table-header {
        height             : 58px;
        padding            : 0px 24px;
        // background   : rgba(111, 45, 189, 0.1);
        text-align         : start;
        border-bottom      : 1px solid #ECEDF1;
        // border-top      : 1px solid #DEDAF2;
        position           : relative;

        // &:last-child {
        //     border-right: 1px solid #DEDAF2;
        // }

        // &:first-child {
        //     border-left: 1px solid #DEDAF2;
        // }

        .table-header-selected-filter {
            width          : fit-content !important;
            display        : flex;
            flex-direction : row;
            justify-content: flex-start;
            align-items    : center;
            padding        : 8px 8px;
            gap            : 8px;
            border-radius  : 24px;
            background     : #121216;

            .filter-option {
                width           : 14px;
                height          : 14px;
                display         : flex;
                align-items     : center;
                justify-content : center;
                border-radius   : 50%;
                background-color: #FFFFFF;
                color           : #121216;
            }

            svg {
                color: white;

                path {
                    stroke: white;
                }
            }
        }

        .table-header-open-filter-card-icon {
            display        : flex;
            flex-direction : column;
            justify-content: center;

            svg {
                color: #6F2DBD;

                path {
                    stroke: #6F2DBD;
                }
            }
        }

        .table-filter-card-container {
            min-width : 200px;
            width     : 200px;
            height    : min-content;
            position  : absolute;
            right     : 0px;
            top       : 30px;
            background: #FFFFFF;

            z-index      : 2;
            border       : 1px solid #DEDAF2;
            box-shadow   : 0px 4px 24px rgba(0, 0, 0, 0.06);
            border-radius: 8px;

            .table-filter-card-header {
                width          : 100%;
                padding        : 16px 12px;
                display        : flex;
                flex-direction : row;
                justify-content: space-between;
                align-items    : center;
                border-bottom  : 1px solid #E1E1E1;
            }

            .primary-checkbox-title {
                @extend .body-3;
                font-weight: 400 !important;
                color      : #2B3340 !important;
            }

            .radio-section {
                width          : 100%;
                display        : flex;
                flex-direction : column;
                align-items    : flex-start;
                justify-content: center;

                .select-radio {
                    width          : 100%;
                    padding        : 17px 20px;
                    display        : flex;
                    justify-content: flex-start;
                    align-items    : center;
                    border-bottom  : 0.6px solid #ECEDF1;

                    input[type='radio'] {
                        width       : 15px;
                        height      : 15px;
                        accent-color: $primary-text;
                    }
                }
            }
        }
    }

    .table-row {
        cursor       : pointer;
        border-bottom: 1px solid #DEDAF2 !important;

        // &:last-child {
        //     border: none;
        // }

        &:hover {
            background-color   : #FAF9FC;
            // border-bottom   : 1px solid #DEDAF2 !important;
        }
    }

    .table-col {
        height       : 58px;
        // padding   : 0px 12px;
        text-align   : start;
    }
}

.pagination {
    display       : flex;
    // gap        : 10px;
    align-items   : center;

    .disable-arrow-icon {
        color: #C8CBCD;

        path {
            stroke: #C8CBCD;
        }
    }
}

.pagination-input {
    width     : 35px;
    height    : 20px;
    text-align: center;
    border    : none !important;
}

.table-search-container {
    width      : 100%;
    padding    : 12px;
    display    : flex;
    align-items: center;

    .table-search-input {
        max-width    : 550px;
        width        : 100%;
        height       : 44px;
        padding      : 0px 24px;
        display      : flex;
        align-items  : center;
        background   : #F6F5F8;
        border-radius: 24px;
        gap          : 8px;

        .on-boarding-input {
            width: 100%;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1439px) {}

@media screen and (max-width: 767px) {
    .table-container {
        .table-col {
            height    : 58px;
            padding   : 0px 6px;
            text-align: start;
        }
    }
}