// // Kindly press Ctrl+A and then press Ctrl+/ to use the code

$primary-brand   : #7C21FF;
$secondary-brand : #896BFF;
$success         : #00D69A;
$danger          : #F33149;
$dark-100        : #121216;
$dark-50         : #808191;
$dark-30         : #ECEDF1;
$dark-20         : #F6F5F8;
$white           : #FFFFFF;

.danger-text {
    color: $danger;
}

.white {
    color: $white !important;
}

.dark-100 {
    color: $dark-100 !important;
}

.dark-50 {
    color: $dark-50 !important;
}

.dark-30 {
    color: $dark-30 !important;
}

.dark-20 {
    color: $dark-20 !important;
}

// -------------------------------------------------------------------------------------------------------------------------------

//Theme colors
$primary-theme   : #F8DEFF;
$secondary-theme : #DBEBFB;

//Brand colors
// $primary-brand : #392D69;

$primary-dark  : #392D69;
$primary-light : #CBC2F2;
$dark-purple   : #462B87;
$submenu-color : #6C5CD6;

$primary-text      : #6F2DBD;
// $secondary-brand: #B078E8;
$tertiary-brand    : #00B4D8;
// $success        : #00D69A;
// $danger         : #FC4848;
$blue              : #1F41F7;
$mediocre-yellow   : #F4C20D;

//White color
$white: #FFFFFF;

//Black colors
$black-900: #10182B;
$black-800: #1B2434;
$black-700: #2B3340;
$black-600: #3E454D;
$black-500: #56585A;
$black-400: #96999C;
$black-300: #C8CBCD;
$black-200: #EAEDEE;
$black-100: #F4F6F6;

$grey     : #B4B4b4;
$dark-grey: #5E5873;

$danger-500: #FC4848;

.black {
    color: $black-900;
}

.primary-text {
    color: $primary-text;
}

.primary-dark-text {
    color: $primary-brand;
}

// .danger-text {
//     color: $danger;
// }

.black-100 {
    color: $black-100;
}

.black-200 {
    color: $black-200;
}

.black-300 {
    color: $black-300;
}

.black-400 {
    color: $black-400;
}

.black-500 {
    color: $black-500;
}

.black-600 {
    color: $black-600;
}

.black-700 {
    color: $black-700
}

.black-800 {
    color: $black-800
}

.black-900 {
    color: $black-900
}