@import "./../../style-assets/colors";

.alert-wrapper {
	margin: 8px 0px;
}

.alert-box-container {
	position                   : fixed;
	width                      : 660px;
	// width          : 100%;
	// height         : 100%;
	top                        : 24px;
	right                      : 0;
	// left                    : 1050px;
	right                      : 20px;
	// bottom            : 0;
	display                    : flex;
	// justify-content      : end;
	z-index                    : 9999999;
	padding                    : 24px;
	border-radius              : 24px;
	background-color           : white;
	box-shadow                 : 3px 4px 4px rgba(6, 5, 7, 0.13);

	@keyframes pop-down {
		from {
			margin-top: 0px
		}

		to {
			margin-top: 20px
		}
	}

	@keyframes fade-out {
		from {
			opacity: 10
		}

		to {
			opacity: 0
		}
	}

	.alert-box {
		border-radius                     : 3px !important;
		// padding                        : 12px 16px;
		border                            : none;
		// background-color: #FFE1E1 !important;
		// min-width             : 200px;
		// width                    : min-content;
		min-width                         : 560px;
		max-width                         : 560px;
		height                            : min-content;
		display                           : flex;
		// transition                  : margin-top 1s;
		animation                         : 0.2s 0s 1 pop-down, 0.5s 2s 1 fade-out;
		// margin-top                     : 20px;
		// align-items        : flex-start;

		span {
			text-align: justify;
		}

	}

	.error-alert-box {
		border-radius      : 3px !important;
		// padding      : 12px 16px;
		border             : none;
		// min-width       : 580px;
		// max-width       : 580px;
		height             : min-content;
		display            : flex;
		// margin-top   : 20px;
		gap                : 12px;
		width              : 100%;

		.alert-icon {
			width      : 24px;
			height     : 24px;
			padding-top: 2px;
		}

		.alert-text {
			width         : calc(100% - 36px);
			display       : flex;
			flex-direction: column;
			align-items   : flex-start;

			.text-header {
				width          : 100%;
				display        : flex;
				flex-direction : row;
				align-items    : center;
				justify-content: space-between;
				gap            : 24px;
			}

			.dismiss-text {
				cursor         : pointer;
				text-decoration: underline;
			}

			.success {
				color: $success !important;
			}

			.danger {
				color: $danger !important;
			}

			span {
				text-align: justify;
			}
		}

	}
}

.success {
	// background-color: #E0FFF6 !important;
	stroke: #00D69A;
}

.success span {
	color: $success;
}

.warning {
	// background-color: #FFF8E0 !important;
	stroke: #F4C20D;
}

.danger {
	// background-color: #f8d7da !important;
	stroke: #FC4848;
}

.danger span {
	color: $danger;
}

.info {
	background-color: #D6F0FF !important;
	stroke          : #46B4FB;
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {

	.alert-box-container {
		top  : 113px;
		right: 20px;

		.alert-box {
			min-width: 327px;
			max-width: 327px;
		}

		// .error-alert-box {
		// 	min-width: 327px;
		// 	max-width: 327px;
		// }
	}
}

@media only screen and (max-width: 767px) {
	.alert-box-container {
		width          : calc(100% - 32px);
		top            : 124px;
		right          : 16px;
		justify-content: center;

		.alert-box {
			min-width: 100%;
			max-width: 100%;
		}

		.error-alert-box {
			min-width: 100%;
			max-width: 100%;
		}
	}
}