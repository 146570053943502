.primary-switch {
    width: 100%;
	height     : 100%;
	align-items: flex-start;
    display: flex;

    .primary-switch-container{
        display: flex;
		flex-direction: row;
        align-items: center;
		gap        : 8px;
    }
}