.subscription-plan-container {
    text-align: start;
    padding   : 0;
    width     : 100%;

    .plan {
        .freeText {
            color: $primary-brand;
        }

        .testsText {
            color        : $primary-text;
            margin-bottom: 12px;
        }
    }

    .empty-circle {
        width        : 12px;
        min-width    : 12px !important;
        height       : 12px;
        max-height   : 12px !important;
        border-radius: 50%;
        border       : 1px solid $primary-text;
        margin-right : 8px;
        margin-top   : 4px;
    }

    .payment-info-form {
        .error-text {
            color: $danger;
        }

        .submit-button {
            height: 40px;

            .cancel-button {
                .title {
                    color: black;
                }
            }
        }
    }
}

.payment-container {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    width          : 100%;
    padding        : 32px 0px 32px 0px;
    gap            : 24px;
}

.payment-plans-container {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    align-items    : flex-start;
    justify-content: center;

    gap: 16px;

    .payment-plan-card {
        width            : 300px;
        height           : 612px;
        display          : flex;
        flex-direction   : column;
        align-items      : flex-start;
        gap              : 36px;
        border-radius    : 24px;
        background       : #F6F5F8;
        // box-shadow    : 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
        padding          : 24px;

        .payment-plan-card-header {
            width           : 100%;
            height          : 113px;
            display         : flex;
            flex-direction  : column;
            align-items     : center;
            justify-content : center;
            background      : #DEDAF2;
            gap             : 24px;
            border-radius   : 24px;
            background-color: #121216;
        }

        .plan-items {
            display       : flex;
            flex-direction: column;
            gap           : 20px;

            .plan-text {
                width     : 90%;
                text-align: left;
            }
        }
    }
}

.plan-confirm-dialogue {
    width         : 100%;
    padding       : 32px 0px 0px 0px;
    gap           : 32px;
    display       : flex;
    flex-direction: column;
    align-items   : center;

    .plan-details {
        width          : 100%;
        display        : flex;
        flex-direction : column;
        align-items    : center;
        justify-content: flex-end;
        gap            : 32px;

        .plan-details-header {
            width          : 100%;
            display        : flex;
            flex-direction : row;
            align-items    : center;
            justify-content: space-between;
            padding        : 12px 32px 12px 32px;
            background     : $dark-100;
            border-radius  : 24px;
        }

        .plan-details-info {
            width          : 100%;
            display        : flex;
            flex-direction : row;
            flex-wrap      : wrap;
            justify-content: space-between;
            gap            : 24px;

            .plan-details-info-check {
                width          : 45%;
                display        : flex;
                flex-direction : row;
                align-items    : center;
                justify-content: space-between;

                .plan-text {
                    width     : 90%;
                    text-align: left;
                }
            }
        }
    }

    .plan-pricing {
        width         : 100%;
        height        : 122px;
        padding       : 16px;
        display       : flex;
        flex-direction: column;
        align-items   : flex-start;
        border        : 1px solid #ECEDF1;
        gap           : 16px;
        border-radius : 24px;
    }
}

.plans-modal-container {
    background   : white;
    border-radius: 24px;

    .modal-container {
        width: 900px;

    }
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {
    .payment-plans-container {
        .payment-plan-card {
            width: inherit;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
    .payment-plans-container {
        flex-wrap: wrap;

        .payment-plan-card {
            max-width: 290px;
        }
    }
}

@media screen and (max-width: 767px) {
    .payment-plans-container {
        flex-wrap: wrap;

        .payment-plan-card {
            height: auto;

            .learn-more-container {
                width          : 100%;
                display        : flex;
                flex-direction : column;
                align-items    : center;
                justify-content: center;
                padding-top    : 24px;
                margin-top     : 32px;
                border-top     : 0.6px solid #DEDAF2;
                gap            : 24px;
            }
        }
    }

    .plan-confirm-dialogue {
        .plan-details {
            // .plan-details-header {
            //     flex-direction : column;
            //     align-items    : flex-start;
            //     justify-content: flex-start;
            // }

            .plan-details-info {
                flex-direction : column;
                flex-wrap      : nowrap;
                justify-content: flex-start;
                gap            : 16px;

                .plan-details-info-check {
                    width: 100%;
                }
            }
        }
    }
}