@import "../../../mixins/typegraphyMixin";

.mask {
    position        : absolute;
    top             : 0;
    left            : 0;
    width           : 100%;
    height          : 100%;
    background-color: rgba(255, 255, 255, 0.384);
    /* Adjust the alpha value for transparency */
    z-index         : 9999;
    /* Ensure the mask is above other elements on the page */
    // display: none; /* Initially hide the mask */
}

.payment-message {
    // width: 100%;
    display         : flex;
    align-items     : center;
    text-align      : left;
    background-color: #FFFFFF;
    box-shadow      : 0px 4px 24px 0px #0000000F;
    padding         : 24px;
    border-radius   : 24px;
    gap             : 12px;
}

.publish-assessment-main-container {
    width         : calc(100% - 24px);
    display       : flex;
    flex-direction: column;
    align-items   : flex-start;
    gap           : 24px;

    .publish-assessment-header {
        width           : 100%;
        display         : flex;
        flex-direction  : column;
        background-color: white;
        padding         : 24px;
        border-radius   : 24px;
        gap             : 18px;
    }

    .publish-assessment-container {
        width         : 100%;
        display       : flex;
        flex-direction: row;
        align-items   : flex-start;
        gap           : 16px;

        .publish-assessment-child-container {
            width          : calc(50vw - 8px);
            height         : max-content;
            min-height     : 173px;
            display        : flex;
            flex-direction : column;
            align-items    : flex-start;
            justify-content: space-between;
            padding        : 24px;
            background     : #FFFFFF;
            border-radius  : 24px;
        }
    }

    .integration-public-assessment-container {
        width         : 100%;
        height        : auto;
        display       : flex;
        flex-direction: column;
        align-items   : flex-start;
        background    : #FFFFFF;
        padding       : 24px;
        border-radius : 24px;
        gap           : 16px;

        .hydrated {
            width: 100%;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1439px) {
    .publish-assessment-main-container {
        width: 100%;

        .publish-assessment-container {
            flex-direction: column;

            .publish-assessment-child-container {
                width: 100%;
            }

            .integration-public-assessment-container {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .publish-assessment-main-container {
        width: 100%;

        .publish-assessment-header {
            margin-top: 24px;
        }

        .publish-assessment-container {
            flex-direction: column;

            .publish-assessment-child-container {
                width: 100%;
            }

            .integration-public-assessment-container {
                width: 100%;
            }
        }
    }
}