@import "./signup";

.login-form-container {
    width  : 100%;
    padding: 0px;
    @extend .signup-form-container;

    .login-input-group {
        .submit-button {
            margin-top: 24px;
            height    : 40px;
        }

        .forget-password {
            margin-top: 16px;
            font-size : 12px;
        }
    }
}