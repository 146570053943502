.hover-primary-text {
    &:hover {
        @extend .primary-text
    }
}

.assessment-view-container {
    width : 100%;
    height: 100%;
}

.assessment-creation-dashboard-page {
    width         : 100%;
    height        : calc(100vh - 48px);
    overflow      : hidden;
    display       : flex;
    flex-direction: row;
    gap           : 24px;

    .assessment-creation-page-content {
        width         : calc(100vw - 75px - 446px);
        height        : 100%;
        overflow      : scroll;
        display       : flex;
        flex-direction: column;
        gap           : 24px;
    }

    .assessment-creation-page-right-side-content {
        min-width      : 350px;
        width          : 350px;
        height         : 100%;
        overflow       : auto;
        display        : flex;
        flex-direction : column;
        align-items    : flex-start;
        justify-content: flex-start;
        padding        : 24px;
        gap            : 24px;
        border-radius  : 24px;
        background     : #FFFFFF;
        position       : sticky;
    }
}

.assesment-creation-button {
    width           : 48.5%;
    border          : 1px solid;
    height          : 72px;
    background-color: #DEDAF2;
    display         : flex;
    justify-content : space-evenly;
    align-items     : center;
    border-radius   : 4px;
    border          : 1px solid #DEDAF2;
    float           : right;

    .add-btn {
        width          : 100%;
        display        : flex;
        justify-content: space-evenly;
        align-items    : center;


    }
}

.label-custom-tooltip {
    width         : 270px;
    height        : auto;
    display       : flex;
    flex-direction: column;
    position      : absolute;
    top           : 0px;
    left          : 0px;
    z-index       : 999;
    background    : #121216;
    padding       : 16px;
    border-radius : 24px;
    gap           : 12px;
}

.assessments-stats-container {
    @media screen and (max-width: 1440px) {
        // font-size: 12px;
    }

    .assessments-stats {

        width      : 100%;
        display    : flex;
        align-items: center;
        flex-wrap  : wrap;
        gap        : 16px;

        .assessments-stats-card {

            // border           : 1px solid $primary-text;
            border-radius    : 24px;
            background-color : #ffffff;
            // max-width     : 381.75px;
            width            : 24.2%;
            display          : flex;
            gap              : 12px;
            height           : 92px;
            align-items      : center;
            padding          : 24px;

            .stats-card-text {
                display        : flex;
                flex-direction : column;
                gap            : 8px;
                text-align     : left;
                justify-content: center;

                .headline-large {
                    font-size  : 18px !important;
                    line-height: 30px;
                }
            }

            .AssessmentsStatsIcon-container {
                width          : 48px;
                height         : 48px;
                border         : 1px solid $dark-100;
                border-radius  : 50px;
                display        : flex;
                justify-content: center;
                align-items    : center;

                svg {
                    path {
                        stroke: #121216 !important;
                    }
                }
            }

            .AssessmentsStatsIcon {
                height: 24px !important;
                width : 24px !important;

            }
        }
    }
}

.create-assessment-secttion {
    // height  : calc(100vh - 245px);
    display : flex;
    gap     : 24px;
    position: relative;

    .warning-border {
        border: 1px solid var(--neutral-dark, #F4C20D) !important;
    }

    .missing-testpack-message {
        display      : flex;
        padding      : 6px 12px;
        border-radius: 4px;
        background   : var(--neutral-light, #FFF8E0);
        align-self   : flex-start;
        width        : 100%;
    }

    .create-assessment-secttion-container {
        width                      : 100%;
        // height            : min-content;
        min-height                 : 100%;
        height                     : min-content;
        display                    : flex;
        flex-direction             : column;
        align-items                : center;
        // justify-content      : center;
        background                 : #FFFFFF;
        // border         : 1px solid #DEDAF2;
        border-radius              : 24px;
        // overflow-y              : auto;

        .create-assessment-secttion-container-header {
            /* Auto layout */
            display                    : flex;
            flex-direction             : row;
            justify-content            : center;
            align-items                : flex-start;
            padding                    : 1em 1.125em 1em 1.5em;
            width                      : 100%;
            min-height                 : 77px;
            // background     : linear-gradient(0deg, rgba(222, 218, 242, 0.5), rgba(222, 218, 242, 0.5)), #FFFFFF;
            // background           : rgba(111, 45, 189, 0.1);
            // border-bottom           : 1px solid #DEDAF2;
            // border-radius     : 16px 16px 0px 0px;

        }
    }

    .create-assessment-secttion-options-container {
        // position                   : sticky;

        // top                        : 10px;
        width                      : 100%;
        display                    : flex;
        // height            : 100%;
        height                     : min-content !important;
        flex-direction             : row;
        justify-content            : center;
        align-items                : flex-start;
        padding                    : 24px;
        gap                        : 0.625em;
        height                     : max-content;
        background                 : #FFFFFF;
        // border               : 1px solid #DEDAF2;
        // box-shadow              : 6px 15px 30px rgba(0, 0, 0, 0.08);
        border-radius              : 24px;
    }
}

.assessments-search-bar {
    display              : flex;
    justify-content      : space-between;
    // padding           : 1em 1.5em 1em 1.5em;
    // border         : 1px solid #DEDAF2;
    border-radius        : 6px;

    .search {
        padding         : 12px 24px 12px 24px;
        // border       : 1px solid #DEDAF2;
        border-radius   : 50px;
        width           : 424px;
        height          : 44px;
        display         : flex;
        gap             : 8px;
        align-items     : center;

        background-color: white;

        .on-boarding-input {
            width: 100%;
        }
    }
}

.add-new-assessment-container {
    box-sizing        : border-box;
    display           : flex;
    justify-content   : flex-end;
    align-items       : center;
    width             : 100%;
    height            : auto;
    // border         : 1px solid #DEDAF2;
    // border-radius  : 4px;

    .add-btn {
        width          : 100%;
        padding        : 16px 24px;
        display        : flex;
        flex-direction : row;
        justify-content: space-between;
        align-items    : center;
        cursor         : pointer;
        margin         : 24px 0px;
        border-radius  : 4px;
        border         : 1px solid var(--primary-light, #DEDAF2);
        background     : linear-gradient(0deg, rgba(222, 218, 242, 0.50) 0%, rgba(222, 218, 242, 0.50) 100%), #FFF;
    }
}

.create-assessment-container {
    width         : 100%;
    height        : 100%;
    padding       : 32px 24px;
    display       : flex;
    flex-direction: column;
    align-items   : flex-start;
    background    : #FFFFFF;

    /* Primary/Light */
    border       : 1px solid #DEDAF2;
    border-radius: 6px;
}

.create-assessment-right-container {
    width   : 100%;
    position: relative;


    .create-assessment-right-inner-container {
        display              : flex;
        flex-direction       : column;
        align-items          : flex-start;
        justify-content      : flex-start;
        width                : 100%;
        // padding        : 0px 32px;
        // position          : fixed;

        .create-assessment-secttion-options-container {
            width          : 100%;
            display        : flex;
            height         : min-content !important;
            flex-direction : column;
            justify-content: center;
            align-items    : flex-start;
            gap            : 16px;

            .section-type-container {
                width: 100%;
                gap  : 16px;

                .in-section-btn-V2 {
                    background-color: #F6F5F8;

                    &:hover {
                        background-color: #121216;
                    }
                }
            }
        }
    }

    .deadline-input {
        width        : 50px;
        height       : 5px;
        background   : transparent;
        text-align   : center;
        padding      : 6px !important;
        border-radius: 0px !important;
        border-bottom: 1px solid black;
        height       : 27px !important;
        margin-left  : 10px;
    }

    input[type="number"] {
        -moz-appearance: textfield;
        /* Firefox */
    }

    /* Hide the up and down arrows in other browsers */
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance        : none;
    }

    .decline {
        padding      : 15px 10px;
        align-items  : center;
        background   : #FFE1E1;
        border-radius: 5px;
        gap          : 8px;
    }

    .addition-settings-validation-error {
        background-color: #FFE1E1 !important;
        border-color    : #FC4848 !important;
    }

    span {
        text-align: start;
    }

    .focused-assessment-section {
        border-left : 4px solid #121216;
        color       : #121216 !important;
        padding-left: 10px;
    }

    .additional-section {
        display        : flex;
        flex-direction : column;
        padding        : 12px 18px;
        justify-content: space-between;
        align-items    : center;
        border-radius  : 24px;
        background     : #F6F5F8;

        .open-ai-icon {
            transition: 0.4s ease-out;
        }

        &:hover {
            // border    : 1px solid #6F2DBD;
            // box-shadow: 3px 4px 4px rgba(147, 98, 206, 0.13);
            cursor: pointer;

            span {
                // font-weight   : 500;
                // color      : #6F2DBD !important;
            }

            svg {
                // height: 30px !important;
                // width : 30px !important;
            }

            .open-ai-icon {
                -webkit-transform: rotateZ(180deg);
                -moz-transform   : rotateZ(180deg);
                transform        : rotateZ(180deg);
            }
        }
    }

    .selected-additional-section {
        // background: #E0FFF6;
        border: 0.6px solid #00D69A;
    }

    .additional-section-extended {
        // border-right  : 0.6px solid var(--primary-light, #DEDAF2);
        // border-bottom : 0.6px solid var(--primary-light, #DEDAF2);
        // border-left   : 0.6px solid var(--primary-light, #DEDAF2);

        width                 : 100%;
        display               : flex;
        flex-direction        : column;
        // border-radius      : 24px;
        gap                   : 12px;
        // background-color: white;

        .additional-section-extended-details {
            background-color: rgb(255, 255, 255);
            border-radius   : 24px;
            padding         : 12px 18px 12px 18px;
            display         : flex;
            align-items     : center;
        }

        .primary-checkbox {
            width      : min-content !important;
            align-self : flex-start;
            padding-top: 8px;

        }
    }

    svg {
        // stroke: #6F2DBD !important;
        // fill  : #6F2DBD !important;
        // color : #6F2DBD !important;
    }
}

// 
@media screen and (max-width: 1780px) {
    .assessments-stats-container {


        .assessments-stats {

            width          : 100%;
            display        : flex;
            gap            : 1.5em;
            align-items    : center;
            flex-wrap      : wrap;
            justify-content: space-between;

            .assessments-stats-card {
                width: 23%;
            }
        }
    }
}

@media screen and (max-width: 1610px) {
    .assessments-stats-container {


        .assessments-stats {

            width          : 100%;
            display        : flex;
            gap            : 1.5em;
            align-items    : center;
            flex-wrap      : wrap;
            justify-content: space-between;

            .assessments-stats-card {
                // border          : 1px solid $primary-text;
                border-radius      : 24px;
                // background-color: #DEDAF2;
                width              : 23%;


            }
        }
    }
}

@media screen and (max-width: 1510px) {
    .assessments-stats-container {


        .assessments-stats {

            width          : 100%;
            display        : flex;
            gap            : 1.5em;
            align-items    : center;
            flex-wrap      : wrap;
            justify-content: space-between;

            .assessments-stats-card {
                // border          : 1px solid $primary-text;
                border-radius      : 24px;
                // background-color: #DEDAF2;
                width              : 23%;
            }
        }
    }
}

@media screen and (max-width: 1260px) {
    .assessments-stats-container {


        .assessments-stats {

            width      : 100%;
            display    : flex;
            align-items: center;
            flex-wrap  : wrap;

            .assessments-stats-card {
                // border          : 1px solid $primary-text;
                border-radius      : 24px;
                // background-color: #DEDAF2;
                gap                : 1.5em;

                .stats-card-text {
                    display       : flex;
                    flex-direction: column;
                    gap           : 8px;
                    text-align    : left;
                }



            }
        }
    }
}

// tab veiew
@media screen and (max-width: 1200px) {
    .assessments-stats-container {
        .assessments-stats {

            width          : 100%;
            display        : flex;
            justify-content: space-between;
            align-items    : center;
            flex-wrap      : nowrap;


            .assessments-stats-card {
                border-radius  : 24px;
                display        : flex;
                justify-content: space-between;
                padding        : 16px;
                gap            : 0 !important;

                .stats-card-text {
                    display        : flex;
                    flex-direction : column;
                    gap            : 8px;
                    text-align     : left;
                    justify-content: center;

                }

                .AssessmentsStatsIcon {
                    height: 24px !important;
                    width : 24px !important;

                    svg {
                        stroke: #121216 !important;
                    }

                    svg {
                        stroke: #121216 !important;
                    }
                }
            }
        }
    }

    .assessments-search-bar {
        display       : flex;
        flex-direction: column !important;
        // align-items: center;
    }

}

@media screen and (max-width: 885px) {
    .assessments-stats-container {


        .assessments-stats {

            width      : 100%;
            display    : flex;
            align-items: center;

            .assessments-stats-card {
                width: 22%;

            }

        }
    }

}

@media screen and (max-width: 810px) {
    .assessments-stats-container {
        .assessments-stats {

            width      : 100%;
            display    : flex;
            align-items: center;


        }
    }

    .assesment-creation-button {
        width           : 48.4%;
        border          : 1px solid;
        height          : 72px;
        background-color: #DEDAF2;
        display         : flex;
        justify-content : space-evenly;
        align-items     : center;
        border-radius   : 4px;
        border          : 1px solid #DEDAF2;
        float           : right;

        .add-btn {
            width          : 100%;
            display        : flex;
            justify-content: space-evenly;
            align-items    : center;


        }
    }
}

// mobile view
@media screen and (max-width: 767px) {
    .search {
        width: 100% !important;
    }

    .assessments-stats-container {


        .assessments-stats {

            width          : 100%;
            display        : flex;
            justify-content: space-between;
            align-items    : center;
            flex-wrap      : wrap;
            margin-bottom  : 16px;


            .assessments-stats-card {
                border-radius  : 24px;
                display        : flex;
                justify-content: space-between;
                padding        : 16px;
                gap            : 0 !important;
                width          : 47%;

                .stats-card-text {
                    display        : flex;
                    flex-direction : column;
                    gap            : 8px;
                    text-align     : left;
                    justify-content: center;

                }

                .AssessmentsStatsIcon {
                    height: 24px !important;
                    width : 24px !important;

                    svg {
                        stroke: #121216 !important;
                    }
                }
            }
        }
    }

    .assessments-search-bar {
        display       : flex;
        flex-direction: column !important;
        // align-items: center;
    }

}

@media screen and (max-width: 410px) {
    .search {
        width: 100% !important;
    }

    .assessments-stats-container {


        .assessments-stats {

            width          : 100%;
            display        : flex;
            justify-content: space-between;
            align-items    : center;
            flex-wrap      : wrap;


            .assessments-stats-card {
                border-radius  : 24px;
                display        : flex;
                justify-content: space-between;
                padding        : 16px;
                gap            : 0 !important;
                width          : 47%;

                .stats-card-text {
                    display        : flex;
                    flex-direction : column;
                    gap            : 8px;
                    text-align     : left;
                    justify-content: center;

                }

                .AssessmentsStatsIcon {
                    height: 24px !important;
                    width : 24px !important;

                    svg {
                        stroke: #121216 !important;
                    }
                }
            }
        }
    }

    .assessments-search-bar {
        display       : flex;
        flex-direction: column !important;
        // align-items: center;
    }

}


@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .assessment-creation-dashboard-page {
        width            : calc(100vw - 80px);
        height           : calc(100vh - 48px);
        overflow         : auto;
        display          : flex;
        flex-direction   : column;
        // padding-bottom: 72px;

        .assessment-creation-page-content {
            display        : flex;
            height         : auto;
            width          : 100%;
            flex-direction : column;
            justify-content: flex-start;

            .assessment-creation-page-header {
                display                    : flex;
                justify-content            : space-between;
                align-items                : center;
                height                     : 100px;
                // margin-bottom           : 32px;
                // background-color  : red;
                // padding              : 24px;
                // padding        : 0px 32px;
                // border-bottom  : 1px solid #DEDAF2;
            }
        }

        .assessment-creation-page-right-side-content {
            width          : 100%;
            display        : flex;
            flex-direction : column;
            align-items    : flex-start;
            justify-content: flex-start;
            padding        : 24px;
            gap            : 24px;
            background     : #FFFFFF;
            border-radius  : 24px;
        }
    }

    .create-assessment-secttion {
        // height: calc(87vh - 245px);
        gap: 12px;

        .create-assessment-secttion-container {
            // width: calc(100% - 12px - 176px - 12px);
        }

        .create-assessment-secttion-options-container {
            width  : 186px;
            padding: 12px;
        }
    }
}

@media screen and (max-width: 767px) {
    .assessment-view-container {
        width             : 100%;
        height            : 100%;
        display           : flex;
        // align-items    : center;
        justify-content   : center;
    }

    .assessment-creation-dashboard-page {
        width               : 100%;
        // height           : 100%;
        display             : flex;
        flex-direction      : column;
        // padding-bottom: 72px;

        .assessment-creation-page-content {
            display        : flex;
            height         : auto;
            width          : 100%;
            flex-direction : column;
            justify-content: flex-start;
            margin-top     : 24px;

            .assessment-creation-page-header {
                display          : flex;
                flex-direction   : row;
                align-items      : center;
                height           : min-content;
                justify-content  : space-between;
                gap              : 12px;
                // border-bottom : 1px solid #DEDAF2;

                .icon-wrapper {
                    align-self: flex-start;
                    margin-top: 5px;
                }

                .header-inner-container {
                    display             : flex;
                    // margin-top       : 24px;
                    margin-bottom       : 24px;
                    // flex-direction: column !important;
                    // align-items   : flex-start !important;

                    // .headline-medium {
                    //     text-align: start;
                    // }
                }
            }
        }

        .assessment-creation-page-right-side-content {
            width          : 100%;
            display        : flex;
            flex-direction : column;
            align-items    : flex-start;
            justify-content: flex-start;
            padding        : 24px;
            gap            : 24px;
            background     : #FFFFFF;
            border-radius  : 24px;
        }
    }

    .create-assessment-secttion {
        gap: 12px;

        .create-assessment-secttion-container {
            width: 100%;
        }

        .create-assessment-secttion-options-container {
            width        : 100%;
            margin-bottom: 48px;
        }
    }
}

@media screen and (max-width: 480px) {
    .assessment-creation-dashboard-page {
        width         : 100%;
        height        : max-content;
        display       : flex;
        flex-direction: column;
        padding-bottom: 2px;

        .assessment-creation-page-content {
            display        : flex;
            height         : auto;
            width          : 100%;
            flex-direction : column;
            justify-content: flex-start;

            .assessment-creation-page-header {
                display             : flex;
                flex-direction      : row;
                align-items         : center;
                height              : min-content;
                // padding       : 24px 0px;
                // margin           : 0px 24px;
                gap                 : 12px;
                margin-bottom       : 16px;
                // border-bottom : 1px solid #DEDAF2;

                .icon-wrapper {
                    align-self: flex-start;
                    margin-top: 5px;
                }

                .header-inner-container {
                    display       : flex;
                    flex-direction: column !important;
                    align-items   : flex-start !important;

                    // .headline-medium {
                    //     text-align: start;
                    // }
                }
            }
        }

        .assessment-creation-page-right-side-content {
            width          : 100%;
            display        : flex;
            flex-direction : column;
            align-items    : flex-start;
            justify-content: flex-start;
            padding        : 24px;
            gap            : 24px;
            background     : #FFFFFF;
            border-radius  : 24px;
        }
    }

}

@media screen and (max-width: 330px) {
    .search {
        width: 100% !important;
    }

    .assessments-stats-container {


        .assessments-stats {

            width          : 100%;
            display        : flex;
            justify-content: space-between;
            align-items    : center;
            flex-wrap      : wrap;


            .assessments-stats-card {
                border-radius  : 24px;
                display        : flex;
                justify-content: space-between;
                padding        : 16px;
                gap            : 0 !important;
                width          : 46%;

                .stats-card-text {
                    display        : flex;
                    flex-direction : column;
                    gap            : 8px;
                    text-align     : left;
                    justify-content: center;

                }

                .AssessmentsStatsIcon {
                    height: 24px !important;
                    width : 24px !important;

                    svg {
                        stroke: #121216 !important;
                    }
                }
            }
        }
    }

    .assessments-search-bar {
        display       : flex;
        flex-direction: column !important;
        // align-items: center;
    }

}