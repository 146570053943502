.nav-bar {
    width         : 100%;
    display       : flex;
    flex-direction: row;
    align-items   : center;
    gap           : 16px;

    .assessments-search-bar {
        width        : calc(100% - 132px);
        height       : auto;
        display      : flex;
        border-radius: 6px;

        .search {
            width        : 100%;
            height       : 44px;
            border-radius: 24px;
            display      : flex;
            align-items  : center;
            background   : white;
            gap          : 8px;

            .on-boarding-input {
                width: 100%;
            }
        }
    }

    .nav-popup {
        display            : flex;
        flex-direction     : column;
        gap                : 10px;
        padding            : 1.5em;
        background-color   : white;
        position           : absolute;
        top                : 50px;
        right              : 0px;
        // border          : 0.6px solid #DEDAF2;
        border-radius      : 24px;
        box-shadow         : 0px 2px 4px rgba(0, 0, 0, 0.08);
        z-index            : 99;

        .label {
            background-color: #DEDAF2;
            padding         : 6px 12px 6px 12px;
            border-radius   : 8px;
            display         : flex;
            justify-content : center;
            align-items     : center;
            width           : fit-content;
            height          : 36px;
        }
    }
}