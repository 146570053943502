.signup-form-container {
	// padding: 0px 50px 75px 50px;
	// max-width: 500px;
	padding-left : 50px;
	padding-right: 50px;

	.form {
		text-align: start;
		width     : 100%;

		// .heading{
		// font-weight: 500;
		// font-size: 32px;
		// line-height: 48px;
		// }

		.signup-input-group {
			.submit-button {
				margin-top: 36px;
				height    : 40px;
			}

			.error-text::first-letter {
				text-transform: uppercase;
			}
		}

		input {
			width: 100%;
		}

		.error-text {
			color           : $danger-500;
			// font-weight: 400;
			// font-size: 12px;
			// line-height: 18px;
			margin-top      : 8px;
			border          : 1px solid;
			width           : 100%;
			padding         : 8px;
			height          : 36px;
			background-color: #FFE1E1;
			font-size       : 12px !important;
			display         : flex;
			align-items     : center;
		}
	}
}

.link-text {
	// font-weight: 400;
	// font-size:16px;
	// line-height: 24px;
	margin-top: 8px;

	.link-to-login {
		font-weight: 600;
		color      : $primary-text;
	}
}

@media only screen and (max-width: 1440px) {
	.signup-form-container {
		// padding: 0px 50px 75px 50px;
		// max-width: 500px;
		padding-left : 0px;
		padding-right: 0px;


	}
}