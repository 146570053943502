.search-container {
    width           : 100%;
    height          : 44px;
    display         : flex;
    align-items     : center;
    justify-content : center;
    position        : relative;
    background      : #ffffff;
    // border       : 1px solid #DEDAF2;
    border-radius   : 32px;

    input {
        border    : none;
        background: transparent;
        width     : 100%;
        height    : 100%;
        padding   : 10px 15px;
        outline   : none;

        :focus-visible {
            border: none;
        }
    }

    .suggestion-box-container {
        width              : 100%;
        position           : absolute;
        top                : 40px;
        z-index            : 2;
        max-height         : 316px;
        overflow           : auto;
        padding            : 24px;
        background         : #ffffff;
        // border-width    : 0px 1px 1px 1px;
        // border-style    : solid;
        // border-color : #DEDAF2;
        box-shadow         : 0px 8px 24px 0px #959DA533;
        gap                : 4px;
        border-radius      : 24px;
        margin-top         : 12px;


        .searched-suggestion {
            height        : auto;
            display       : flex;
            align-items   : center;
            height        : 40px;
            // padding    : 16px;

        }

        .searched-suggestion:hover {
            cursor          : pointer;
            background-color: #ffffff;
        }
    }

    .highlight-searched-text {
        color           : $dark-100;
        font-weight     : 500;
        background-color: #fff;
    }
}