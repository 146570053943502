.default-assessment-card-container {
    width                       : 100%;
    height                      : calc(100vh - 260px);
    // display                  : flex;
    // flex-direction     : column;
    // align-items     : center;
    // justify-content       : center;
    background-color            : white;
    border-radius               : 24px;

    .default-assessment-text {
        width           : calc(100% - 48px);
        background-color: #F6F5F8;
        border-radius   : 24px;
        padding         : 18px 24px 18px 24px;
        margin          : auto;
    }


}

.view-for-default-assessment-card {
    padding-top: 98px;
    display    : flex;
    align-items: flex-start;
}

.view-for-assessment-table {
    padding-top: 55px;
    display    : flex;
    align-items: flex-start;
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {

    .view-for-default-assessment-card {
        padding-top: 83px;
    }
}

@media only screen and (max-width: 767px) {
    .view-for-default-assessment-card {
        padding-top: 64px;
    }

    .default-assessment-card-container {
        .default-assessment-illustration {
            width: 200px;
        }

        .default-notification-illustration {
            width : 312px;
            height: 260px;
        }
    }
}