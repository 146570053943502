// @import url(https://fonts.googleapis.com/css?family=Inter:300italic,700italic,400,300);
@import url('https://fonts.googleapis.com/css?family=Inter:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');

//********************Assets
@import './style-assets/colors';
@import './style-assets/typography';
@import './style-assets/misc';

//*********************Mixins
@import './mixins/typegraphyMixin';

//********************Views
@import './views/dashboard';
@import './views//dashboard/assessments/assessments';
@import './views//dashboard/assessments/qualifying-questions';
@import './views/dashboard/assessments/create-assessment';
@import './views/dashboard/assessments/welcomeVideo';
@import './views//dashboard/assessments/publish-assessment';
@import './views/candidate-assessment-flow/candidate-assess-flow';
@import './views//dashboard/assessments/publish-assessment';
@import './views/candidate-assessment-flow/tests/skill-based-test';
@import './views/dashboard/test-details/testDetails';
@import './views/dashboard/test-details/assessmentCriteriaModal';
@import './views/dashboard/test-details/assessmentCriteria';
@import './views//dashboard/assessments/case-study';
@import './views/dashboard/candidates/candidateDetails';
@import './views/errorPage';
@import './views/cookieDisclaimer';

//******************API Access Token
@import './views/api-access-token/apiAccessToken';
@import './views/api-access-token/apiAccessTokenCard';

//******************Preview Questions
@import './views/preview-questions/testpackPreviewQuestions';

//******************Public Result
@import './views/public-result/publicAssessmentResult';

//******************Cadidate Dashboard
@import './views/candidates/dashboard/dashboard';
@import './views/candidates/assessments/assessments';
@import './views/candidates/assessments/assessmentCard';
@import './views/candidates/assessments/candidateAssessmentRightSideMenu';
@import './views/candidates/assessments/declineAssessmentModal';
@import './views/candidates/settings/profileSettings';
@import './views/candidates/test-history/candidateTestHistory';
@import './views/candidates/test-history/testHistoryCard';

//******************Components
@import './components/onboarding/onboarding-form';
@import './components/onboarding/signup';
@import './components/onboarding/login';
@import './components/onboarding/subscription';
@import './components/dashboard/assessments/assessment-creation-header';
@import './components/dashboard/assessments/default-assessment-card.scss';
@import './components/dashboard/test-details/codingTestDetails.scss';
@import './components/dashboard/avtarBadge';
@import './views/candidate-assessment-flow/qualification-test';
@import './views/candidate-assessment-flow/welcom-video';
@import './views/candidate-assessment-flow/test-content-wrapper';
@import './views/candidates/assessments/case-study-cs';

@import './components/candidate-assessment-flow/test-question-types/mcqs-type-questions';
@import './components/candidate-assessment-flow/assessment-info-dialog-box';
@import './components/candidate-assessment-flow/on-test-start-card';
@import './components/candidate-assessment-flow/on-test-complete-card';
@import './components/candidate-assessment-flow/test-progress-header';
@import './components/dashboard/test-details/antiCheat';

//******************Dashboard Components
@import './components/dashboard/side-menu';
@import './components//dashboard/sideMenu-card/notification-card';
@import './components//dashboard/sideMenu-card/support-card';
@import './components//dashboard/sideMenu-card/profile-card';
@import './components/dashboard/settings/user-settings';
@import './components//dashboard/settings/company-settings';
@import './components/dashboard/settings/billing-settings/billing-settings';
@import './components/dashboard/settings/billing-settings/billing-settings-view-card-modal';
@import './components/dashboard/settings/team-settings/team-settings';
@import './components/dashboard/assessments/section-type';
@import './components/dashboard/assessments/assessment-result-card';
@import './components/dashboard/notifications/notifications';

//******************Common
@import './components/common/alert';
@import './components/common/modal';
@import './components/common/table';
@import './components/common/progressBar';

//*****************Sub-Components
@import './sub-components/button';
@import './sub-components/input';
@import './sub-components/textarea';
@import './sub-components//signup';
@import './sub-components/signin';
@import './sub-components/checkbox';
@import './sub-components/radio';
@import './sub-components/search';
@import './sub-components/navbar';
@import './sub-components/switch';

// @import "./sub-components/alert";

@import './sub-components//select';
@import './sub-components/registrationBackground';

//*************general classes
@import './generalClasses';

@import './mask-icons';



/* Default font size for small screens */
html {
    font-size: 16px;
}

@media screen and (max-width: 1349px) {
    html {
        font-size: 13px;
    }
}

/* Adjust font size for medium-sized screens */
@media screen and (min-width: 1350px) and (max-width: 1600px) {
    html {
        font-size: 14px;
    }
}

@media screen and (min-width: 1601px) {
    html {
        font-size: 16px;
    }
}

// /* Further adjustments for larger screens */
// @media screen and (min-width: 1200px) {
//     html {
//         font-size: 20px;
//     }
// }