@import "../../style-assets/typography";

.avtar-badge-container {
    position: relative;
    height  : 100;
    width   : min-content;

    .avtar-badge-status {
        position: absolute;
        z-index : 1;
        right   : 2px;
        bottom  : 2px;
    }
}

.css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar {
    width           : 24px !important;
    height          : 24px !important;
    font-family     : 'Roboto' !important;
    font-style      : normal !important;
    font-weight     : 400 !important;
    font-size       : 11px !important;
    line-height     : 20px !important;
    color           : #42526E !important;
    background-color: #DFE1E6 !important;
}

.css-1f0tmi6-MuiAvatar-root {
    background-color: #DFE1E6 !important;
}