.notification-msg {
	position: absolute;
	padding: 16px 0px;
	width: 400px;
	height: 836px;
	left: 155px;
	top: 24px;
	background: linear-gradient(0deg, #FFFFFF, #FFFFFF), rgba(0, 0, 0, 0.0001);
	box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
	border-radius: 6px;
	.notification-msg-header {
		align-items: center;
		margin-left: 20px;
		width: 368px;
		height: 22px;
		.body-1{
			color: #5E5873;
		}
		.badge {
			padding: 4px 12px;
			gap: 3px;
			width: 57px;
			height: 20px;
			margin-left: 15px;
			margin-right: 78px;
			background: rgba(115, 103, 240, 0.12);
			color: #6F2DBD;;
			border-radius: 17px;
		}
		.description {
			color: #6F2DBD;
		}
	}
	.notification-messages {
		width: 400px;
		height: auto;
		.notification-message-single {
			gap: 10px;
			margin-left: 20px;
			.notification-message-avatar {
				position: relative;
				.badge{
					box-sizing: border-box;
					position: absolute;
					left: -12.5%;
					right: 68.75%;
					top: -12.5%;
					bottom: 68.75%;
					background: #6F2DBD;
					border: 2px solid #FFFFFF;
					border-radius: 10px;
				}
			}
			.notification-message-text {
				align-items: flex-start;
				gap: 10px;
			}
			.notification-message-time{
				gap: 10px;
				margin-left: 20px;
			}
		}
	}
}