.MuiBox-root {
    display        : flex;
    align-items    : flex-start;
    justify-content: center;
    width          : 100%;

    .decline-assessment-modal-container {
        width        : 736px;
        background   : #FFFFFF;
        border       : 0.6px solid #C8CBCD;
        box-shadow   : 6px 15px 30px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        padding      : 32px;

        .detail {
            color        : #2B3340;
            margin-bottom: 30px;
            gap          : 10px;

            .hr {
                width           : 100%;
                height          : 1px;
                border-width    : 0;
                color           : #C8CBCD;
                background-color: #C8CBCD;
            }
        }

        .btn {
            border: none;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
    .MuiBox-root {
        width: 100%;

        .decline-assessment-modal-container {
            width  : 594px;
            padding: 24px;
        }
    }
}

@media screen and (max-width: 767px) {
    .MuiBox-root {
        width: 100%;

        .decline-assessment-modal-container {
            width  : 327px;
            padding: 32px 16px;

            .btn {
                width          : 100%;
                align-items    : center;
                justify-content: center;
            }
        }
    }
}