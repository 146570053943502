@import "../../../style-assets/typography";

.coding-test-response-container {
    display          : flex;
    flex-direction   : column;
    // padding       : 24px;
    width            : 100%;
    border-radius    : 24px;
    background-color : #ffffff;
    padding          : 16px 18px 16px 18px;
    border           : 1px solid #ECEDF1;

    .coding-test-question-container {
        width           : 100%;
        border-radius   : 24px;
        background-color: #F6F5F8;
        padding         : 16px 18px 16px 18px;

        .ql-editor {
            padding : 0px !important;
            overflow: unset !important;
            @extend .body-1;
            color: #3E454D !important;
        }
    }

    .coding-test-timeline-container {
        width         : 100%;
        display       : flex;
        flex-direction: column;
        gap           : 12px;

        .coding-test-details-timeline-container {

            width         : 100%;
            height        : 100%;
            display       : flex;
            flex-direction: column;
            align-items   : flex-start;

            .coding-test-timeline-details {
                // padding       : 24px;
                display          : flex;
                flex-direction   : column;
                // border        : 1px solid #DEDAF2;
                border-radius    : 8px;
                width            : 100%;
                height           : min-content;

                .coding-test-timeline-details-timmer {
                    padding   : 0px 14px;
                    margin-top: 24px;
                    width     : 77%;
                    align-self: center;
                }
            }
        }

        .coding-result-event-container {
            width         : 100%;
            height        : 100%;
            display       : flex;
            flex-direction: column;
            align-items   : flex-start;
            border        : 1px solid #DEDAF2;
            border-radius : 8px;
            padding       : 24px;

            button {
                display           : flex;
                flex-direction    : row;
                align-items       : center;
                justify-content   : center;
                // width          : 47px;
                height            : 24px;
                background        : #EAEDEE;
                border-radius     : 4px;
                outline           : none;
                box-shadow        : none;
                border            : none;
                text-align        : center;
                padding           : 0px 8px;

            }

            .executed-tasks-container {
                width     : 100%;
                margin-top: 24px;

                .executed-task-expanded {
                    background-color: #282a36;
                    padding         : 10px;
                }

                .executed-task {
                    width          : 100%;
                    display        : flex;
                    justify-content: space-between;
                    align-items    : center;
                    height         : 40px;
                    padding        : 0px 23.5px;
                    background     : #EAEDEE;
                    border-bottom  : 1px solid #D9D9D9;



                    .OK,
                    .KO {
                        width          : 32px;
                        height         : 24px;
                        display        : flex;
                        align-items    : center;
                        justify-content: center;

                        border-radius: 4px;
                    }

                    .OK {
                        background: #00D69A;
                    }

                    .KO {
                        background: #FC4848;
                    }
                }
            }
        }
    }
}

.ct-exec-result {
    .cm-activeLine {
        // background: transparent !important;
    }

    .cm-zebra-stripe {
        background-color: #882727 !important;
    }
}

.coding-test-timeline-player {
    padding-top: 44px;
}

.coding-test-timeline-player {
    width   : 100%;
    position: relative;

    .timeline-label {
        position       : absolute;
        z-index        : 1;
        bottom         : 50px;
        width          : 6px;
        height         : 7px;
        display        : flex;
        flex-direction : column;
        align-items    : flex-start;
        justify-content: flex-start;

        .visited-label {
            circle {
                fill: #6F2DBD !important
            }
        }

        .unvisited-label {
            circle {
                fill: white !important
            }
        }
    }

    .input-range__label--max,
    .input-range__label--min,
    .input-range__label-container {
        display: none !important;
    }

    .input-range__track--background {
        height    : 12px !important;
        background: #EAEDEE !important;
    }

    .input-range__track--active {
        height    : 12px !important;
        background: #6F2DBD !important;
    }

    .input-range__slider {
        height          : 34px;
        width           : 34px;
        margin-top      : -1.45rem !important;
        background-color: #6F2DBD !important;
        border-color    : #6F2DBD !important;
        z-index         : 2
    }

    .body-5-white-color-500 {
        @extend .body-5;
        color          : white !important;
        font-weight    : 500;
        display        : flex;
        align-items    : center;
        justify-content: center;

    }
}