.api-access-token-main-container {
    width  : 100%;
    display: flex;

    .api-access-token-container {
        width        : 100%;
        height       : calc(100vh - 140px);
        display      : flex;
        align-items  : flex-start;
        padding      : 24px;
        border-radius: 24px;
        background   : white;
        overflow-y   : auto;
        position     : relative;
    }
}

@media screen and (max-width: 767px) {
    .api-access-token-main-container {
        .api-access-token-container {
            height: calc(100vh - 170px);
        }
    }
}