.assessment-custom-card-container {
	width         : 100%;
	display       : flex;
	flex-direction: column;
	align-items   : flex-start;
	gap           : 24px;

	.right-sidebar-header-container {
		width         : 100%;
		display       : flex;
		flex-direction: column;
		align-items   : flex-start;
		gap           : 24px;

		.right-sidebar-header-pagination {
			width          : 100%;
			display        : flex;
			flex-direction : row;
			align-items    : center;
			justify-content: space-between;
			gap            : 24px;
		}

		.right-sidebar-header-top {
			width          : 100%;
			display        : flex;
			flex-direction : row;
			align-items    : center;
			justify-content: space-between;
			gap            : 12px;
		}

		.already-completed-message {
			display         : flex;
			align-items     : center;
			text-align      : left;
			background-color: #F6F5F8;
			padding         : 24px;
			border-radius   : 24px;
			gap             : 12px;
			margin-top      : 12px;
		}
	}

	.assessment-custom-card-result-container {
		width            : 100%;
		display          : flex;
		flex-direction   : column;
		align-items      : flex-start;
		padding          : 0px 0px 24px;
		// border-radius : 24px;
		// border        : 1px solid #ECEDF1;
		gap              : 24px;
	}

	.candidate-assessment-results {
		width         : 100%;
		display       : flex;
		flex-direction: column;
		align-items   : flex-start;
		gap           : 24px;

		.decline-tag-card {
			width         : 100%;
			padding       : 12px 18px;
			display       : flex;
			flex-direction: row;
			align-items   : center;
			background    : #F6F5F8;
			border-radius : 24px;
			gap           : 8px;
		}

		.test-card-container {
			width         : 100%;
			display       : flex;
			flex-direction: column;
			align-items   : flex-start;
			gap           : 16px;

			.test-card {
				width          : 100%;
				display        : flex;
				flex-direction : row;
				align-items    : center;
				justify-content: space-between;
				background     : #F6F5F8;
				padding        : 12px 18px;
				border-radius  : 24px;
				gap            : 24px;
			}
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 1439px) {
	.assessment-custom-card-container {
		.assessment-custom-card-result-container {
			padding: 16px;
		}
	}
}

@media screen and (max-width: 767px) {
	.assessment-custom-card-container {
		.right-sidebar-header-container {
			.right-sidebar-header-top {
				flex-direction: column;
				align-items   : flex-start;
			}
		}

		.assessment-custom-card-result-container {
			padding: 16px;
		}

		.candidate-assessment-results {
			.test-card-container {
				.test-card {
					flex-direction: column;
					align-items   : flex-start;
					gap           : 8px;
				}
			}
		}
	}
}