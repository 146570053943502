.public-result-main-container {
    width           : 100%;
    height          : max-content;
    min-height      : 100vh;
    display         : flex;
    align-items     : flex-start;
    justify-content : center;
    background-color: #F5F5F5;
    padding         : 24px;

    .default-public-result-alert {
        width           : 100%;
        display         : flex;
        align-items     : center;
        justify-content : center;
        background-color: #FFFFFF;
        border-radius   : 24px;
        padding         : 18px 24px 18px 24px;
    }

    .default-public-result-card-container {
        width           : 100%;
        height          : calc(100vh - 260px);
        display         : flex;
        background-color: white;
        border-radius   : 24px;

        .default-public-result-alert {
            width           : calc(100% - 48px);
            background-color: #F6F5F8;
            border-radius   : 24px;
            padding         : 18px 24px 18px 24px;
            margin          : auto;
        }
    }

    .public-result-container-expended {
        max-width: 1280px;
    }

    .public-result-container-not-expended {
        max-width : 624px;
        transition: max-width 0.3s;
    }

    .public-result-container {
        width          : 100%;
        display        : flex;
        flex-direction : column;
        align-items    : center;
        justify-content: center;
        gap            : 24px;

        .public-result-header {
            width          : 100%;
            display        : flex;
            flex-direction : column;
            align-items    : flex-start;
            justify-content: center;
            background     : #FFF;
            padding        : 16px 24px;
            border-radius  : 24px;
            gap            : 16px;
        }

        .public-result-bottom-container {
            width          : 100%;
            display        : flex;
            flex-direction : row;
            align-items    : flex-start;
            justify-content: center;
            gap            : 24px;

            .public-result-view-container {
                width        : 100%;
                max-width    : 100%;
                height       : 100%;
                background   : #FFFFFF;
                padding      : 24px;
                border-radius: 24px;
            }

            .public-result-view-expanded {
                max-width : calc(50% - 12px);
                transition: max-width 0.25s ease-in-out;
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .public-result-main-container {
        .public-result-container-expended {
            max-width: 100%;
        }

        .public-result-container-not-expended {
            max-width: 100%;
        }

        .public-result-container {
            .public-result-bottom-container {
                .public-result-view-expanded {
                    max-width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .public-result-main-container {
        padding: 12px;

        .public-result-container-expended {
            max-width: 100%;
        }

        .public-result-container-not-expended {
            max-width: 100%;
        }

        .public-result-container {
            .public-result-bottom-container {
                .public-result-view-container {
                    padding: 0px;
                }

                .public-result-view-expanded {
                    max-width: 100%;
                }
            }
        }
    }
}