%tag {
    align-items  : center;
    padding      : 0px 16px 0px 16px;
    width        : fit-content;
    border-radius: 3px;
}

.success-tag {
    @extend %tag;
    // color           : #7C21FF;
    background-color: #7C21FF;
    width           : 110px;
    padding         : 6px 24px 6px 24px;
    border-radius   : 24px;
    color           : white;
    text-align      : center;
}

.pending-tag {
    @extend %tag;
    background-color: #FFF8E0;
    color           : #F4C20D;
    width           : 110px;
    padding         : 6px 24px 6px 24px;
    border-radius   : 24px;
    text-align      : center;
}

.fail-tag {
    @extend %tag;
    background-color: #FFE1E1;
    color           : #FC4848;
    width           : 110px;
    padding         : 6px 24px 6px 24px;
    border-radius   : 24px;
    text-align      : center;
}

.draft-tag {
    @extend %tag;
    background-color: #DEDAF2;
    color           : #6F2DBD;
    width           : 110px;
    padding         : 6px 24px 6px 24px;
    border-radius   : 24px;
    text-align      : center;
}

.billing-settings {
    width         : 100%;
    align-items   : flex-start;
    display       : flex;
    flex-direction: column;
    gap           : 24px;

    .billing-settings-detail {
        width         : 100%;
        display       : flex;
        flex-direction: row;
        gap           : 32px;

        .payment-head {
            justify-content      : space-between;
            // padding-bottom    : 16px;
            // border-bottom  : 1px solid #DEDAF2;

            .trialing-message {
                width           : 100%;
                padding         : 12px;
                background-color: $dark-20;
                display         : flex;
                align-items     : center;
                text-align      : left;
                margin-top      : 12px;
                gap             : 12px;
                border-radius   : 24px;
            }
        }

        .billing-settings-plan {
            width             : 50%;
            min-width         : 480px;
            min-height        : 293px;
            // height         : 293px;
            max-height        : max-content;
            padding           : 24px;
            background        : #FFFFFF;
            text-align        : justify;
            text-justify      : inter-word;
            display           : flex;
            flex-direction    : column;
            justify-content   : space-between;
            box-sizing        : border-box;
            // border: 1px solid #DEDAF2;
            border-radius     : 24px;
            gap               : 24px;

            .plan-detail-container {
                width          : 100%;
                display        : flex;
                flex-direction : row;
                align-items    : flex-start;
                justify-content: space-between;
                gap            : 24px;

                .plan-detail-inner-container1 {
                    width         : 55%;
                    display       : flex;
                    flex-direction: column;
                    gap           : 16px;
                }

                .plan-detail-inner-container2 {
                    width         : 45%;
                    display       : flex;
                    flex-direction: column;
                    gap           : 16px;
                }

                .plan-detail-text {
                    width         : 100%;
                    display       : flex;
                    flex-direction: row;
                    gap           : 12px;

                    // span {
                    //     font-size: 1.25em;
                    // }
                }

                // @media only screen and (min-width: 1440px) and (max-width: 1650px) {
                //     .plan-detail-text {
                //         // flex-direction: column;
                //         gap: 0px;
                //     }
                // }
            }

            .plan-without-detail-container {
                width          : 100%;
                height         : 160px;
                padding        : 10px 24px 10px 10px;
                display        : flex;
                flex-direction : row;
                align-items    : center;
                justify-content: center;
                border         : 2px solid #EBE9F1;
                border-radius  : 6px;
                gap            : 16px;
            }
        }

        .billing-settings-payment {
            width            : 50%;
            min-width        : 480px;
            min-height       : 350px;
            padding          : 24px;
            background       : #FFFFFF;
            text-align       : justify;
            text-justify     : inter-word;
            display          : flex;
            flex-direction   : column;
            box-sizing       : border-box;
            // border        : 1px solid #DEDAF2;
            gap              : 16px;
            position         : relative;
            border-radius    : 24px;

            .add-card-button {
                position: absolute;
                bottom  : 24px;
            }

            .payment-card-detail {
                width              : 100%;
                height             : 52px;
                padding            : 10px 24px 10px 10px;
                display            : flex;
                flex-direction     : row;
                align-items        : center;
                justify-content    : space-between;
                // border          : 1px solid #EBE9F1;
                border-radius      : 24px;
                gap                : 16px;
                background-color   : $dark-20;

                .payment-card-option {
                    height        : 32px;
                    display       : flex;
                    flex-direction: row;
                    align-items   : center;
                    gap           : 10px;

                    .payment-card-compny {
                        display       : flex;
                        flex-direction: row;
                        align-items   : center;
                        gap           : 8px;

                        .payment-card-owner-detail {
                            display       : flex;
                            flex-direction: column;
                        }
                    }
                }

            }

            .description {
                width : max-content;
                cursor: pointer;
                color : #6F2DBD;
            }
        }
    }

    .retry-payment {
        width          : 100%;
        display        : flex;
        flex-direction : row;
        align-items    : center;
        justify-content: flex-end;
        margin-bottom  : -50px;
    }

    .table-basic {
        width         : 100%;
        display       : flex;
        flex-direction: column;
        align-items   : flex-start;
    }
}

.add-card-modal {
    width         : 100%;
    display       : flex;
    flex-direction: column;
    padding-top   : 24px;
    gap           : 24px;

    .card-number {
        width: 100%;
        gap  : 8px;

        .stripe-input-field-full {
            display         : flex;
            height          : 40px;
            // border       : 0.6px solid #56585A;
            border-radius   : 24px;
            background-color: $dark-20;

            .__PrivateStripeElement {
                width: 100%;
            }
        }
    }

    .card-detail {
        width         : 100%;
        display       : flex;
        flex-direction: row;
        gap           : 24px;

        .card-name {
            width: 50%;
            gap  : 8px;
        }

        .card-date-cvv {
            width: 50%;
            gap  : 24px;

            .stripe-input-field-full {
                display         : flex;
                height          : 40px;
                // border       : 0.6px solid #56585A;
                border-radius   : 24px;
                background-color: $dark-20;

                .__PrivateStripeElement {
                    width: 100%;
                }
            }

            .card-date {
                width: 50%;
                gap  : 8px;
            }

            .card-cvv {
                width: 50%;
                gap  : 8px;
            }
        }
    }

    .card-switch {
        height     : 25px;
        gap        : 8px;
        align-items: flex-start;
    }

    .danger-text {
        color: $danger;
    }
}

.plans-modal {
    .payment-plan-card {
        width: inherit;
    }

    .payment-container {
        padding: 0px 0px 32px 0px;
    }
}

.w-1000 {
    width  : 97% !important;
    padding: 24px !important;
}

.card-table {
    table {
        td:first-child {
            width: 80%;
        }
    }
}

@media screen and (max-width: 1290px) {
    .billing-settings-plan {
        .plan-detail-text span {
            font-size: 1em !important;
        }
    }

}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .billing-settings {
        .billing-settings-detail {
            flex-direction: column;

            .billing-settings-plan {
                width    : 100%;
                min-width: 100%;

                .plan-detail-text span {
                    font-size: 1.25em !important;
                }
            }

            .billing-settings-payment {
                width    : 100%;
                min-width: 100%;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .billing-settings {
        .billing-settings-detail {
            flex-direction: column;

            .billing-settings-plan {
                width    : 100%;
                min-width: 100%;
                padding  : 24px 24px;

                .plan-detail-container {
                    flex-direction: column;
                    gap           : 16px;

                    .plan-detail-inner-container1 {
                        width: 100%;
                    }

                    .plan-detail-inner-container2 {
                        width: 100%;
                    }
                }
            }

            .billing-settings-payment {
                width    : 100%;
                min-width: 100%;
                padding  : 24px 24px;

                .payment-card-detail {
                    padding: 10px 10px 10px 10px;
                    gap    : 12px;
                }
            }
        }
    }

    .add-card-modal {
        .card-detail {
            flex-direction: column;

            .card-name {
                width: 100%;
            }

            .card-date-cvv {
                width: 100%;
            }
        }
    }
}