.notification-view-container {
	width           : 100%;
	height          : calc(99vh - 130px);
	display         : flex;
	flex-direction  : column;
	text-align      : left;
	background-color: white;
	padding         : 24px;
	border-radius   : 24px;
	gap             : 16px;
	overflow-y      : auto;
	

	.notification-header {
		width             : 100%;
		height            : 48px;
		box-sizing        : border-box;
		display           : flex;
		flex-direction    : column;
		justify-content   : space-between;
		align-items       : flex-start;
		padding           : 0px 32px;
		// border-bottom  : 1px solid #DEDAF2;
	}

	.default-notification-alert {
		width           : 100%;
		display         : flex;
		align-items     : center;
		justify-content : center;
		background-color: #F6F5F8;
		border-radius   : 24px;
		padding         : 18px 24px 18px 24px;
	}

	.notification-message-card {
		width           : auto;
		height          : auto;
		box-sizing      : border-box;
		display         : flex;
		flex-direction  : column;
		justify-content : space-between;
		align-items     : flex-start;
		border-radius   : 24px;
		background-color: $dark-20;
		padding         : 12px 24px 12px 24px;

		.dot-icon {
			position: relative;
			bottom  : 8px;
		}

		.profile-icon {
			height          : 32px;
			width           : 32px;
			border-radius   : 50%;
			background-color: #121216;
			color           : #FFF;
			display         : flex;
			justify-content : center;
			align-items     : center;
			text-transform  : uppercase;
		}
	}
}

@media screen and (max-width: 767px) {
	.notification-view-container {
		width  : 100%;
		height          : calc(100vh - 185px);
		display: flex;

		.notification-message-card {
			height: auto;
			margin: 0px;
		}
	}
}