@import "../../../mixins/typegraphyMixin";

.assessmnet-creation-header-box {
    background-color: $white;
    border-radius   : 24px;
    padding         : 0px 24px 24px 24px;
}

.assessment-creation-header {
    width                : 100%;
    // min-height     : 92px;
    display              : flex;
    // height            : 120px;
    align-items          : center;
    justify-content      : space-between;
    position             : relative;
    padding              : 24px;
    border-radius        : 24px;
    background           : $dark-20;
    margin-top           : 24px;

    svg#upload-icon circle,
    svg#upload-icon path {
        display: none;
    }

    .assessment-name-truncate {
        white-space  : nowrap;
        overflow     : hidden;
        text-overflow: ellipsis;
        max-width    : 200px !important;
        border       : 1px solid;
        display      : block;
    }

    .assessment-name-container {
        width            : 100%;
        // height        : 72px;
        display          : flex;
        align-items      : flex-start;
        flex-direction   : column;
        gap              : 18px;

        svg {
            cursor: pointer;
        }

        .assessment-header-back-btn {
            margin-top: 8px;
        }

        .assessment-upload-icon {
            // margin-left: 28px;
        }

        .assessment-name-edit-section {
            width          : 100%;
            display        : flex;
            flex-direction : row;
            align-items    : center;
            justify-content: space-between;

            .assessment-date-container {
                display       : flex;
                flex-direction: row;
                gap           : 24px;
            }



            .assessment-title-edit-icon-box {
                height          : 30px;
                width           : 30px;
                // margin-left  : 14px;
                transition      : 0.05s;
                border-radius   : 50%;
                display         : flex;
                justify-content : center;
                align-items     : center;

                &:hover {
                    background-color: $black-200;
                }
            }

            .assessment-creation-setps-dots-icon {
                width : 24px;
                height: 24px;
            }

            // &.active{
            //     .assessment-creation-setps-dots-icon {
            //         background-color: $base-color;
            //         border: 1px solid transparent;
            //         color: #fff;
            //     }

            //     .assessment-creation-setps-dots-icon {
            //         color: $base-color;
            //     }
            // }
            .assessment-creation-setps-dots-text {
                width            : 157px;
                height           : 34px;
                margin-left      : 10px;
                // color         : #3E454D;
                display          : flex;
                flex-direction   : column;
                align-items      : flex-start;
            }
        }
    }

    //     .assessment-creation-setps-container {
    //         width          : 100%;
    //         height         : 36px;
    //         display        : flex;
    //         flex-direction : column;
    //         align-items    : center;
    //         justify-content: space-between;
    //         position       : absolute;
    //         bottom         : 0px;

    //         .assessment-creation-setps {
    //             max-width      : 520px;
    //             width          : 100%;
    //             height         : 100%;
    //             display        : flex;
    //             flex-direction : column;
    //             align-items    : center;
    //             justify-content: space-between;
    //             position       : relative;


    //             .assessment-creation-setps-dots {
    //                 display        : flex;
    //                 width          : 100%;
    //                 justify-content: space-around;
    //                 position       : absolute;
    //             }

    //             .progress-bar-container {
    //                 width           : 390px;
    //                 height          : 9px;
    //                 background-color: transparent;
    //                 border          : none;
    //                 border-radius   : 8px !important;
    //                 display         : flex;
    //                 justify-content : space-between;

    //                 .progress-bar {
    //                     background   : #6F2DBD;
    //                     border-radius: 8px !important;
    //                 }

    //             }

    //             .assessment-creation-setps-label {
    //                 width          : 100%;
    //                 display        : flex;
    //                 justify-content: space-around;
    //             }
    //         }
    //     }
}

.assessment-name-truncate {
    white-space  : nowrap;
    overflow     : hidden;
    text-overflow: ellipsis;
    max-width    : 230px;
    display      : block;
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
    .assessment-creation-header {
        min-height: auto;

        .assessment-name-container {
            .assessment-name-edit-section {
                @media only screen and (min-width: 768px) and (max-width: 800px) {
                    .assessment-date-container {
                        flex-direction: column;
                        gap           : 6px;
                    }
                }
            }
        }

        .assessment-creation-setps-container {
            // position  : unset;
            // margin-top: 24px;
            width : 629px;
            margin: 50px 0px;

            .assessment-creation-setps-dots {
                .assessment-creation-setps-dots-icon {}

                .assessment-creation-setps-dots-text {}
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .assessment-creation-header {
        height         : max-content;
        flex-direction : row;
        justify-content: flex-start;
        align-items    : flex-start;
        margin-bottom  : 12px;

        .assessment-name-container {
            .assessment-upload-icon {
                position: absolute;
                left    : 69.93%;
                right   : 2.87%;
                top     : 0%;
                bottom  : 46.67%;
            }

            .assessment-name-edit-section {
                flex-direction: column !important;
                align-items   : flex-start;


                .assessment-date-container {
                    flex-direction: column;
                    gap           : 6px;
                }

                .assessment-name-truncate {
                    white-space  : nowrap;
                    overflow     : hidden;
                    text-overflow: ellipsis;
                    max-width    : 200px !important;
                    display      : block;
                }
            }

            .assessment-edit-and-saved-section {
                margin-top: -30px;
            }

            .assessment-start-time {
                margin-left: 30px;
                margin-top : 10px;
            }
        }

        .assessment-creation-setps-container {
            width      : 327px;
            margin-top : 50px;
            margin-left: 0px;

            .assessment-creation-setps-dots {
                height        : 63px;
                flex-direction: column;
                align-items   : center;

                .assessment-creation-setps-dots-text {
                    width         : 109px;
                    height        : 31px;
                    margin-left   : 0px;
                    flex-direction: column;
                    align-items   : center;

                    .body-4 {
                        @include typography('Roboto', 400, 10px, 12px, 0.005px, #10182B);
                    }
                }
            }
        }
    }


    // .assessment-creation-header {
    //     height: max-content;

    //     .assessment-creation-setps-container {
    //         position  : unset;
    //         margin-top: 24px;

    //         .assessment-creation-setps {
    //             max-width: 327px;

    //             .progress-bar-container {
    //                 max-width: 220px
    //             }

    //             .assessment-creation-setps-label {
    //                 span {
    //                     @include typography('Roboto', 400, 10px, 12px, 0.005px, #10182B);
    //                 }
    //             }
    //         }

    //     }
    // }
}

.assessment-icon-container {
    display       : flex;
    padding       : 24px 24px 48px 24px;
    flex-direction: column;
    align-items   : flex-end;
    gap           : 24px;
    border-radius : 6px;
    border        : 0.6px solid var(--Primary-Light, #DEDAF2);
    background    : var(--Primary-White, #FFF);
    /* Card Shadow */
    box-shadow    : 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
    width         : 626px;
    height        : 530px;
}

@media only screen and (max-width: 1440px) {
    .icon-modal {
        left: 46px !important;
        top : 47px !important;
    }

    .assessment-icon-container {
        display       : flex;
        padding       : 24px 24px 48px 24px;
        flex-direction: column;
        align-items   : flex-end;
        gap           : 24px;
        border-radius : 6px;
        border        : 0.6px solid var(--Primary-Light, #DEDAF2);
        background    : var(--Primary-White, #FFF);
        /* Card Shadow */
        box-shadow    : 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
        width         : 500px;
        height        : 530px;
    }

}

@media only screen and (max-width: 1200px) {
    .icon-container {
        column-gap: 32px !important;
    }

}

@media only screen and (max-width: 900px) {
    .assessment-icon-container {
        display       : flex;
        padding       : 24px 24px 48px 24px;
        flex-direction: column;
        align-items   : flex-end;
        gap           : 24px;
        border-radius : 6px;
        border        : 0.6px solid var(--Primary-Light, #DEDAF2);
        background    : var(--Primary-White, #FFF);
        /* Card Shadow */
        box-shadow    : 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
        width         : 500px;
        height        : 530px;
    }

}

@media only screen and (max-width: 767px) {
    .icon-container {
        column-gap: 28px !important;
    }

}

@media only screen and (max-width: 620px) {
    // .icon-modal {
    //     left: 20px !important;
    // }

    .assessment-icon-container {
        display       : flex;
        padding       : 24px 24px 48px 24px;
        flex-direction: column;
        align-items   : flex-end;
        gap           : 24px;
        border-radius : 6px;
        border        : 0.6px solid var(--Primary-Light, #DEDAF2);
        background    : var(--Primary-White, #FFF);
        /* Card Shadow */
        box-shadow    : 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
        width         : 500px;
        height        : 530px;
    }

}

@media only screen and (max-width: 550px) {
    // .icon-modal {
    //     left: 0px !important;
    // }

    .assessment-icon-container {
        display       : flex;
        padding       : 24px 24px 48px 24px;
        flex-direction: column;
        align-items   : flex-end;
        gap           : 24px;
        border-radius : 6px;
        border        : 0.6px solid var(--Primary-Light, #DEDAF2);
        background    : var(--Primary-White, #FFF);
        /* Card Shadow */
        box-shadow    : 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
        width         : 450px;
        height        : 530px;
    }

}

@media only screen and (max-width: 520px) {


    .assessment-icon-container {
        display       : flex;
        padding       : 24px 24px 48px 24px;
        flex-direction: column;
        align-items   : flex-end;
        gap           : 24px;
        border-radius : 6px;
        border        : 0.6px solid var(--Primary-Light, #DEDAF2);
        background    : var(--Primary-White, #FFF);
        /* Card Shadow */
        box-shadow    : 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
        width         : 420px;
        height        : 530px;
    }

}

@media only screen and (max-width: 500px) {


    .assessment-icon-container {
        display       : flex;
        padding       : 24px 24px 48px 24px;
        flex-direction: column;
        align-items   : flex-end;
        gap           : 24px;
        border-radius : 6px;
        border        : 0.6px solid var(--Primary-Light, #DEDAF2);
        background    : var(--Primary-White, #FFF);
        /* Card Shadow */
        box-shadow    : 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
        width         : 400px;
        height        : 530px;
    }

}

@media only screen and (max-width: 470px) {


    .assessment-icon-container {
        display       : flex;
        padding       : 24px 24px 48px 24px;
        flex-direction: column;
        align-items   : flex-end;
        gap           : 24px;
        border-radius : 6px;
        border        : 0.6px solid var(--Primary-Light, #DEDAF2);
        background    : var(--Primary-White, #FFF);
        /* Card Shadow */
        box-shadow    : 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
        width         : 380px;
        height        : 530px;
    }

}

@media only screen and (max-width: 460px) {


    .assessment-icon-container {
        display       : flex;
        padding       : 24px 24px 48px 24px;
        flex-direction: column;
        align-items   : flex-end;
        gap           : 24px;
        border-radius : 6px;
        border        : 0.6px solid var(--Primary-Light, #DEDAF2);
        background    : var(--Primary-White, #FFF);
        /* Card Shadow */
        box-shadow    : 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
        width         : 350px;
        height        : 530px;

        .search-container {
            width: 45% !important;
        }

        .mbl-btn {
            width: 50% !important;

            .btn-lg {
                padding: 13px 20px !important;

            }

        }

    }

}

@media only screen and (max-width: 425px) {

    .icon-modal {
        left: 0px !important;
        top : 55px !important;
    }

    .assessment-icon-container {
        display       : flex;
        padding       : 24px 24px 48px 24px;
        flex-direction: column;
        align-items   : flex-end;
        gap           : 24px;
        border-radius : 6px;
        border        : 0.6px solid var(--Primary-Light, #DEDAF2);
        background    : var(--Primary-White, #FFF);
        /* Card Shadow */
        box-shadow    : 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
        width         : 350px;
        height        : 530px;



    }

}

@media only screen and (max-width: 380px) {
    .icon-modal {
        left: 0px !important;
    }


    .assessment-icon-container {
        display       : flex;
        padding       : 24px 24px 48px 24px;
        flex-direction: column;
        align-items   : flex-end;
        gap           : 24px;
        border-radius : 6px;
        border        : 0.6px solid var(--Primary-Light, #DEDAF2);
        background    : var(--Primary-White, #FFF);
        /* Card Shadow */
        box-shadow    : 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
        width         : 330px;
        height        : 530px;


    }

}