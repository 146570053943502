.signup_info {
	padding: 50px;

	p {
		text-align: initial;
	}

	h1 {
		text-align: initial;
		font-size: 3em;
	}
}

.signup_container {
	.main-row {
		margin-left: 0px;
		margin-right: 0px;
		width: 100%;
		min-height: 100vh;
	}

	.left_box {
		background: white;
		border-top-right-radius: 30px;
		border-bottom-right-radius: 30px;
		text-align: initial;

		.links {
			padding-left: 50px;
			padding-bottom: 10px;
		}

		.content_wrapper {
			height: 100%;
		}
	}

	.right_box {
		padding-left: 0px;
		padding-right: 0px;
		text-align: initial;

		.links {
			padding-left: 50px;
			padding-bottom: 10px;
		}

		.content_wrapper {
			height: 100%;
		}

		.background {
			position: absolute;
			left: -10%;
			z-index: -1;
			background-color: lavender;
			height: 100%;
			width: 110%;
			background-image: url("../../images/dashboard/Candidate\ Detail.png");
		}
	}

	.helper_link {
		padding-right: 45px;
		color: #2b3340;
	}
}

.SignUpFormGroup {
	display: flex;
	flex-direction: column;

	input[type="text"] {
		border-top: 0px;
		border-left: 0px;
		border-right: 0px;
		border-bottom: 1px solid #96999c;
		background: none;
		transition: border-color 0.3s linear;
		margin-bottom: 20px;
		padding-bottom: 5px;
	}

	input[type="text"]:focus {
		border-top: 0px;
		border-left: 0px;
		border-right: 0px;
		border-bottom: 1px solid #00b4d8;
		outline: none;
		background: none;
	}

	input[type="password"] {
		border-top: 0px;
		border-left: 0px;
		border-right: 0px;
		border-bottom: 1px solid #96999c;
		background: none;
		transition: border-color 0.3s linear;
		margin-bottom: 20px;
		padding-bottom: 5px;
	}

	input[type="password"]:focus {
		border-top: 0px;
		border-left: 0px;
		border-right: 0px;
		border-bottom: 1px solid #00b4d8;
		outline: none;
		background: none;
	}

	input[type="button"] {
		border: 0px;
		background: #00b4d8;
		color: white;
		border-radius: 50px;
		padding-top: 9.5px;
		padding-bottom: 9.5px;
		outline: none;
	}
	input[type="button"]:hover {
		border: 0px;
		background: #32c3e0;
		color: white;
		border-radius: 50px;
		padding-top: 9.5px;
		padding-bottom: 9.5px;
	}
}
