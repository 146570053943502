@import "../../../style-assets/typography";

.ss-video-player-container {
    display       : flex;
    flex-direction: column;
    gap           : 24px;

    .ss-video-image-container {
        position     : relative;
        height       : 341px;
        width        : 100%;
        border-radius: 6px 6px 0px 0px;

        img {
            border-radius: 6px 6px 0px 0px;
        }

        .timmer-view {
            width          : 110px;
            height         : 34px;
            display        : flex;
            align-items    : center;
            justify-content: center;
            background     : #DEDAF2;
            border-radius  : 4px;
            gap            : 10px;
            position       : absolute;
            top            : 16px;
            right          : 6px;
        }
    }

    .ss-player-timeline {
        height: 93px;
        width : 100%;
    }
}

.ss-player-timeline {
    position: relative;

    .timeline-label {
        position       : absolute;
        z-index        : 1;
        top            : 7.5px;
        width          : 6px;
        height         : 7px;
        display        : flex;
        flex-direction : column;
        align-items    : flex-start;
        justify-content: flex-start;

        .visited-label {
            circle {
                fill: #6F2DBD !important
            }
        }

        .unvisited-label {
            circle {
                fill: white !important
            }
        }
    }

    .input-range__label--max,
    .input-range__label--min,
    .input-range__label-container {
        display: none !important;
    }

    .input-range__track--background {
        height    : 9.9px !important;
        background: #EAEDEE !important;
    }

    .input-range__track--active {
        height    : 9.9px !important;
        background: #6F2DBD !important;
    }

    .input-range__slider {
        height          : 24px;
        width           : 24px;
        margin-top      : -1.1rem !important;
        background-color: #6F2DBD !important;
        border-color    : #6F2DBD !important;
        z-index         : 2
    }

    .body-5-white-color-500 {
        @extend .body-5;
        color          : white !important;
        font-weight    : 500;
        display        : flex;
        align-items    : center;
        justify-content: center;

    }
}