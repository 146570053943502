.candidate-test-history-view-with-sidebar {
    width   : 100%;
    height  : calc(100vh - 48px);
    overflow: hidden;
    display : flex;

    .candidate-test-history-left-sidebar-container {
        width     : 100%;
        height    : 100%;
        transition: width 0.1s;
        overflow  : scroll;
    }

    .candidate-test-history-left-sidebar-open {
        width: calc(50vw - 36px - 32px - 24px) !important;
    }

    .candidate-test-history-right-sidebar-container {
        height    : 100%;
        width     : 0px;
        overflow  : hidden;
        transition: width 0.2s;
    }

    .candidate-test-history-right-sidebar-open {
        width        : calc(50vw - 36px - 24px - 24px) !important;
        height       : 100%;
        overflow     : auto;
        padding      : 24px;
        background   : #FFFFFF;
        border-radius: 24px;
        margin-left  : 24px;
        gap          : 24px;
    }

    .candidate-test-history-detail-container {
        width         : 100%;
        height        : 100%;
        display       : flex;
        flex-direction: column;
        align-items   : flex-start;

        .candidate-test-history-header {
            width         : 100%;
            height        : auto;
            padding       : 20px 0px 34px;
            display       : flex;
            flex-direction: row;
            align-items   : flex-start;
        }

        .candidate-test-history {
            width         : 100%;
            display       : flex;
            flex-direction: column;
            align-items   : flex-start;
            gap           : 16px;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1339px) {
    .candidate-test-history-view-with-sidebar {
        .candidate-test-history-right-sidebar-open {
            width      : 100% !important;
            margin-left: 0px;
        }
    }
}

@media screen and (max-width: 767px) {
    .candidate-test-history-view-with-sidebar {
        // height       : 100%;
        height    : calc(100vh - 108px);
        margin-top: 24px;

        .candidate-test-history-right-sidebar-open {
            width      : 100% !important;
            padding    : 16px;
            margin-left: 0px;
        }
    }
}