.candidate-assessment-flow-container {
    width          : 100%;
    height         : calc(100vh - 48px);
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: space-between;
    gap            : 24px;

    .test-progress-info-header {
        width          : 100%;
        padding        : 24px;
        background     : #FFFFFF;
        border-radius  : 24px;
        display        : flex;
        flex-direction : row;
        align-items    : center;
        justify-content: space-between;

        .test-progress-button {
            display       : flex;
            flex-direction: row;
            align-items   : center;
            margin-left   : 24px;
            z-index       : 6;
            gap           : 24px;
        }
    }

    .candidate-assment-flow-wrapper {
        width          : 100%;
        height         : 100%;
        display        : flex;
        align-items    : center;
        justify-content: flex-start;

        .candidate-assment-flow-test-container {
            width          : 100%;
            display        : flex;
            flex-direction : column;
            align-items    : center;
            justify-content: center;
            align-self     : flex-start;
        }

        .candidate-assessment-feedack-modal {
            max-width     : 665px;
            height        : auto;
            display       : flex;
            flex-direction: column;
            align-items   : center;
            background    : #FFF;
            padding       : 24px;
            border-radius : 24px;
            gap           : 10px;
            height        : max-content;

            .emoji-container {
                width          : 100%;
                height         : 70px;
                display        : flex;
                flex-direction : row;
                align-items    : center;
                justify-content: center;
                gap            : 12px;

                .emoji-icon {
                    width : 39px;
                    height: 39px;
                    cursor: pointer;

                    svg {
                        width : 100%;
                        height: 100%;
                    }
                }

                .emoji-icon:hover {
                    width : 54px;
                    height: 54px;
                }

                .selected {
                    width : 54px;
                    height: 54px;
                }
            }

            .candidate-assessment-feedack-modal-text {
                display       : flex;
                flex-direction: column;
                align-items   : flex-start;
            }
        }
    }

    .assessment-header {
        width            : 100%;
        display          : flex;
        flex-direction   : column;
        // margin        : auto;
        gap              : 12px;

        .assessment-header-icon {
            height       : 64px;
            width        : 64px;
            border-radius: 50%;
            margin       : auto;
        }

        .assessment-header-icon-initial {
            @extend .assessment-header-icon;
            background-color: #F6F5F8;
            display         : flex;
            justify-content : center;
            align-items     : center;
        }
    }

    .test-info-icon-container {
        position       : absolute;
        top            : 0;
        right          : 0;
        display        : flex;
        justify-content: flex-end;
        padding-top    : 24px;
        padding-right  : 24px;
        z-index        : 1;



        .test-info-icon {
            position: absolute;
            top     : 0px;
            right   : 0px;
        }


    }



    .test-info-icon-container-open {
        top       : -24px;
        left      : -24px;
        right     : -24px;
        bottom    : -60px;
        background: #121216;
        opacity   : 0.38;
        z-index   : 5;
    }


    .watermark {
        width          : 100%;
        display        : flex;
        align-items    : center;
        justify-content: center;
    }

    .video-cs-container {
        width         : 100%;
        display       : flex;
        flex-direction: column;

        .video-cs-container-header {
            display      : flex;
            width        : 100%;
            height       : 64px;
            padding      : 12px 24px;
            align-items  : center;
            gap          : 24px;
            border-radius: 24px 24px 0px 0px;
            background   : #F6F5F8;

            .form-check-input {
                margin-top: 0px;
            }
        }

        .video-cs-container-main {
            width : 100%;
            height: 455.5px;
        }

        .video-cs-container-footer {
            display       : flex;
            width         : 100%;
            height        : 54px;
            padding       : 12px 24px;
            flex-direction: column;
            align-items   : flex-start;
            gap           : 10px;
            flex-shrink   : 0;
            border-radius : 0px 0px 24px 24px;
            background    : #F6F5F8;
        }
    }
}

.test-assessment-info-container {
    width          : 520px;
    height         : 650px;
    overflow-y     : scroll;
    position       : absolute;
    top            : 95px;
    right          : 32px;
    display        : flex;
    flex-direction : column;
    align-items    : flex-start;
    justify-content: flex-start;
    background     : #FFFFFF;
    padding        : 24px 24px 40px;
    border-radius  : 24px;
    gap            : 24px;

    .test-assessment-progress-container {
        width          : 100%;
        display        : flex;
        flex-direction : column;
        align-items    : flex-start;
        justify-content: flex-start;
        padding        : 24px;
        border         : 1px solid #ECEDF1;
        border-radius  : 24px;
        gap            : 24px;

        .progress-test-card-container {
            width         : 100%;
            display       : flex;
            flex-direction: column;
            align-items   : flex-start;
            gap           : 16px;

            .progress-test-card {
                width          : 100%;
                display        : flex;
                flex-direction : row;
                align-items    : center;
                justify-content: space-between;
                background     : #F6F5F8;
                padding        : 12px 18px;
                border-radius  : 24px;
                gap            : 24px;

                .progress-test-card-inner {
                    width          : 100%;
                    display        : flex;
                    flex-direction : row;
                    align-items    : center;
                    justify-content: space-between;
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1339px) {}

@media only screen and (max-width: 767px) {
    .candidate-assessment-flow-container {
        .test-progress-info-header {
            flex-direction: column;
            gap           : 12px;

            .test-progress-button {
                width      : 100%;
                margin-left: 0px;
            }
        }
    }

    .test-assessment-info-container {
        // width : calc(100% - 48px);
        width : 100%;
        height: 100vh;
        top   : 0px;
        right : 0px;

        .test-assessment-progress-container {
            .progress-test-card-container {
                .progress-test-card {
                    .progress-test-card-inner {
                        flex-direction: column;
                        align-items   : flex-start;
                    }
                }
            }
        }
    }
}