.candidate-dashboard {
    align-items: flex-start;
    width      : 100%;
    gap        : 30px;

    .assessments {
        gap: 20px;

        .assessment {
            align-items  : flex-start;
            padding      : 16px;
            gap          : 10px;
            width        : 215px;
            height       : 107px;
            background   : #FFFFFF;
            border-radius: 8px;

            .icon {
                width : 46px;
                height: 46px;
            }
        }
    }

    .skill {
        gap: 30px;

        .skill-head {
            align-items: center;
            gap        : 5px;
        }

        // .skill-container {
        //     width: 860px;
        //     height: 244px;
        //     background: #FFFFFF;
        //     border-radius: 8px;
        //     .hero-medium {
        //         margin-top: 10%;
        //         text-align: center;
        //         color: #DEDAF2;
        //     }
        // }
        .skill-container {
            width        : 860px;
            height       : 244px;
            padding      : 30px 24px;
            background   : #FFFFFF;
            border-radius: 8px;
            align-items  : flex-start;
            gap          : 20px;

            .body-2 {
                color      : #392D69;
                font-weight: 500;
                line-height: 20px;
            }
        }
    }

    .pending-assessment {
        gap: 30px;

        .pending-container {
            width          : 706px;
            height         : 82px;
            align-items    : flex-start;
            justify-content: space-between;
            padding        : 18px 24px;
            background     : #FFFFFF;
            border         : 0.6px solid #C8CBCD;
            border-radius  : 3px;

            .pending-container-detail {
                gap: 6px;

                .details {
                    align-items: center;
                    gap        : 12px;

                    .label {
                        align-items   : center;
                        padding       : 6px 12px;
                        width         : 83px;
                        height        : 24px;
                        background    : #FFF8E0;
                        border-radius : 4px;
                        text-transform: uppercase;
                        color         : #F4C20D;
                    }
                }

                .company-detail {
                    gap  : 12px;
                    color: #96999C;

                    .time {
                        align-items: center;
                        gap        : 4px;
                    }
                }
            }

            .pending-btn {
                gap: 12px;
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
    .candidate-dashboard {
        min-width: 640px;

        .assessments {
            gap: 10px;

            .assessment {
                padding: 12px;
                gap    : 10px;
                width  : 204px;
                height : 99px;
            }
        }

        .skill {
            width: 640px;

            // .skill-container {
            //     width: 100%;
            // }
            .skill-container {
                width  : 100%;
                padding: 24px 12px;

                .skills {
                    .body-2 {
                        font-size  : 14px;
                        line-height: 17px;
                    }
                }
            }
        }

        .pending-assessment {
            width: 640px;

            .pending-container {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .candidate-dashboard {
        min-width: 327px;

        .assessments {
            width: 327px;

            .assessment {
                width  : 142px;
                height : 93px;
                padding: 12px;
                gap    : 10px;

                .icon {
                    width : 32px;
                    height: 32px;
                }
            }
        }

        .skill {
            width: 327px;

            // .skill-container {
            //     width: 100%;
            //     background: #FFFFFF;
            //     border-radius: 8px;
            //     .hero-medium {
            //         margin-top: 30%;
            //         text-align: center;
            //         color: #DEDAF2;
            //     }
            // }
            .skill-container {
                width  : 100%;
                padding: 24px 12px;

                .skills {
                    .body-2 {
                        font-size  : 12px;
                        line-height: 15px;
                    }
                }
            }
        }

        .pending-assessment {
            width: 327px;

            .pending-container {
                padding : 12px;
                gap     : 10px;
                position: absolute;
                width   : 327px;
                height  : 103px;

                .company-detail {
                    gap  : 12px;
                    color: #96999C;

                    .time {
                        align-items: center;
                        gap        : 4px;
                    }
                }

                .details {
                    align-items: center;
                    gap        : 12px;

                    .label {
                        align-items   : center;
                        padding       : 6px 12px;
                        width         : 83px;
                        height        : 24px;
                        background    : #FFF8E0;
                        border-radius : 4px;
                        text-transform: uppercase;
                        color         : #F4C20D;
                    }

                    .pending-btn {
                        gap: 12px;

                        .btn-md {
                            padding: 6px 16px;
                        }
                    }
                }
            }
        }
    }
}