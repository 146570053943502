.team-settings {
    .team-settings-header {
        width          : 100%;
        height         : 40px;
        margin-bottom  : 50px;
        justify-content: space-between;
    }
}

.team-modal {
    width          : 100%;
    height         : auto;
    justify-content: space-between;

    .team-detail {
        width: 100%;
        gap  : 20px;

        .modal-header {
            align-items: center;
            gap        : 4px;
        }

        .team-email {
            gap: 8px;
        }

        .team-role {
            gap: 8px;
        }
    }
}


@media screen and (max-width: 767px) {
    .team-settings {
        // padding: 24px;

        .team-settings-heading {
            display        : flex;
            flex-direction : column;
            justify-content: center;
            align-items    : flex-start;
            min-height     : 68px;
            height         : 68px;
            max-height     : 68px;
            padding        : 0px 6px;
            border-bottom  : 1px solid #DEDAF2;
        }

        .team-settings-header {
            height       : auto;
            margin-top   : 24px;
            margin-bottom: 0px;

            .add-btn {
                width          : 100%;
                padding        : 16px 24px;
                display        : flex;
                flex-direction : row;
                justify-content: space-between;
                align-items    : center;
                cursor         : pointer;
                margin-top     : 24px;
                border-radius  : 4px;
                border         : 1px solid var(--primary-light, #DEDAF2);
                background     : linear-gradient(0deg, rgba(222, 218, 242, 0.50) 0%, rgba(222, 218, 242, 0.50) 100%), #FFF;
            }

            .btn-md {
                padding: 0px 20px;
            }
        }

        .email-text {
            word-break        : break-word;
            text-align        : left;
            width             : 100%;
            display           : -webkit-box;
            max-width         : 100%;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow          : hidden;
        }
    }

    .team-modal {
        width: 100%;
    }

}