.on-test-start-container {
    width         : 100%;
    max-width     : 710px;
    height        : auto;
    display       : flex;
    flex-direction: column;
    align-items   : center;
    padding       : 24px 48px;
    background    : #FFFFFF;
    border-radius : 24px;
    gap           : 24px;
}