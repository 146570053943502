@import "../style-assets/typography";

.custom-select-div {
	height         : 35px;
	width          : 100%;
	// margin-top: 8px;
	background     : #ffffff;
	border         : 1px solid #ffffff;
	border-radius  : 12px;
	box-shadow     : 2px 8px 15px rgba(0, 0, 0, 0.1);
	display        : flex;
	align-items    : center;
	justify-content: space-between;
	padding        : 0px 14.5px;
}

.custom-select-option {
	padding           : 1px 14.5px;
	height            : 40px;
	width             : 100%;
	// background     : #FFFFFF;
	display           : flex;
	align-items       : center;
	justify-content   : flex-start;
	cursor            : pointer;

	&:hover {
		background-color: $black-100;
	}
}

.custom-select-option-container {
	background     : #ffffff;
	border         : 1px solid #ffffff;
	border-radius  : 12px;
	box-shadow     : 2px 8px 15px rgba(0, 0, 0, 0.1);
	max-height     : 125px;
	overflow-y     : auto;
	scrollbar-width: thin;
	scrollbar-color: $black-300 white;
	z-index        : 22;
}

.custom-select-dropdown-container {
	@extend .custom-select-option-container;

	border-radius: 6px;
}

.css-b62m3t-container {
	width  : 100%;
	padding: 10px 0px;
	outline: none !important;
}

.css-1s2u09g-control {
	border       : none !important;
	border-radius: 12px !important;
	outline      : none !important;
}

.css-26l3qy-menu {
	border: none !important;
}

.css-1pahdxg-control {
	border       : none !important;
	box-shadow   : none !important;
	border-radius: 12px !important;
}

.css-3iigni-container {
	width  : 100%;
	padding: 10px 0px;
	outline: none !important;
}

.css-1insrsq-control {
	border-radius   : 12px !important;
	border          : none !important;
	background-color: #ffffff !important;
}

.select-container {
	position        : relative;
	width           : 100%;
	height          : 40px;
	// max-width    : 350px;
	// background   : #FFFFFF;
	// border       : 0.6px solid #C8CBCD;
	border-radius   : 3px;
	display         : flex;
	align-items     : center;

	input {
		width        : 100%;
		height       : 40px;
		border       : none;
		background   : #F6F5F8;
		padding      : 12px 24px 12px 24px;
		cursor       : pointer;
		border-radius: 24px;
		color        : #121216 !important;
		@extend .body-3
	}

	input:focus-visible {
		border             : none;
		outline            : none;
		// border-radius   : 3px;
		// background-color: white;
	}

	input:focus-visible::placeholder {
		color: #7A869A;
	}
}

input::placeholder // input:-ms-input-placeholder input ::-webkit-input-placeholder 

	{
	color: #7A869A;
	@extend .body-3
}

.arrow-icon {
	position      : absolute;
	right         : 24px;
	cursor        : pointer;
	pointer-events: none;
}

.loader-container {
	display         : flex;
	position        : absolute;
	top             : 52px;
	width           : 100%;
	padding         : 24px 0px;
	justify-content : center;
	z-index         : 2;
	background-color: white;
	background      : #FFFFFF;
	box-shadow      : 0px 3px 5px rgba(9, 30, 66, 0.2),
		0px 0px 1px rgba(9, 30, 66, 0.31);
	border-radius: 3px;

	.button-processing-indicator-wrapper {
		height    : 15px;
		width     : 15px;
		display   : flex;
		align-self: center;
	}
}

.menu-items-container {
	position           : absolute;
	top                : 52px;
	width              : 100%;
	z-index            : 4;
	// background-color: rgb(100, 22, 22);
	background         : #FFFFFF;
	box-shadow         : 0px 3px 5px rgba(9, 30, 66, 0.2),
		0px 0px 1px rgba(9, 30, 66, 0.31);
	border-radius: 24px;

	display       : flex;
	flex-direction: column;
	align-items   : flex-start;
	max-height    : 400px;
	overflow-y    : auto;

	.menu-item-container {
		display         : flex;
		align-items     : center;
		justify-content : flex-start;
		padding         : 0px 24px 0px 24px;
		width           : 100%;
		// border-bottom: 1px solid #e1e1e1;
		height          : 40px !important;
		min-height      : 40px;

		span {
			@extend .body-2;
			color        : $dark-100 !important;
			// margin-top: 10px;
		}

		&:last-child {
			border-bottom: none;
		}

		&:hover {
			background-color: #121216 !important;
			color           : white !important;

			span {
				color: white !important;
			}
		}


	}

	.menu-item-container:hover {
		background: #EBECF0;
		cursor    : pointer;
	}
}

@media screen and (max-width: 767px) {
	.menu-items-container {
		width: 160px;
		right: 0px;
	}
}