progress[value]::-webkit-progress-value {
    transition   : width 0.5s;
    // background: orange;
}

progress[value]::-moz-progress-bar {
    transition   : width 0.5s;
    // background: orange;
}

.progress-bar-container {
    width        : 110px;
    height       : 7px;
    border-radius: 7px;
    background   : #F6F5F8;

    .progress-bar {
        width           : 0px;
        height          : 100%;
        border-radius   : 7px;
        transition      : width 0.5s;
        background-color: #896BFF;
    }
}

.progress-bar-container-number-value {
    width        : 100px;
    height       : 4px;
    border-radius: 4px;
    display      : flex;
    align-items  : center;
    background   : #F6F5F8;

    .progress-bar {
        width           : 0px;
        height          : 4px;
        border-radius   : 4px;
        transition      : width 0.5s;
        background-color: #896BFF;
    }
}

.progress-bar-container-lg {
    width        : 100%;
    height       : 12px;
    border-radius: 12px;
    background   : #F6F5F8;

    .progress-bar {
        width           : 0px;
        height          : 100%;
        border-radius   : 12px;
        transition      : width 0.5s;
        background-color: #896BFF;
    }
}

.segment-progress-main-container {
    width         : 100%;
    display       : flex;
    flex-direction: column;
    gap           : 32px;

    .segment-progress-container {
        width         : 100%;
        display       : flex;
        flex-direction: column;
        gap           : 5px;

        .segment-progress-bar-wrapper {
            height        : 7px;
            display       : flex;
            flex-direction: row;
            border-radius : 3.5px;

            .segment-progress-bar-left {
                height          : 100%;
                display         : flex;
                border-radius   : 3.5px 0px 0px 3.5px;
                overflow        : hidden;
                background-color: #e0e0e0;
            }

            .segment-progress-bar-right {
                height          : 100%;
                display         : flex;
                border-radius   : 0px 3.5px 3.5px 0px;
                overflow        : hidden;
                background-color: #e0e0e0;
            }

            .progress-segment {
                height  : 100%;
                position: relative;

                .progress-bar-use {
                    height    : 100%;
                    cursor    : pointer;
                    position  : relative;
                    transition: transform .2s;
                }
            }

            .active-left {
                border-radius: 3.5px 0px 0px 3.5px;
            }

            .active-left:hover {
                border-radius: 4.5px 0px 0px 4.5px;
            }

            .active-right:hover {
                border-radius: 0px 4.5px 4.5px 0px;
            }
        }

        .segment-progress-bar-left:hover,
        .segment-progress-bar-right:hover {
            overflow: visible;
        }

        .progress-segment .progress-bar-use:hover {
            transform: scaleY(1.3);
        }
    }

    .legend {
        margin-top    : 10px;
        display       : flex;
        flex-direction: column;
        gap           : 8px;
    }

    .legend-item {
        display    : flex;
        align-items: center;
        gap        : 5px;
    }

    .legend-color {
        width        : 6px;
        height       : 6px;
        border-radius: 50%;
    }
}