.api-access-token-card-container {
    width         : 100%;
    height        : auto;
    display       : flex;
    flex-direction: column;
    align-items   : flex-start;
    padding       : 24px;
    border-radius : 12px;
    background    : #F6F5F8;
    gap           : 12px;

    .api-access-token-card-header {
        width          : 100%;
        display        : flex;
        flex-direction : row;
        align-items    : flex-start;
        justify-content: space-between;
        position       : relative;
        gap            : 12px;

        .menu-container {
            width         : auto;
            height        : auto;
            display       : flex;
            flex-direction: column;
            background    : #FFFFFF;
            position      : absolute;
            right         : 0px;
            top           : 20px;
            padding       : 12px 24px;
            border-radius : 12px;

            .menu-card {
                width          : auto;
                height         : 40px;
                display        : flex;
                flex-direction : row;
                align-items    : center;
                justify-content: center;
                cursor         : pointer;
            }
        }
    }
}