.cookie-disclaimer {
    width           : calc(50vw - 24px);
    height          : 66px;
    display         : none;
    position        : fixed;
    bottom          : 24px;
    left            : 24px;
    border-radius   : 24px;
    // border       : 0.6px solid rgba(111, 45, 189, 1);
    background-color: $white;
    padding         : 16px;
    z-index         : 9999;


    .cookie-disclaimer-content {
        width          : 100%;
        display        : flex;
        flex-direction : row;
        align-items    : center;
        justify-content: space-between;
        gap            : 10px;
        color          : white;
        border         : 1px solid;

        .primary-button span {
            font-size: 16px;
        }
    }
}

.cookie-disclaimer-visible {
    display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
    .cookie-disclaimer {
        width : 510px;
        left  : 32px;
        bottom: 120px;

        .cookie-disclaimer-content {}
    }
}

@media screen and (max-width: 767px) {
    .cookie-disclaimer {
        width        : 100%;
        left         : 0;
        bottom       : 0px;
        height       : min-content !important;
        border-radius: 0px;

        .cookie-disclaimer-content {

            // flex-direction: column;
            .primary-button span {
                font-size: 14px;
            }
        }
    }
}